import { FormProvider, useForm } from '@fleet/shared';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  DateField,
  RadioGroupField,
  SelectField,
  TextField,
} from '@fleet/shared/form';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { useCallback, useMemo, useState } from 'react';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { BookingSearchFilter } from 'dto/booking';
import { searchBookings } from 'features/booking/bookingActions';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { TransParagraph } from 'i18n/trans/paragraph';
import isEmpty from 'lodash/isEmpty';
import { TransAlert } from 'i18n/trans/alert';
import { bookingsFilterSelector } from 'features/booking/bookingSelectors';

const useStyles = makeStyles(
  (theme) => ({
    general: {
      width: 'calc(100% - 130px)',
      zIndex: 1,
    },
    accordion: {
      position: 'relative',
      zIndex: 0,
      background: 'transparent',
      '&:before': {
        display: 'none',
      },
      '& .MuiAccordionDetails-root': {
        padding: '16px',
        background: theme.palette.background.default,
      },
      '&$expanded': {
        marginBottom: 24,
      },
    },
    expanded: {},
    accordionSummary: {
      marginTop: '-38px',
    },
    accordionSummaryContent: {
      '&&': {
        margin: '0 0 24px 0',
      },
    },
  }),
  {
    name: 'BookingsSearchForm',
  }
);
export const BookingsSearchForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stopOptions = useClassificationOptions(ClassificationGroup.STOP);
  const filter = useSelector(bookingsFilterSelector);
  const [isAdvancedSearchCollapsed, setAdvancedSearchCollapsed] =
    useState(true);
  const onSubmit = useCallback(
    async (values: BookingSearchFilter) => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      await dispatch(searchBookings(values));
    },
    [dispatch]
  );
  const initialValues = useMemo(
    () => ({ filterParameters: { status: 'PAID' } }),
    []
  );
  const { form, handleSubmit } = useForm<BookingSearchFilter>({
    initialValues: {
      ...initialValues,
      ...filter,
    },
    onSubmit,
    validate: ({ referenceNumber, purchaser, passenger }) => {
      if (
        [referenceNumber, purchaser, passenger].every((val) => isEmpty(val))
      ) {
        return {
          referenceNumber: <TransAlert i18nKey="fillOneRequired" />,
          passenger: {
            name: {
              firstName: <TransAlert i18nKey="fillOneRequired" />,
              lastName: <TransAlert i18nKey="fillOneRequired" />,
            },
            emailAddress: <TransAlert i18nKey="fillOneRequired" />,
            phoneNumber: <TransAlert i18nKey="fillOneRequired" />,
          },
          purchaser: {
            name: {
              firstName: <TransAlert i18nKey="fillOneRequired" />,
              lastName: <TransAlert i18nKey="fillOneRequired" />,
            },
            emailAddress: <TransAlert i18nKey="fillOneRequired" />,
            phoneNumber: <TransAlert i18nKey="fillOneRequired" />,
          },
        };
      }
    },
  });

  return (
    <FormProvider {...form}>
      <Stack component="form" onSubmit={handleSubmit} p={3}>
        <Grid
          className={classes.general}
          container
          columns={8}
          spacing={4}
          rowSpacing={1}
          mb={1}
        >
          <Grid item xs={2}>
            <TextField
              name="referenceNumber"
              label={
                <Stack direction="row" sx={{ display: 'inline-flex' }}>
                  <TransField i18nKey="referenceNumber" />
                  &nbsp;
                  <Tooltip
                    content={<TransParagraph i18nKey="referenceNumberHint" />}
                  >
                    <Icon name="info-circle" />
                  </Tooltip>
                </Stack>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DateField
              name="filterParameters.travelDateRange"
              label={<TransField i18nKey="travelDateBetween" />}
              isClearable
              showTimeInput
              selectsRange
            />
          </Grid>
          <Grid item xs={3}>
            <DateField
              name="filterParameters.purchaseDateRange"
              label={<TransField i18nKey="purchaseDateBetween" />}
              isClearable
              showTimeInput
              selectsRange
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="passenger.name.firstName"
              label={<TransField i18nKey="passengerNameFirst" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="passenger.name.lastName"
              label={<TransField i18nKey="passengerNameLast" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="passenger.emailAddress"
              label={<TransField i18nKey="passengerEmail" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="passenger.phoneNumber"
              label={<TransField i18nKey="passengerPhone" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="purchaser.name.firstName"
              label={<TransField i18nKey="purchaserNameFirst" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="purchaser.name.lastName"
              label={<TransField i18nKey="purchaserNameLast" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="purchaser.emailAddress"
              label={<TransField i18nKey="purchaserEmail" />}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="purchaser.phoneNumber"
              label={<TransField i18nKey="purchaserPhone" />}
            />
          </Grid>
        </Grid>
        <Accordion
          classes={{
            root: classes.accordion,
            expanded: classes.expanded,
          }}
          disableGutters
          elevation={0}
          expanded={!isAdvancedSearchCollapsed}
          onChange={() =>
            setAdvancedSearchCollapsed(!isAdvancedSearchCollapsed)
          }
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              content: classes.accordionSummaryContent,
            }}
          >
            <Stack ml="auto" direction="row" alignItems="center" spacing={1}>
              <Typography variant="paragraph" color="primary">
                {isAdvancedSearchCollapsed ? '+' : '—'}
              </Typography>
              <Typography variant="body1" color="primary">
                <TransButton i18nKey="advancedSearch" />
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container columns={4} spacing={4} rowSpacing={1}>
              <Grid item xs={1}>
                <TextField
                  name="filterParameters.corporateAgreement"
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="agreementRef" />
                      &nbsp;
                      <Tooltip
                        content={
                          <TransParagraph i18nKey="corporateAgreementHint" />
                        }
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="filterParameters.originStop"
                  label={<TransField i18nKey="legOrigin" />}
                  options={stopOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="filterParameters.destinationStop"
                  label={<TransField i18nKey="legDestination" />}
                  options={stopOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="filterParameters.serviceCode"
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="service" />
                      &nbsp;
                      <Tooltip
                        content={<TransParagraph i18nKey="serviceHint" />}
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="filterParameters.travelAgencyName"
                  label={
                    <Stack direction="row">
                      <TransField i18nKey="retailerName" />
                      &nbsp;
                      <Tooltip
                        content={<TransParagraph i18nKey="retailerHint" />}
                      >
                        <Icon name="info-circle" />
                      </Tooltip>
                    </Stack>
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="filterParameters.status"
                  label={<TransField i18nKey="paymentStatus" />}
                  defaultValue="PAID"
                  options={[
                    {
                      value: 'PAID',
                      label: <TransField i18nKey="statusPaid" />,
                    },
                    {
                      value: 'UNPAID',
                      label: <TransField i18nKey="statusUnpaid" />,
                    },
                    {
                      value: 'ON_HOLD',
                      label: <TransField i18nKey="statusOnHold" />,
                    },
                  ]}
                  inline
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Stack direction="row" spacing={2} ml="auto">
          <Button onClick={() => form.restart(initialValues)}>
            <TransButton i18nKey="resetFields" />
          </Button>
          <Button variant="contained" type="submit">
            <TransButton i18nKey="search" />
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
