import { Journey, Journey as TripJourney, Trip, TripLeg } from 'dto/trip';
import { format, getTime, Duration, intervalToDuration } from 'date-fns';
import { parse } from 'duration-fns';
import _omitBy from 'lodash/omitBy';
import _last from 'lodash/last';
import _uniqWith from 'lodash/uniqWith';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _partition from 'lodash/partition';
import {
  BookingAdmission,
  BookingDetails,
  BookingFulfillment,
} from 'dto/booking';
import download from 'downloadjs';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';

export const getTimeString = (dateTimeStr: string): string => {
  return format(
    getTime(new Date(dateTimeStr)),
    currentLocaleConfiguration.timeFormat
  );
};

export const getLegDuration = (trip: TripLeg) => {
  return parseDuration(parse(trip.duration));
};

export const getLegTransferTime = (tripA: TripLeg, tripB: TripLeg) => {
  return getDuration(tripA.arrivalTime, tripB.departureTime);
};

export const getDuration = (a: string, b: string) => {
  const duration = _omitBy(
    intervalToDuration({
      start: new Date(a),
      end: new Date(b),
    }),
    (v) => !v
  );

  return parseDuration(duration);
};

export const parseDuration = (duration: Duration) =>
  Object.keys(duration).reduce((acc, cur) => {
    const unitStr = cur === 'minutes' ? 'min' : cur.substring(0, 1);
    const unitValue = duration[cur as keyof Duration];
    return [
      acc,
      ...(unitValue ? [`${duration[cur as keyof Duration]}${unitStr}`] : []),
    ].join(' ');
  }, '');

export const getBookingJourneys = (
  booking: BookingDetails
): Array<TripJourney> =>
  booking.bookingParts
    .map(({ journey }) => journey)
    .filter(Boolean)
    .sort(
      (a, b) =>
        new Date(a.departureTime).valueOf() -
        new Date(b.departureTime).valueOf()
    );

export const getBookingTrips = (booking: BookingDetails | undefined) => {
  if (!booking) return [];
  const pickTripStopsAndDates = (trip: Trip) =>
    _pick(trip, [
      'originStop',
      'destinationStop',
      'arrivalTime',
      'departureTime',
    ]);

  return _partition(
    getBookingJourneys(booking),
    (journey) => journey.isOutbound
  )
    .map((journeys) =>
      journeys.reduce<Array<Trip>>(
        (acc, journey) => [...acc, ...journey.trips],
        []
      )
    )
    .map((trips) =>
      _uniqWith(trips, (a: Trip, b: Trip) =>
        _isEqual(pickTripStopsAndDates(a), pickTripStopsAndDates(b))
      )
    );
};

export const getBookingDestinations = (
  booking?: BookingDetails
): Array<string> => {
  if (!booking) return [];
  const journeys = getBookingJourneys(booking);
  return [journeys[0].originStop.name, _last(journeys)!.destinationStop.name];
};

export const getOnDemandServiceTexts = ({ trips }: Journey): Array<string> =>
  trips.reduce<Array<string>>(
    (texts, trip) => [
      ...texts,
      ...trip.legs.reduce<Array<string>>(
        (texts, leg) => [...texts, ...(leg.onDemandTexts ?? [])],
        []
      ),
    ],
    []
  );

export const getHasJourneyNotifications = ({ trips }: Journey): boolean =>
  trips.some(({ legs }) =>
    legs.some(({ serviceTexts }) => !!serviceTexts?.length)
  );
export const trimPhoneAreaCode = (phone?: string): string =>
  phone?.replace(/^([+]?46)+/, '') ?? '';

export const getBookingAdmissions = (booking?: BookingDetails) =>
  booking?.bookedTrips.reduce<Array<BookingAdmission>>(
    (admissions, trip) => [...admissions, ...trip.bookedOffer.admissions],
    []
  ) ?? [];

export const downloadBookingTickets = (booking?: BookingDetails) => {
  if (!booking) return;

  return booking.bookedTrips
    .reduce<Array<BookingFulfillment>>(
      (acc, trip) => [
        ...acc,
        ...trip.bookedOffer.admissions.reduce<Array<BookingFulfillment>>(
          (acc, admission) => [...acc, ...admission.fulfillments],
          []
        ),
      ],
      []
    )
    .filter(
      ({ downloadLink, documentContent }) => downloadLink || documentContent
    )
    .forEach(({ downloadLink, documentContent, documentFormat }) =>
      downloadLink
        ? window.open(downloadLink)
        : download(
            `data:application/pdf;base64,${documentContent}`,
            'ticket.pdf',
            documentFormat
          )
    );
};
