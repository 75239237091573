import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  addon: <Trans i18nKey="table.head.addon" defaults="Add-on" />,
  age: <Trans i18nKey="table.head.age" defaults="Age" />,
  arrivalTime: (
    <Trans i18nKey="table.head.arrivalTime" defaults="Arrival time" />
  ),
  bookingReference: (
    <Trans i18nKey="table.head.bookingReference" defaults="Booking reference" />
  ),
  carriage: <Trans i18nKey="table.head.carriage" defaults="Carriage" />,
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  changeable: <Trans i18nKey="table.head.changeable" defaults="Changeable" />,
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  createdOn: <Trans i18nKey="table.head.createdOn" defaults="Created on" />,
  departureTime: (
    <Trans i18nKey="table.head.departureTime" defaults="Departure time" />
  ),
  destination: (
    <Trans i18nKey="table.head.destination" defaults="Destination" />
  ),
  fee: <Trans i18nKey="table.head.fee" defaults="Fee" />,
  flexibility: (
    <Trans i18nKey="table.head.flexibility" defaults="Flexibility" />
  ),
  journey: <Trans i18nKey="table.head.journey" defaults="Journey" />,
  journeyDestination: (
    <Trans
      i18nKey="table.head.journeyDestination"
      defaults="Journey destination"
    />
  ),
  journeyOrigin: (
    <Trans i18nKey="table.head.journeyOrigin" defaults="Journey origin" />
  ),
  leg: <Trans i18nKey="table.head.leg" defaults="Leg" />,
  origin: <Trans i18nKey="table.head.origin" defaults="Origin" />,
  passenger: <Trans i18nKey="table.head.passenger" defaults="Passenger" />,
  passengerCards: (
    <Trans i18nKey="table.head.passengerCards" defaults="Passenger cards" />
  ),
  passengers: <Trans i18nKey="table.head.passengers" defaults="Passengers" />,
  passengerType: (
    <Trans i18nKey="table.head.passengerType" defaults="Passenger type" />
  ),
  paymentStatus: (
    <Trans i18nKey="table.head.paymentStatus" defaults="Payment status" />
  ),
  price: <Trans i18nKey="table.head.price" defaults="Price" />,
  productName: (
    <Trans i18nKey="table.head.productName" defaults="Product name" />
  ),
  qty: <Trans i18nKey="table.head.qty" defaults="Quantity" />,
  refundable: <Trans i18nKey="table.head.refundable" defaults="Refundable" />,
  refundableAmount: (
    <Trans i18nKey="table.head.refundableAmount" defaults="Refundable amount" />
  ),
  seat: <Trans i18nKey="table.head.seat" defaults="Seat" />,
  seatClass: <Trans i18nKey="table.head.seatClass" defaults="Seat class" />,
  status: <Trans i18nKey="table.head.status" defaults="Status" />,
  stop: <Trans i18nKey="table.head.stop" defaults="Stop" />,
  stopCode: <Trans i18nKey="table.head.stopCode" defaults="Stop code" />,
  ticket: <Trans i18nKey="table.head.ticket" defaults="Ticket" />,
  ticketNr: <Trans i18nKey="table.head.ticketNr" defaults="Ticket number" />,
  totalPrice: (
    <Trans i18nKey="table.head.totalPrice" defaults="Total price incl VAT" />
  ),
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
});
