import { Modal, Input } from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { useDispatch, useSelector } from 'store/utils';
import { showingStopsForSelector } from 'features/trip/tripSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { showTripStops } from 'features/trip/tripActions';
import { Column, useExpanded, useTable } from 'react-table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { TransTableHead } from 'i18n/trans/table';
import { TransField } from 'i18n/trans/field';
import { Table } from '@fleet/shared/components/Table';
import { Box, Grid, Stack } from '@mui/material';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { TransTitle } from 'i18n/trans/title';
interface PreparedStop {
  code: number;
  name: string;
  arrivalTime?: string;
  departureTime?: string;
  cityName?: string;
}

const useStyles = makeStyles(
  (theme) => ({
    tag: {
      display: 'inline-block',
      borderRadius: '3px',
      background: theme.palette.primary.light,
      padding: '0 4px',
    },
  }),
  {
    name: 'TripStopsModal',
  }
);

export const TripStopsModal = () => {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState('');
  const { open, onOpen, onClose } = useModal();
  const dispatch = useDispatch();
  const showingStopsFor = useSelector(showingStopsForSelector);
  const onModalClose = useCallback(() => {
    dispatch(showTripStops());
    onClose();
  }, [dispatch, onClose]);
  const stopsList = useMemo<Array<PreparedStop>>(() => {
    if (!showingStopsFor) return [];
    const {
      departureTime,
      arrivalTime,
      originStop,
      intermediateStops = [],
      destinationStop,
    } = showingStopsFor;
    return [
      { ...originStop, departureTime },
      ...intermediateStops,
      { ...destinationStop, arrivalTime },
    ].filter(({ name, code, cityName = '' }) =>
      [name, code, cityName].some((str) =>
        String(str).toLowerCase().includes(searchStr.toLowerCase())
      )
    );
  }, [showingStopsFor, searchStr]);

  useEffect(() => {
    if (showingStopsFor) {
      onOpen();
    }

    return () => {
      setSearchStr('');
    };
  }, [onOpen, showingStopsFor]);
  const columns = useMemo<Column<PreparedStop>[]>(
    () => [
      {
        id: 'arrivalTime',
        accessor: ({ arrivalTime }) =>
          arrivalTime ? formatDate(arrivalTime, currentDateTimeFormat) : ' - ',
        Header: <TransTableHead i18nKey="arrivalTime" />,
      },
      {
        id: 'departureTime',
        accessor: ({ departureTime }) =>
          departureTime ? formatDate(departureTime, currentDateTimeFormat) : ' - ',
        Header: <TransTableHead i18nKey="departureTime" />,
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stop" />,
      },
      {
        id: 'stopCode',
        accessor: ({ code }) => <Box className={classes.tag}>{code}</Box>,
        Header: <TransTableHead i18nKey="stopCode" />,
      },
      {
        id: 'cityName',
        accessor: ({ cityName }) => (
          <Box className={classes.tag}>{cityName}</Box>
        ),
        Header: <TransTableHead i18nKey="city" />,
      },
    ],
    [classes.tag]
  );
  const table = useTable<PreparedStop>(
    {
      data: stopsList,
      columns,
    },
    useExpanded
  );

  return (
    <Modal
      title={<TransTitle i18nKey="viewStops" />}
      open={open}
      onClose={onModalClose}
      actionButton={<></>}
    >
      <Stack spacing={2}>
        <Grid container columns={2}>
          <Grid item xs={1}>
            <Input
              label={<TransField i18nKey="stopsSearch" />}
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
            />
          </Grid>
          <Grid item xs={1} alignSelf="flex-end" textAlign="right">
            <Button
              variant="text"
              onClick={() => setSearchStr('')}
              label={<TransButton i18nKey="resetFields" />}
            />
          </Grid>
        </Grid>
        <Table table={table} />
      </Stack>
    </Modal>
  );
};
