import { CartTotal } from 'components/CartTotal';
import { Button, RadioGroup } from '@fleet/shared/mui';
import { selectEmailSendAvailable } from 'features/user/userSelector';
import { TransButton } from 'i18n/trans/button';
import { FormProvider, Icon, Select } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { trimPhoneAreaCode } from 'utils/trip';
import { downloadPdfFlagSelector } from 'features/trip/tripSelector';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { TransField } from 'i18n/trans/field';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TextField, useForm } from '@fleet/shared/form';
import { Checkbox } from '@fleet/shared/mui/Checkbox';
import {
  sendConfirmation,
  triggerBookingFulfillment,
} from 'features/booking/bookingActions';
import {
  PassengerDetailsPayload,
  setPdfDownload,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { SendTicketsSelection } from 'components/SendTicketsSelection';
import { getOnDemandServiceTexts } from 'utils/trip';
import { AlertCard } from 'components/AlertCard';
import { TransAlert } from 'i18n/trans/alert';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { SearchTabsContext } from 'components/SearchTabsContext';

interface OverviewProps {
  goToNextStep: () => void;
}

export const Overview: FC<OverviewProps> = ({ goToNextStep }) => {
  const [emailSend, setEmailSend] = useState(false);
  const dispatch = useDispatch();
  const emailSendAvailable = useSelector(selectEmailSendAvailable);
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const booking = useSelector(currentBookingSelector)!;
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const { updateTab } = useContext(SearchTabsContext);
  const { bookingParts, passengers, purchaser } = booking;
  const passengerOptions = useMemo(
    () =>
      passengers.map(({ firstName, lastName, id }) => ({
        label: [firstName.value, lastName.value].join(' '),
        value: id,
      })),
    [passengers]
  );
  const handleConfirmationSend = useCallback(
    async ({
      confirmationRecipient = [],
      additionalConfirmationRecipients = [],
      passengerSelection = [],
    }) => {
      const confirmationRecipients = [
        ...confirmationRecipient,
        ...additionalConfirmationRecipients,
      ];
      await Promise.all(
        [
          confirmationRecipients.length &&
            dispatch(
              sendConfirmation({
                url: 'purchase-confirmation',
                bookingId: booking.id,
                emailsOverride: confirmationRecipients,
              })
            ).unwrap(),
          passengerSelection.length &&
            dispatch(
              sendConfirmation({
                url: 'ticket-delivery',
                bookingId: booking.id,
                passengers: passengerSelection.map((passengerId: string) => ({
                  passengerId,
                })),
              })
            ).unwrap(),
        ].filter(Boolean)
      );
    },
    [dispatch, booking.id]
  );

  const { form: emailsForm, invalid: emailsFormInvalid } = useForm({
    onSubmit: handleConfirmationSend,
    subscription: { invalid: true },
  });

  useEffect(() => {
    emailsForm.reset();
  }, [emailsForm, emailSend]);

  const onSubmit = useCallback(
    async ({ phone, ...values }) => {
      if (emailsFormInvalid && emailSend) return emailsForm.submit();
      await dispatch(
        updatePurchaserDetails({
          bookingId: booking.id,
          phone: {
            // temp while area code input is missing
            ...phone,
            areaCode: '+46',
          },
          ...values,
        })
      ).unwrap();
      await dispatch(triggerBookingFulfillment(booking.id)).unwrap();
      emailSend && emailsForm.submit();
      updateTab({ isCompleted: true });
      goToNextStep();
    },
    [
      emailsFormInvalid,
      emailSend,
      emailsForm,
      dispatch,
      booking.id,
      updateTab,
      goToNextStep,
    ]
  );

  const { form, handleSubmit, values, invalid } =
    useForm<PassengerDetailsPayload>({
      subscription: { values: true, invalid: true },
      onSubmit,
    });

  const handlePrefillData = useCallback(
    (passengerId: string) => {
      const passenger = passengers.find(({ id }) => id === passengerId);
      if (passenger) {
        const { firstName, lastName, contactInformation } = passenger;
        form.reset({
          firstName: firstName.value,
          lastName: lastName.value,
          email: contactInformation.emailAddress.value,
          phone: {
            // temp while area code input is missing
            number: trimPhoneAreaCode(contactInformation.phoneNumber.value),
          },
        });
      } else {
        form.reset({});
      }
    },
    [form, passengers]
  );

  const purchaserEmail = useMemo(() => {
    if (form.getFieldState('email')?.valid) {
      return values.email;
    }
  }, [form, values]);

  const showValidations = useCallback(
    () => invalid && form.submit(),
    [form, invalid]
  );

  if (!bookingParts.length) {
    return null;
  }

  const [{ journey }] = bookingParts;
  const onDemandTexts = getOnDemandServiceTexts(journey);

  return (
    <>
      <Stack spacing={2} divider={<Divider />}>
        <>
          <Typography variant="h2">
            <TransTitle i18nKey="overview" />
          </Typography>
          {!!onDemandTexts.length && (
            <AlertCard
              title={<TransAlert i18nKey="advanceOrderRequired" />}
              message={onDemandTexts}
            />
          )}
          <JourneyOverview collapsible />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ py: 3 }}
          >
            <Typography variant="h2">Total to pay:</Typography>
            <Typography variant="title">
              {`${booking?.provisionalPrice.amount} ${booking?.provisionalPrice.currency}`}
            </Typography>
          </Stack>
        </>
        <>
          <Typography variant="h2">
            <TransTitle i18nKey="payerDetails" />
          </Typography>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit} id="purchaserDetails">
              <Grid container columns={4} spacing={2} rowSpacing={2}>
                <Grid item xs={1}>
                  <Select
                    label={<TransField i18nKey="prefillWithPassenger" />}
                    labelPosition="top"
                    options={passengerOptions}
                    onChange={handlePrefillData}
                    showEmptyOption
                  />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.firstName.isRequired}
                    name="firstName"
                    label={<TransField i18nKey="purchaserNameFirst" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.lastName.isRequired}
                    name="lastName"
                    label={<TransField i18nKey="purchaserNameLast" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={
                      purchaser?.contactInformation.emailAddress.isRequired
                    }
                    email
                    name="email"
                    label={<TransField i18nKey="email" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={
                      purchaser?.contactInformation.phoneNumber.isRequired
                    }
                    name="phone.number"
                    label={<TransField i18nKey="mobileNumber" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.address.isRequired}
                    name="address.zipCode"
                    label={<TransField i18nKey="zipCode" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.address.isRequired}
                    name="address.streetName"
                    label={<TransField i18nKey="address" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.address.isRequired}
                    name="address.city"
                    label={<TransField i18nKey="postalCity" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.address.isRequired}
                    name="address.countryName"
                    label={<TransField i18nKey="postalCountry" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.companyName.isRequired}
                    name="company.name"
                    label={<TransField i18nKey="companyName" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.companyRegistrationNumber.isRequired}
                    name="company.registrationNumber"
                    label={<TransField i18nKey="companyRegNr" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required={purchaser?.vatNumber.isRequired}
                    name="company.taxId"
                    label={<TransField i18nKey="vatNumber" />}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </>
        <>
          <Typography variant="h2">
            <TransTitle i18nKey="ticketFulfillment" />
          </Typography>
          <Stack direction="row" spacing={3} onClick={showValidations}>
            <Checkbox
              checked={downloadPdf}
              label="PDF download"
              onChange={(e) => dispatch(setPdfDownload(e.target.checked))}
              inline
            />
            {emailSendAvailable && (
              <Checkbox
                checked={emailSend}
                disabled={invalid}
                label="Email"
                onChange={(e) => setEmailSend(e.target.checked)}
                inline
              />
            )}
          </Stack>
          {emailSend && (
            <SendTicketsSelection
              form={emailsForm}
              purchaserEmail={purchaserEmail}
              isInline
            />
          )}
        </>
        <>
          <Typography variant="h2">
            <TransTitle i18nKey="paymentMethod" />
          </Typography>
          <RadioGroup
            inline
            options={[
              { value: 'external', label: 'External' },
              // { value: 'creditCard', label: 'Credit card' },
              // { value: 'invoice', label: 'Invoice' },
            ]}
            defaultValue="creditCard"
          />
        </>
      </Stack>
      <CartTotal>
        <>
          <Button
            variant="text"
            onClick={() => form.restart()}
            label={<TransButton i18nKey="resetFields" />}
            disabled={isBookingExpired}
          />
          <Button
            variant="contained"
            type="submit"
            loading={loading}
            disabled={isBookingExpired}
            form="purchaserDetails"
            label={
              <>
                <Icon name="check" sx={{ mr: 1 }} />
                <TransButton i18nKey="confirmTransaction" />
              </>
            }
          />
        </>
      </CartTotal>
    </>
  );
};
