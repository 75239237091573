import { createReducer } from '@reduxjs/toolkit';
import {
  getBooking,
  postBooking,
  triggerBookingFulfillment,
  searchBookings,
  setBookingFilter,
  updatePassengerAdmissionSelection,
  initiateRefund,
  initiateRelease,
  updateAdmissionsSelection,
  resetCurrentBooking,
  getComments,
} from 'features/booking/bookingActions';
import {
  Booking,
  BookingComment,
  BookingDetails,
  BookingRefundOffer,
  BookingReleaseOffer,
  BookingSearchFilter,
  BookingsSearchResult,
} from 'dto/booking';
import { Pagination } from '@fleet/shared/dto/pagination';
import { selectTripOffer } from 'features/trip/tripActions';

export interface BookingState {
  filter: Partial<BookingSearchFilter>;
  search?: Pagination<Booking>;
  current?: BookingDetails;
  list: Array<BookingsSearchResult>;
  selectedAdmissions: Record<string, Array<string>>;
  refundOffers?: {
    passengerOffers: Array<BookingRefundOffer>;
    bookingOffers: Array<BookingRefundOffer>;
  };
  releaseOffers?: Array<BookingReleaseOffer>;
  comments: Array<BookingComment>;
}
const initialState: BookingState = {
  filter: {},
  list: [],
  selectedAdmissions: {},
  comments: [],
};

export const bookingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchBookings.fulfilled, (state, action) => {
      state.list = action.payload.bookingsSearchResults;
    })
    .addCase(setBookingFilter, (state, action) => {
      state.filter = action.payload ?? {};
    })
    .addCase(updatePassengerAdmissionSelection, (state, action) => {
      const { passengerId, selection } = action.payload;
      state.selectedAdmissions[passengerId] = selection;
    })
    .addCase(updateAdmissionsSelection, (state, action) => {
      state.selectedAdmissions = action.payload;
    })
    .addCase(initiateRefund.pending, (state) => {
      state.refundOffers = undefined;
    })
    .addCase(initiateRefund.fulfilled, (state, action) => {
      state.refundOffers = action.payload;
    })
    .addCase(initiateRelease.pending, (state) => {
      state.releaseOffers = undefined;
    })
    .addCase(initiateRelease.fulfilled, (state, action) => {
      state.releaseOffers = action.payload;
    })
    .addCase(resetCurrentBooking, (state) => {
      state.current = undefined;
      state.comments = [];
      state.selectedAdmissions = {};
    })
    .addCase(selectTripOffer, (state) => {
      state.current = undefined;
    })
    .addCase(getComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    })
    .addMatcher(
      (action) =>
        [
          getBooking.fulfilled.type,
          postBooking.fulfilled.type,
          triggerBookingFulfillment.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.current = action.payload;
      }
    );
});
