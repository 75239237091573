import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import { createAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

export type UserUnit = {
  id: string;
  name: string;
};

export interface UserOrganization extends UserUnit {
  units: Array<UserUnit & { code: string }>;
  pointsOfSale: Array<UserUnit>;
}
export interface User {
  id: string;
  name: string;
  primaryOrganization: UserUnit;
  primaryUnit: UserUnit & { code: string };
  primaryPointOfSale: UserUnit;
  organizations: Array<UserOrganization>;
  units: Array<UserUnit>;
  pointOfSales: Array<UserUnit>;
}

export interface RecentTrip {
  origin: {
    code: string;
    name: string;
  };
  destination: {
    code: string;
    name: string;
  };
}
export interface BookmarkedTrip extends RecentTrip {
  id: string;
}

export interface PosConfiguration {
  pointOfSaleId: string;
  pointOfSaleName: string;
  currencies: Array<string>;
  purchaseConfirmationEnabled: boolean;
  ticketDeliveryEnabled: boolean;
}

interface RetailerSelection {
  organizationId?: string;
  salesUnitCode?: string;
  pointOfSaleId?: string;
}

export const setShowRetalerModal = createAction<boolean>(
  'user/setShowRetalerModal'
);

export const selectDefaultCurrency = createAction<string>(
  'user/selectDefaultCurrency'
);

export const selectRetailer = createAction<RetailerSelection>(
  'user/selectRetailer'
);

export const getUser = createAsyncThunk<User, void>(
  'user/getUser',
  async () => (await api.get('/users')).data
);

export const getFavoriteTrips = createAsyncThunk<Array<BookmarkedTrip>>(
  'user/getFavoriteTrips',
  async () =>
    (await api.get<{ items: Array<BookmarkedTrip> }>('/favorite-stops')).data
      .items
);

export const addTripToFavorites = createAsyncThunk<
  BookmarkedTrip,
  { origin: string; destination: string }
>(
  'user/addTripToFavorites',
  async (stopPair) => (await api.post('/favorite-stops', stopPair)).data
);

export const removeTripFromFavorites = createAsyncThunk<void, string>(
  'user/removeTripFromFavorites',
  async (pairId) => (await api.delete(`/favorite-stops/${pairId}`)).data
);

export const getPosConfiguration = createAsyncThunk<
  PosConfiguration,
  string | undefined
>(
  'user/getPosConfiguration',
  async (posId) =>
    // use random id if not provided
    (await api.get(`/agent-tool/${posId ?? v4()}/configuration`)).data
);
