import { createReducer } from '@reduxjs/toolkit';
import {
  BookmarkedTrip,
  getFavoriteTrips,
  getPosConfiguration,
  getUser,
  PosConfiguration,
  selectDefaultCurrency,
  selectRetailer,
  setShowRetalerModal,
  User,
} from 'features/user/userActions';

interface UserState {
  current?: User;
  organizationId?: string;
  salesUnitCode?: string;
  pointOfSaleId?: string;
  favoriteTrips: Array<BookmarkedTrip>;
  showRetailerModal: boolean;
  defaultCurrency?: string;
  posConfiguration?: PosConfiguration;
}
const initialState: UserState = {
  favoriteTrips: [],
  showRetailerModal: false,
};
export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUser.fulfilled, (state, action) => {
      try {
        if (!state.current) {
          const { organizations } = action.payload;
          state.current = action.payload;
          state.showRetailerModal = !!organizations.length;
        }
      } catch (e) {}
    })
    .addCase(setShowRetalerModal, (state, action) => {
      state.showRetailerModal = action.payload;
    })
    .addCase(selectRetailer, (state, action) => {
      const { organizationId, salesUnitCode, pointOfSaleId } = action.payload;
      state.organizationId = organizationId;
      state.salesUnitCode = salesUnitCode;
      state.pointOfSaleId = pointOfSaleId;
    })
    .addCase(getFavoriteTrips.fulfilled, (state, action) => {
      state.favoriteTrips = action.payload;
    })
    .addCase(getPosConfiguration.fulfilled, (state, action) => {
      const { currencies } = action.payload;
      state.posConfiguration = action.payload;
      state.defaultCurrency = currencies[0];
    })
    .addCase(selectDefaultCurrency, (state, action) => {
      state.defaultCurrency = action.payload;
    });
});
