import {
  Card,
  CardHeader,
  Divider,
  Link,
  Slide,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Button, FormControl, Icon, Switch } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { makeStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TransTitle } from 'i18n/trans/title';
import { TabContext, TabPanel } from '@mui/lab';
import { PassengerDetails } from 'routes/tickets/checkout/PassengerDetails';
import { Overview } from 'routes/tickets/checkout/Overview';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'store/utils';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { JourneyInfo } from 'components/JourneyInfo';
import { getBookingDestinations, getBookingTrips } from 'utils/trip';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { resetTripOffers } from 'features/trip/tripActions';
import { deleteBooking } from 'features/booking/bookingActions';
import { Loadable } from '@fleet/shared';
import { SearchTab, SearchTabsContext } from 'components/SearchTabsContext';
import { Ancillaries } from 'routes/tickets/checkout/Ancillaries';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '4px 32px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
  },
  detailsToggler: {
    width: 'auto',
    marginLeft: 'auto',
    '& > label': {
      maxWidth: 'initial',
    },
    '& .MuiBox-root, & .MuiSwitch-root': {
      marginBottom: 0,
    },
  },
  confirmBtnWrap: {
    marginTop: 32,
    padding: '24px 32px',
    background: theme.palette.background.default,
  },
  stepper: {
    counterReset: 'step',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  step: {
    flex: 1,
    padding: '16px 0',
    position: 'relative',
    color: theme.palette.primary.main,
    '& > div': {
      zIndex: 1,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '1.7rem',
      zIndex: 0,
      left: 0,
      right: 0,
      height: '1px',
      background: theme.palette.divider,
    },
    '&:first-child::before': {
      left: '50%',
    },
    '&:last-child::before': {
      right: '50%',
    },
    '&$stepSelected, &$stepCompleted': {
      '& $stepIcon': {
        color: 'white',
      },
    },
    '&$stepSelected': {
      boxShadow: 'none',
      color: theme.palette.text.primary,
      '& $stepIcon': {
        borderColor: theme.palette.text.primary,
        background: theme.palette.text.primary,
      },
    },
    '&$stepCompleted': {
      '& $stepIcon': {
        '& svg': {
          display: 'block',
        },
        '&:after': {
          display: 'none',
        },
        background: theme.palette.primary.main,
      },
    },
  },
  stepIcon: {
    display: 'inline-flex',
    width: 24,
    height: 24,
    counterIncrement: 'step',
    boxSizing: 'border-box',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    '& svg': {
      display: 'none',
      transform: 'scale(0.675)',
    },
    '&:after': {
      content: 'counter(step)',
      margin: 'auto',
      lineHeight: 1,
    },
  },
  tabsIndicator: {
    display: 'none',
  },
  stepCompleted: {},
  stepSelected: {},
}));

interface CheckoutProps extends RouteComponentProps {
  initBooking: (
    tab: SearchTab,
    updateTab: SearchTabsContext['updateTab']
  ) => void;
}

export const Checkout: FC<CheckoutProps> = ({ history, initBooking }) => {
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const bookingCheckoutLoading = useSelector(bookingCheckoutLoadingSelector);
  const { currentTab, updateTab } = useContext(SearchTabsContext);
  const tabs = useMemo(
    () => ['passengerDetails', 'addons', 'overview'] as const,
    []
  );
  const [showDetails, setDetailsShown] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(0);
  const detailsToggleHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDetailsShown(e.target.checked);
    },
    []
  );
  const cancelBookingHandler = useCallback(async () => {
    await dispatch(deleteBooking());
    await dispatch(resetTripOffers(''));
    updateTab({ bookingId: undefined, name: '' });
    history.replace('/tickets/search');
  }, [dispatch, history, updateTab]);

  const goToNextStep = useCallback(() => {
    const nextStepIdx = activeTab + 1;
    if (nextStepIdx === tabs.length) {
      history.replace('/tickets/success');
    }
    setActiveTab(nextStepIdx);
  }, [activeTab, history, tabs]);
  const tabsContent = useMemo(
    () => ({
      passengerDetails: <PassengerDetails goToNextStep={goToNextStep} />,
      addons: <Ancillaries goToNextStep={goToNextStep} />,
      overview: <Overview goToNextStep={goToNextStep} />,
    }),
    [goToNextStep]
  );

  useEffect(() => {
    initBooking(currentTab!, updateTab);
    setActiveTab(0);
  }, [currentTab, initBooking, updateTab]);

  useEffect(() => {
    isBookingExpired &&
      alert.error(<TransAlert i18nKey="bookingHasExpired" />, {
        timeout: 0,
      });
  }, [alert, isBookingExpired]);

  const [outboundTrips] = getBookingTrips(booking);
  const [originStopName, destinationStopName] = getBookingDestinations(booking);
  return (
    <Loadable loading={bookingCheckoutLoading}>
      <Card className={classes.header}>
        <Button
          variant="outlined"
          startIcon={<Icon name="direction-left" />}
          label={<TransButton i18nKey="cancelBooking" />}
          onClick={cancelBookingHandler}
          disabled={isBookingExpired}
        />
        <OverflowEllipsis
          variant="h2"
          content={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h2">{originStopName}</Typography>
              <Icon name="transfer" />
              <Typography variant="h2">{destinationStopName}</Typography>
            </Stack>
          }
          sx={{ p: 2 }}
        />
        <FormControl
          label={<TransSubtitle i18nKey="detailedTripInfo" />}
          labelPosition="top"
          className={classes.detailsToggler}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">Off</Typography>
            <Switch value={showDetails} onChange={detailsToggleHandler} />
            <Typography variant="body2">On</Typography>
          </Stack>
        </FormControl>
      </Card>
      {!bookingCheckoutLoading && (
        <Stack direction="row" spacing={3} mt={3}>
          <Card sx={{ width: '100%' }}>
            <CardHeader
              sx={{
                px: 4,
                background: (theme) => theme.palette.primary.main,
                color: 'white',
              }}
              title={
                <Typography variant="h2">
                  <TransTitle i18nKey="checkout" />
                </Typography>
              }
            />
            {booking && (
              <TabContext value={tabs[activeTab]}>
                <Tabs centered value={activeTab} className={classes.stepper}>
                  {tabs.map((tab, idx) => (
                    <Tab
                      key={idx}
                      classes={{
                        root: classNames(classes.step, {
                          [classes.stepCompleted]: idx < activeTab,
                        }),
                        selected: classes.stepSelected,
                      }}
                      label={
                        <Stack alignItems="center" spacing={1}>
                          <Icon name="tick" className={classes.stepIcon} />
                          <Typography variant="body1">
                            <TransSubtitle i18nKey={tab} />
                            {idx < activeTab && (
                              <Link
                                sx={{ ml: 0.5 }}
                                onClick={() => setActiveTab(idx)}
                              >
                                <TransButton i18nKey="edit" />
                              </Link>
                            )}
                          </Typography>
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                <Divider />
                {tabs.map((tab) => (
                  <TabPanel key={tab} value={tab} sx={{ pb: 10 }}>
                    {tabsContent[tab]}
                  </TabPanel>
                ))}
              </TabContext>
            )}
          </Card>
          <Slide direction="left" in={showDetails} mountOnEnter unmountOnExit>
            <Card sx={{ p: 3, width: 330 }}>
              <Typography variant="h2">
                <TransSubtitle i18nKey="detailedTripInfo" />
              </Typography>
              <Stack mt={2} spacing={2}>
                {!!outboundTrips?.length && (
                  <Stack spacing={2}>
                    <Typography variant="subtitle">
                      <TransSubtitle i18nKey="outbound" />
                    </Typography>
                    <JourneyInfo trips={outboundTrips} />
                  </Stack>
                )}
              </Stack>
            </Card>
          </Slide>
        </Stack>
      )}
    </Loadable>
  );
};
