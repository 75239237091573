import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { Header } from 'components/Header';
import { Route, Switch } from 'react-router';
import { useAuth } from 'react-oidc-context';
import { AppVersion } from '@fleet/shared';
import { getClassifications } from 'features/classification/classificationActions';
import { useDispatch, useSelector } from 'store/utils';
import { api, getErrHandler } from 'features/api';
import { BookingSearch } from 'routes/BookingSearch';
import { Tickets } from 'routes/Tickets';
import { BookingDetails } from 'routes/BookingDetails';
import { TripStopsModal } from 'components/TripStopsModal';
import { useAlert } from 'react-alert';
import { RetailerModal } from 'components/RetailerModal';
import { getFavoriteTrips, getUser } from 'features/user/userActions';
import {
  selectPosConfiguration,
  selectRequestorHeaderContent,
  selectShowRetailerModal,
  selectUser,
  selectUserUnits,
} from 'features/user/userSelector';
import { initialLoadingSelector } from 'features/loading/loadingSelectors';
import AuthWrapper from 'AuthWrapper';
import _isEmpty from 'lodash/isEmpty';

export const App = () => {
  const auth = useAuth();
  const alert = useAlert();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const posConfiguration = useSelector(selectPosConfiguration);
  const loading = useSelector(initialLoadingSelector);
  const userUnits = useSelector(selectUserUnits);
  const retailerModalShown = useSelector(selectShowRetailerModal);
  const requestorHeaderContent = useSelector(selectRequestorHeaderContent);
  const canShowRoutes = useMemo(() => {
    return user && !retailerModalShown && !_isEmpty(posConfiguration);
  }, [retailerModalShown, user, posConfiguration]);

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Requestor: requestorHeaderContent,
      },
    }));
    return () => api.interceptors.request.eject(requestInterceptor);
  }, [requestorHeaderContent]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    dispatch(getUser());
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    const errInterceptor = api.interceptors.response.use(
      (res) => res,
      getErrHandler(auth, alert)
    );
    return () => api.interceptors.response.eject(errInterceptor);
  }, [alert, auth]);

  useEffect(() => {
    if (!userUnits.organizationId) return;
    dispatch(getClassifications());
    dispatch(getFavoriteTrips());
  }, [dispatch, userUnits.organizationId]);

  useEffect(() => {
    if (posConfiguration) {
      document.title = posConfiguration.pointOfSaleName;
    }
  }, [posConfiguration]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={loading}>
          <Header />
          {canShowRoutes && (
            <Switch>
              <Route exact path="/">
                <Redirect to="/tickets" />
              </Route>
              <Route path="/tickets" component={Tickets} />
              <Route path="/bookings" component={BookingSearch} exact />
              <Route path="/booking/:id" component={BookingDetails} exact />
            </Switch>
          )}
          <TripStopsModal />
          {retailerModalShown && <RetailerModal />}
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
};
