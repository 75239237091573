import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  addComment: <Trans i18nKey="title.addComment" defaults="Add new comment" />,
  journeyNotifications: (
    <Trans
      i18nKey="title.journeyNotifications"
      defaults="Journey notifications"
    />
  ),
  addCorporateCode: (
    <Trans
      i18nKey="title.addCorporateCode"
      defaults="Add corporate agreement code"
    />
  ),
  addons: <Trans i18nKey="title.addons" defaults="Add-ons" />,
  addPromoCode: (
    <Trans i18nKey="title.addPromoCode" defaults="Add promo code" />
  ),
  booking: <Trans i18nKey="title.booking" defaults="Booking {{ num }}" />,
  bookingComplete: (
    <Trans i18nKey="title.bookingComplete" defaults="Booking complete" />
  ),
  bookingSearch: (
    <Trans i18nKey="title.bookingSearch" defaults="Booking search" />
  ),
  bookingSummary: (
    <Trans i18nKey="title.bookingSummary" defaults="Booking summary" />
  ),
  cancelSelected: (
    <Trans i18nKey="title.cancelSelected" defaults="Cancel selected" />
  ),
  checkout: <Trans i18nKey="title.checkout" defaults="Checkout" />,
  chooseRetailer: (
    <Trans i18nKey="title.chooseRetailer" defaults="Choose retailer" />
  ),
  compartmentPreferences: (
    <Trans
      i18nKey="title.compartmentPreferences"
      defaults="Compartment preferences"
    />
  ),
  overview: <Trans i18nKey="title.overview" defaults="Overview" />,
  passengerDetails: (
    <Trans i18nKey="title.passengerDetails" defaults="Passenger details" />
  ),
  payerDetails: <Trans i18nKey="title.payerDetails" defaults="Payer details" />,
  paymentDetails: (
    <Trans i18nKey="title.paymentDetails" defaults="Payment details" />
  ),
  paymentMethod: (
    <Trans i18nKey="title.paymentMethod" defaults="Payment method" />
  ),
  refundSelected: (
    <Trans i18nKey="title.refundSelected" defaults="Refund selected" />
  ),
  searchResults: (
    <Trans i18nKey="title.searchResults" defaults="Search results" />
  ),
  ticketFulfillment: (
    <Trans i18nKey="title.ticketFulfillment" defaults="Ticket fulfillment" />
  ),
  viewStops: <Trans i18nKey="title.viewStops" defaults="View stops" />,
});
