import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  addAddon: <Trans i18nKey="button.addAddon" defaults="Add new add-on" />,
  addCode: <Trans i18nKey="button.addCode" defaults="Add another code" />,
  addonSelection: (
    <Trans i18nKey="button.addonSelection" defaults="Add-on selection" />
  ),
  addPassengerCard: (
    <Trans i18nKey="button.addPassengerCard" defaults="Add customer card" />
  ),
  addRecipient: (
    <Trans i18nKey="button.addRecipient" defaults="Add new recipient" />
  ),
  advancedSearch: (
    <Trans i18nKey="button.advancedSearch" defaults="Advanced search" />
  ),
  back: <Trans i18nKey="button.back" defaults="Back" />,
  backToSearch: (
    <Trans i18nKey="button.backToSearch" defaults="Back to search" />
  ),
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  cancelBooking: (
    <Trans i18nKey="button.cancelBooking" defaults="Cancel booking" />
  ),
  cancelSelected: (
    <Trans i18nKey="button.cancelSelected" defaults="Cancel selected" />
  ),
  clearAll: <Trans i18nKey="button.clearAll" defaults="Clear all" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  closeDetails: (
    <Trans i18nKey="button.closeDetails" defaults="Close details" />
  ),
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  confirmCancel: (
    <Trans i18nKey="button.confirmCancel" defaults="Confirm cancel" />
  ),
  confirmRefund: (
    <Trans i18nKey="button.confirmRefund" defaults="Confirm refund" />
  ),
  confirmSelection: (
    <Trans i18nKey="button.confirmSelection" defaults="Confirm selection" />
  ),
  confirmTransaction: (
    <Trans i18nKey="button.confirmTransaction" defaults="Confirm transaction" />
  ),
  continueCheckout: (
    <Trans i18nKey="button.continueCheckout" defaults="Continue to checkout" />
  ),
  corporateAgreementCode: (
    <Trans
      i18nKey="button.corporateAgreementCode"
      defaults="Corporate agreement code"
    />
  ),
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  details: <Trans i18nKey="button.details" defaults="Details" />,
  download: <Trans i18nKey="button.download" defaults="Download" />,
  downloadTickets: (
    <Trans i18nKey="button.downloadTickets" defaults="Download tickets" />
  ),
  edit: <Trans i18nKey="button.edit" defaults="Edit" />,
  expandAll: <Trans i18nKey="button.expandAll" defaults="Expand all" />,
  modifySelected: (
    <Trans i18nKey="button.modifySelected" defaults="Modify selected" />
  ),
  newBooking: <Trans i18nKey="button.newBooking" defaults="New booking" />,
  newTrip: <Trans i18nKey="button.newTrip" defaults="New trip" />,
  next: <Trans i18nKey="button.next" defaults="Next" />,
  notAvailable: (
    <Trans i18nKey="button.notAvailable" defaults="Not available" />
  ),
  paymentOverview: (
    <Trans i18nKey="button.paymentOverview" defaults="Overview and payment" />
  ),
  postComment: <Trans i18nKey="button.postComment" defaults="Post comment" />,
  prev: <Trans i18nKey="button.prev" defaults="Previous" />,
  printTickets: (
    <Trans i18nKey="button.printTickets" defaults="Print tickets" />
  ),
  promoCode: <Trans i18nKey="button.promoCode" defaults="Promo code" />,
  refundDetails: (
    <Trans i18nKey="button.refundDetails" defaults="Refund details" />
  ),
  refundSelected: (
    <Trans i18nKey="button.refundSelected" defaults="Refund selected" />
  ),
  resetFields: <Trans i18nKey="button.resetFields" defaults="Reset fields" />,
  save: <Trans i18nKey="button.save" defaults="Save" />,
  saveChanges: <Trans i18nKey="button.saveChanges" defaults="Save changes" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  searchResultsSettings: (
    <Trans
      i18nKey="button.searchResultsSettings"
      defaults="Search results settings"
    />
  ),
  selectTrip: <Trans i18nKey="button.selectTrip" defaults="Select trip" />,
  sendBookingConfirmation: (
    <Trans
      i18nKey="button.sendBookingConfirmation"
      defaults="Send booking confirmation"
    />
  ),
  sendTickets: <Trans i18nKey="button.sendTickets" defaults="Send tickets" />,
  sendTicketsToPassengers: (
    <Trans
      i18nKey="button.sendTicketsToPassengers"
      defaults="Send tickets to passengers"
    />
  ),
  specifyDetails: (
    <Trans i18nKey="button.specifyDetails" defaults="Specify details" />
  ),
  viewAllDayJourneys: (
    <Trans
      i18nKey="button.viewAllDayJourneys"
      defaults="View all journeys for the day ({{num}})"
    />
  ),
  viewFullRoute: (
    <Trans i18nKey="button.viewFullRoute" defaults="View full route" />
  ),
  viewStops: <Trans i18nKey="button.viewStops" defaults="View stops" />,
});
