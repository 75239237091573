import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

interface TagProps {
  bold?: boolean;
  color?: 'primary' | 'secondary' | 'success';
  variant?: TypographyProps['variant'];
}

export const Tag: FC<TagProps> = ({
  children,
  color = 'secondary',
  bold,
  variant = 'body1',
}) => (
  <Typography
    variant={variant}
    color="white"
    fontWeight={bold ? 'bold' : 'normal'}
    component="span"
    sx={{
      padding: '0.25rem 0.6125rem',
      background: (theme) => theme.palette[color].main,
      borderRadius: '0.25rem',
    }}
  >
    {children}
  </Typography>
);
