import { Stack, Typography } from '@mui/material';
import { HintWrapper } from 'components/HintWrapper';
import { TransField } from 'i18n/trans/field';
import { FC } from 'react';

interface PriceWithFeeProps {
  price: number;
  currency: string;
  vatFee: number;
}

export const PriceWithFee: FC<PriceWithFeeProps> = ({
  price,
  currency,
  vatFee,
}) => {
  return (
    <HintWrapper
      placement="bottom-end"
      content={
        <Typography variant="body2">{[price, currency].join(' ')}</Typography>
      }
      hint={
        <Typography variant="body2" component="div" sx={{ width: '11.625rem' }}>
          {[price, vatFee].map((amount) => (
            <Stack direction="row" justifyContent="space-between" key={amount}>
              <TransField
                i18nKey={amount === price ? 'totalPrice' : 'totalVAT'}
              />
              <span>{[amount, currency].join(' ')}</span>
            </Stack>
          ))}
        </Typography>
      }
    />
  );
};
