import { Tooltip } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { FC, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Icon } from '@fleet/shared/mui';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Button, useFormContext } from '@fleet/shared';
import { useFieldArray } from '@fleet/shared/form/hooks/useFieldArray';
import { PassengerCard } from './PassengerCard';
import { TransButton } from 'i18n/trans/button';
import { v4 } from 'uuid';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useHistory } from 'react-router-dom';

interface SearchPassengersProps {
  name: string;
}

const useStyles = makeStyles((theme) => ({
  value: {
    alignSelf: 'center',
    '& .MuiButton-text': {
      ...theme.typography.body2,
      textDecoration: 'underline',
      padding: 0,
      minWidth: 0,
    },
  },
  control: {
    alignSelf: 'center',
    '& svg': {
      strokeWidth: '2px',
    },
  },
}));

const CARD_WIDTH = 341;

export const SearchPassengers: FC<SearchPassengersProps> = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();
  const [controlsShown, setControlsShown] = useState(false);
  const form = useFormContext();
  const initialValue = useMemo(
    () => [
      {
        type: 'PERSON',
        externalReference: v4(),
      },
    ],
    []
  );
  const { fields, meta } = useFieldArray(name, {
    validate: (value) => (value.length ? undefined : 'required'),
    initialValue,
    subscription: { value: true, invalid: true },
    form,
  });
  const { value, push, pop, remove } = fields;
  const prevValueRef = useRef(value);

  useEffect(() => {
    if (prevValueRef.current.length !== value.length) {
      prevValueRef.current = value;
      history.replace('/tickets');
    }
  }, [history, value]);

  const onClickOutside = useCallback(() => {
    if (meta.invalid) {
      form.submit();
    } else if (controlsShown) {
      setControlsShown(false);
    }
  }, [form, controlsShown, meta]);

  return (
    <Tooltip
      arrow={false}
      open={controlsShown}
      theme="light"
      placement="bottom-end"
      disableFocusListener
      offset={[0, 0]}
      PopperProps={{
        sx: {
          zIndex: 1000,
          '& > .MuiTooltip-tooltipPlacementBottom': {
            marginTop: '24px!important',
            maxWidth: `calc((${CARD_WIDTH}px + 16px) * 3 + 16px)`,
            padding: 0,
          },
        },
      }}
      content={
        value.length && (
          <ClickAwayListener onClickAway={onClickOutside}>
            <Grid
              container
              columns={value.length > 3 ? 3 : value.length}
              spacing={2}
              rowSpacing={2}
              p={2}
              pt={0}
            >
              {value.map((_, idx) => (
                <Grid item xs={1} key={idx}>
                  <PassengerCard
                    width={CARD_WIDTH}
                    number={idx + 1}
                    name={`${name}[${idx}]`}
                    onRemove={() => remove(idx)}
                  />
                </Grid>
              ))}
            </Grid>
          </ClickAwayListener>
        )
      }
    >
      <Stack direction="row" justifyContent="space-between" px={1}>
        <IconButton
          disabled={!value.length}
          className={classes.control}
          onClick={(e) => {
            e.stopPropagation();
            pop();
          }}
        >
          <Icon name="minus" size={18} />
        </IconButton>
        <Stack alignItems="flex-start" className={classes.value}>
          <Typography variant="subtitle">
            <TransSubtitle
              i18nKey="passengersQty"
              count={value.length}
              tOptions={{ postProcess: 'interval' }}
            />
          </Typography>
          <Button
            variant="text"
            disabled={!value.length}
            onClick={() => setControlsShown(true)}
          >
            <TransButton i18nKey="details" />
          </Button>
        </Stack>

        <IconButton
          className={classes.control}
          onClick={(e) => {
            e.stopPropagation();
            push({ type: 'PERSON', externalReference: v4() });
          }}
        >
          <Icon name="plus" size={20} />
        </IconButton>
      </Stack>
    </Tooltip>
  );
};
