import { Icon, Table, TableColumns } from '@fleet/shared';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { HintWrapper } from 'components/HintWrapper';
import { PriceWithFee } from 'components/PriceWithFee';
import { BookingAdmission, FulfillmentStatus } from 'dto/booking';
import { TripLeg } from 'dto/trip';
import {
  currentBookingSelector,
  selectRefundOffers,
} from 'features/booking/bookingSelectors';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { useSelector } from 'store/utils';

export enum PassengerAdmissionsColumns {
  selection = 'selection',
  status = 'status',
  ticketNumber = 'ticketNumber',
  origin = 'origin',
  destination = 'destination',
  productName = 'productName',
  passengerType = 'passengerType',
  passengerCards = 'passengerCards',
  changeable = 'changeable',
  refundable = 'refundable',
  flexibility = 'flexibility',
  price = 'price',
  refundableAmount = 'refundableAmount',
}

interface PassengerAdmissionsTableProps {
  selected?: Array<string>;
  data: Array<BookingAdmission>;
  onRowSelectionUpdate?: (selection: Array<string>) => void;
  hiddenColumns?: Array<
    PassengerAdmissionsColumns[keyof PassengerAdmissionsColumns]
  >;
}

const useStyles = makeStyles(
  (theme) => ({
    selectionDisabled: {
      '& > td:first-of-type': {
        cursor: 'not-allowed',
        '& label': {
          pointerEvents: 'none',
        },
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
      opacity: 0.75,
    },
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
    cancelled: {},
  }),
  {
    name: 'PassengerAdmissionsTable',
  }
);
export const PassengerAdmissionsTable: FC<PassengerAdmissionsTableProps> = ({
  selected,
  data,
  onRowSelectionUpdate,
  hiddenColumns,
}) => {
  const classes = useStyles();
  const { bookedTrips } = useSelector(currentBookingSelector)!;
  const refundOffers = useSelector(selectRefundOffers)!;
  const preparedSelectedRows = useMemo(
    () =>
      selected?.reduce((selection, id) => ({ ...selection, [id]: true }), {}) ??
      {},
    [selected]
  );
  const bookingLegs = useMemo(
    () =>
      bookedTrips.reduce<Array<TripLeg>>(
        (acc, trip) => [...acc, ...trip.legs],
        []
      ),
    [bookedTrips]
  );
  const getAdmissionLeg = useCallback(
    (coveredLegIds: Array<string>, type: 'origin' | 'destination') => {
      const admissionLegs = coveredLegIds
        .map((legId) => bookingLegs.find(({ id }) => id === legId)!)
        .sort(
          (a, b) =>
            new Date(a.departureTime).getTime() -
            new Date(b.departureTime).getTime()
        );
      if (type === 'origin') return admissionLegs[0];
      else return admissionLegs[admissionLegs.length - 1];
    },
    [bookingLegs]
  );
  const columns = useMemo<TableColumns<BookingAdmission>>(
    () => [
      {
        id: PassengerAdmissionsColumns.status,
        Header: <TransTableHead i18nKey="status" />,
        accessor: ({ status }) => <TransField i18nKey={status} />,
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.ticketNumber,
        Header: <TransTableHead i18nKey="ticketNr" />,
        accessor: ({ fulfillments }) => fulfillments[0]?.controlNumber,
      },
      ...(['origin', 'destination'] as const).map((place) => ({
        id: PassengerAdmissionsColumns[place],
        Header: <TransTableHead i18nKey={place} />,
        accessor: ({ coveredLegIds, status }: BookingAdmission) => {
          const leg = getAdmissionLeg(coveredLegIds, place);
          return (
            leg && (
              <Stack
                className={classNames({
                  [classes.strikeThrough]: [
                    FulfillmentStatus.RELEASED,
                    FulfillmentStatus.REFUNDED,
                  ].includes(status),
                })}
              >
                <Typography variant="body2">
                  {formatDate(
                    leg[place === 'origin' ? 'departureTime' : 'arrivalTime'],
                    currentDateTimeFormat
                  )}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  {
                    leg[place === 'origin' ? 'originStop' : 'destinationStop']
                      .name
                  }
                </Typography>
              </Stack>
            )
          );
        },
      })),
      {
        id: PassengerAdmissionsColumns.productName,
        Header: <TransTableHead i18nKey="productName" />,
        accessor: 'description',
      },
      {
        id: PassengerAdmissionsColumns.passengerType,
        Header: <TransTableHead i18nKey="passengerType" />,
        accessor: 'passengerTypes',
      },
      {
        id: PassengerAdmissionsColumns.passengerCards,
        Header: <TransTableHead i18nKey="passengerCards" />,
        accessor: 'passengerCards',
      },
      {
        id: PassengerAdmissionsColumns.flexibility,
        Header: <TransTableHead i18nKey="flexibility" />,
        accessor: ({ flexibility, ...admission }) => (
          <HintWrapper
            content={_capitalize(_startCase(flexibility))}
            hint={
              <Stack direction="row" spacing={0.5}>
                {(['exchangeable', 'refundable'] as const).map((field) => (
                  <Fragment key={field}>
                    <Typography variant="body2" fontWeight="bold">
                      <TransSubtitle i18nKey={field} />:
                    </Typography>
                    <Typography variant="body2">
                      {_capitalize(_startCase(admission[field]))}.
                    </Typography>
                  </Fragment>
                ))}
              </Stack>
            }
          />
        ),
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.changeable,
        Header: <TransTableHead i18nKey="changeable" />,
        accessor: (admission) => {
          const exchangeable = admission.exchangeable === 'YES';
          return (
            <Icon
              name={exchangeable ? 'check-circle' : 'deactivate'}
              size={exchangeable ? 16 : 14}
              color={exchangeable ? 'success' : 'error'}
            />
          );
        },
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.refundable,
        Header: <TransTableHead i18nKey="refundable" />,
        accessor: (admission) => {
          const refundable = admission.refundable === 'YES';
          return (
            <Icon
              name={refundable ? 'check-circle' : 'deactivate'}
              size={refundable ? 16 : 14}
              color={refundable ? 'success' : 'error'}
            />
          );
        },
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.price,
        Header: <TransTableHead i18nKey="price" />,
        accessor: ({ price }) => (
          <PriceWithFee
            price={price.amount}
            currency={price.currency}
            vatFee={
              price.vats?.reduce((total, { amount }) => total + amount, 0) ?? 0
            }
          />
        ),
        width: 80,
      },
      {
        id: PassengerAdmissionsColumns.refundableAmount,
        Header: <TransTableHead i18nKey="refundableAmount" />,
        accessor: ({ fulfillments }) => {
          const passengerRefundOffer = refundOffers?.passengerOffers.find(
            ({ fulfillmentId }) =>
              fulfillments.map(({ id }) => id).includes(fulfillmentId)
          );
          return (
            passengerRefundOffer &&
            `${passengerRefundOffer.refundableAmount.amount} ${passengerRefundOffer.refundableAmount.currency}`
          );
        },
      },
    ],
    [refundOffers, classes.strikeThrough, getAdmissionLeg]
  );

  const table = useTable(
    {
      initialState: {
        selectedRowIds: preparedSelectedRows,
        hiddenColumns: hiddenColumns as Array<string>,
      },
      data: useMemo(() => data, [data]),
      columns,
      getRowId: ({ id }) => id,
    },
    useRowSelect,
    useRowSelectCheckbox
  );

  const selectedIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );
  useEffect(() => {
    onRowSelectionUpdate?.(selectedIds);
  }, [onRowSelectionUpdate, selectedIds]);

  return <Table table={table} classes={{ table: classes.table }} />;
};
