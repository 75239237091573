import { FC, useCallback, useMemo, useState } from 'react';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { PassengersSelection } from 'routes/bookingDetails/modal/PassengersSelection';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import {
  Button,
  FormProvider,
  Icon,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBookingSelector,
  selectRefundOffers,
} from 'features/booking/bookingSelectors';
import {
  confirmRefund,
  getBooking,
  initiateRefund,
} from 'features/booking/bookingActions';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { BookingAdmission } from 'dto/booking';
import _uniq from 'lodash/uniq';

interface RefundModalProps {
  selectedAdmissions: Array<BookingAdmission>;
  selectedPassengerIds: Array<string>;
  onClose: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    modalPaper: {
      overflow: 'inherit',
    },
    detailsStep: {
      width: 400,
    },
    refundDetails: {
      position: 'absolute',
      left: '100%',
      top: 0,
      background: theme.palette.background.default,
      boxShadow: theme.shadows[2],
      zIndex: -1,
    },
  }),
  {
    name: 'RefundModal',
  }
);
export const RefundModal: FC<RefundModalProps> = ({
  selectedAdmissions,
  selectedPassengerIds,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useSelector(currentBookingSelector)!;
  const loading = useSelector(currentBookingLoadingSelector);
  const refundOffers = useSelector(selectRefundOffers);
  const submitDisabled = useMemo(
    () =>
      selectedAdmissions.some(({ fulfillments }: BookingAdmission) =>
        fulfillments.some(({ status }) => status === 'REFUNDED')
      ),
    [selectedAdmissions]
  );
  const [step, setStep] = useState(submitDisabled ? 2 : 1);

  const selectedFulfillmentIds = useMemo(
    () =>
      _uniq(
        selectedAdmissions
          .map(({ fulfillments }) => fulfillments.map(({ id }) => id))
          .flat()
      ),
    [selectedAdmissions]
  );
  const onReasonSubmit = useCallback(
    async ({ refundReason }) => {
      try {
        await dispatch(
          initiateRefund({
            bookingId: id,
            fulfillmentIds: selectedFulfillmentIds,
            overruleCode: refundReason,
          })
        ).unwrap();
        setStep(2);
      } catch (e) {
        onClose();
      }
    },
    [onClose, dispatch, id, selectedFulfillmentIds]
  );
  const onRefundSubmit = useCallback(async () => {
    await dispatch(
      confirmRefund({
        bookingId: id,
        refundOfferIds: refundOffers!.bookingOffers.map(({ id }) => id),
      })
    ).unwrap();
    await dispatch(getBooking(id));
    onClose();
  }, [dispatch, id, refundOffers, onClose]);

  const { form, handleSubmit } = useForm({
    onSubmit: onReasonSubmit,
  });

  return (
    <ModalWrap
      open={!loading}
      onClose={() => onClose()}
      title={<TransTitle i18nKey="refundSelected" />}
      maxWidth="lg"
      classes={{
        paper: classNames(classes.modalPaper, {
          [classes.detailsStep]: step === 1,
        }),
      }}
    >
      {step === 1 && (
        <FormProvider {...form}>
          <Stack
            component="form"
            name="refundDetails"
            onSubmit={handleSubmit}
            spacing={3}
          >
            <SelectField
              options={[
                { label: "Client's wish", value: '' },
                { label: "Carrier's fault", value: 'TECHNICAL_FAILURE' },
                { label: 'Wrong sale', value: 'SALES_STAFF_ERROR' },
              ]}
              name="refundReason"
              label={<TransField i18nKey="refundReason" />}
            />
            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button variant="text" onClick={onClose}>
                <TransButton i18nKey="cancel" />
              </Button>
              <Button variant="contained" type="submit">
                <TransButton i18nKey="next" />
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      )}
      {step === 2 && (
        <PassengersSelection
          type="refund"
          selected={selectedPassengerIds}
          submitIcon={<Icon name="arrow-right" />}
          submitLabel={<TransButton i18nKey="confirmRefund" />}
          onSubmit={onRefundSubmit}
          submitDisabled={submitDisabled}
          onClose={onClose}
        />
      )}
    </ModalWrap>
  );
};
