import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Loader, ThemeProvider } from '@fleet/shared/mui';
import { store } from 'store';
import { Provider } from 'react-redux';
import { App } from 'App';
import { AuthProvider } from 'react-oidc-context';
import { Provider as AlertProvider } from 'react-alert';
import { alertProviderProps } from '@fleet/shared/components/Alert';
import authConf from 'authConf';
import 'i18n';
import 'index.scss';

ReactDOM.render(
  <StrictMode>
    <ThemeProvider>
      <Suspense fallback={<Loader active size="fullscreen" />}>
        <Provider store={store}>
          <AuthProvider {...authConf}>
            <AlertProvider {...alertProviderProps}>
              <App />
            </AlertProvider>
          </AuthProvider>
        </Provider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
