import { createLoadingSelector } from 'store/utils';
import {
  searchBookings,
  getBooking,
  triggerBookingFulfillment,
  deleteBooking,
  initiateRefund,
  initiateRelease,
  confirmRefund,
  confirmRelease,
  sendConfirmation,
  getComments,
  updateComment,
  addComment,
  deleteComment,
} from 'features/booking/bookingActions';
import {
  updatePassengerDetails,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import {
  addTripToFavorites,
  getFavoriteTrips,
  getUser,
  removeTripFromFavorites,
} from 'features/user/userActions';
import {
  getClassifications,
  getPlaces,
} from 'features/classification/classificationActions';

export const initialLoadingSelector = createLoadingSelector(
  getUser,
  getClassifications,
  getFavoriteTrips
);
export const bookingSearchLoadingSelector =
  createLoadingSelector(searchBookings);
export const currentBookingLoadingSelector = createLoadingSelector(
  getBooking,
  initiateRefund,
  confirmRefund,
  initiateRelease,
  confirmRelease,
  sendConfirmation,
  getComments,
  addComment,
  updateComment,
  deleteComment
);

export const bookingCheckoutLoadingSelector = createLoadingSelector(
  deleteBooking,
  getBooking,
  updatePassengerDetails,
  updatePurchaserDetails,
  triggerBookingFulfillment,
  sendConfirmation
);
export const passengerUpdateLoading = createLoadingSelector(
  updatePassengerDetails
);

export const purchaserUpdateLoading = createLoadingSelector(
  updatePurchaserDetails
);

export const favoriteTripsLoading = createLoadingSelector(
  getFavoriteTrips,
  addTripToFavorites,
  removeTripFromFavorites
);
export const ticketsLoading = createLoadingSelector(getPlaces);
