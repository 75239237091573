import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransParagraph = createTrans({
  admissionStatusHint: (
    <Trans
      i18nKey="paragraph.admissionStatusHint"
      defaults="Some of the admissions have different status. Please refer to admissions tab"
    />
  ),
  addonsBookingLevelEmpty: (
    <Trans
      i18nKey="paragraph.addonsBookingLevelEmpty"
      defaults="No leg level add-ons added"
    />
  ),
  addonsLegLevelEmpty: (
    <Trans
      i18nKey="paragraph.addonsLegLevelEmpty"
      defaults="No booking level add-ons added"
    />
  ),
  admissionSelectionCanceled: (
    <Trans
      i18nKey="paragraph.admissionSelectionCanceled"
      defaults="Some or all of selected admissions are already cancelled/refunded"
    />
  ),
  agentToolUserNameHint: (
    <Trans
      i18nKey="paragraph.agentToolUserNameHint"
      defaults="Search is made by agent tool user who sold the booking. Please type in the person's first and/or last name."
    />
  ),
  bookingSuccess: (
    <Trans
      i18nKey="paragraph.bookingSuccess"
      defaults="Your tickets have been generated and are available to download. The download should start automatically within 5 seconds. If it doesn’t then click below to download."
    />
  ),
  cancelWarning: (
    <Trans
      i18nKey="paragraph.cancelWarning"
      defaults="Are you sure you want to cancel the selected bookings?"
    />
  ),
  corporateAgreementHint: (
    <Trans
      i18nKey="paragraph.corporateAgreementHint"
      defaults="Corporate agreement code represents the contract between a carrier and a corporate customer. Search is made over the bookings where the corporate agreement code has been applied."
    />
  ),
  deletedComment: (
    <Trans
      i18nKey="paragraph.deletedComment"
      defaults="Deleted comment {{date}}"
    />
  ),
  emptySearch: (
    <Trans
      i18nKey="paragraph.emptySearch"
      defaults="Requested date has no available trips"
    />
  ),
  fees: <Trans i18nKey="paragraph.fees" defaults="Fees" />,
  lastModified: (
    <Trans i18nKey="paragraph.lastModified" defaults="Last modified {{date}}" />
  ),
  referenceNumberHint: (
    <Trans
      i18nKey="paragraph.referenceNumberHint"
      defaults="Search is made by booking, ticket or external reference number"
    />
  ),
  refundableAmount: (
    <Trans i18nKey="paragraph.refundableAmount" defaults="Refundable amount" />
  ),
  refundDetails: (
    <Trans i18nKey="paragraph.refundDetails" defaults="Refund details" />
  ),
  refundFee: <Trans i18nKey="paragraph.refundFee" defaults="Refund fee" />,
  refundWarning: (
    <Trans
      i18nKey="paragraph.refundWarning"
      defaults="Are you sure you want to refund the selected bookings?"
    />
  ),
  retailerHint: (
    <Trans
      i18nKey="paragraph.retailerHint"
      defaults="Search is made by retailer organization (travel agency, agent) who sold the booking. User related retailers are available, please start typing the name and choose the retailer from the list"
    />
  ),
  serviceHint: (
    <Trans
      i18nKey="paragraph.serviceHint"
      defaults="Search is made by service code or line number"
    />
  ),
  specifyRefundDetails: (
    <Trans
      i18nKey="paragraph.specifyRefundDetails"
      defaults="Please specify refund details"
    />
  ),
});
