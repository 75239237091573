import { Icon, Tooltip } from '@fleet/shared';
import { Stack, TooltipProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface HintWrapperProps {
  content: ReactNode;
  hint: ReactNode;
  placement?: TooltipProps['placement'];
}

export const HintWrapper: FC<HintWrapperProps> = ({
  content,
  hint,
  placement,
}) => {
  return (
    <Tooltip content={hint} placement={placement}>
      <Stack direction="row" alignItems="center">
        {content}
        <Icon name="info-circle" margin />
      </Stack>
    </Tooltip>
  );
};
