import { Table } from '@fleet/shared/components/Table';
import { Column, Row, useExpanded, useTable } from 'react-table';
import { FC, useCallback, useMemo, useState, MouseEvent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardHeader, Stack, Typography } from '@mui/material';
import { Icon, useFormContext } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import {
  selectUnconfiguredOffer,
  tripLinksMapSelector,
  tripsSelector,
} from 'features/trip/tripSelector';
import { Journey, Offer } from 'dto/trip';
import {
  getDuration,
  getHasJourneyNotifications,
  getOnDemandServiceTexts,
  getTimeString,
} from 'utils/trip';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { JourneyInfo } from 'components/JourneyInfo';
import { DaysSelector } from 'components/DaysSelector';
import { parse, toMinutes } from 'duration-fns';
import { Tag } from 'components/Tag';
import {
  selectTripOffer,
  showTripsResultPage,
} from 'features/trip/tripActions';
import { LegInfo } from 'components/LegInfo';
import classNames from 'classnames';
import { addDays, set } from 'date-fns';
import { useField } from 'react-final-form-hooks';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { ServiceTextsModal } from 'routes/tickets/searchResults/ServiceTextsModal';
import { CompartmentPreferencesModal } from 'routes/tickets/searchResults/CompartmentPreferencesModal';

const useStyles = makeStyles(
  (theme) => ({
    header: {
      height: 48,
      padding: '0px 32px',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
    },
    hidden: {
      display: 'none',
    },
    selectTripBtn: {
      cursor: 'pointer',
      background: theme.palette.background.default,
      borderBottom: 'none',
      borderRadius: 4,
      padding: '14px 26px 14px 12px',
    },
    notificationsBtn: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    cell: {
      '& > div': {
        padding: '0!important',
      },
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '8px!important',
      '&$controlCell': {
        width: 180,
        paddingRight: '16px!important',
      },
      '&$resplusCell': {
        paddingLeft: '0!important',
        width: 14,
      },
      '&$tagsCell': {
        width: 120,
      },
      '&$summaryCell': {
        width: 400,
      },
    },
    changes: {
      '&:empty': {
        display: 'none',
      },
      '&:before': {
        content: '"("',
      },
      '&:after': {
        content: '")"',
      },
      '& :nth-child(2):before': {
        content: '" + "',
      },
    },
    controlCell: {},
    controlCellHidden: {},
    resplusCell: {},
    summaryCell: {},
    tagsCell: {},
    disabledRow: {
      background: theme.palette.background.default,
      cursor: 'not-allowed',
    },
    subRowCell: {
      padding: 32,
      background: theme.palette.background.default,
    },
    expandBtn: {
      px: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: 1,
      textDecoration: 'underline',
      padding: 0,
      minWidth: 0,
    },
    legInfo: {
      marginRight: '8px',
    },
    rowExpanded: {
      '& $notificationsBtn': {
        color: theme.palette.common.white,
      },
    },
  }),
  { name: 'TripsTable' }
);

interface TripsTableProps {
  isOutbound?: boolean;
  onNextDay?: () => void;
  onPrevDay?: () => void;
}

export const TripsTable: FC<TripsTableProps> = ({ isOutbound = false }) => {
  const [showNotificationsFor, setShowNotificationsFor] = useState<Journey>();
  const unconfiguredOffer = useSelector(selectUnconfiguredOffer);
  const fieldName = isOutbound ? 'departureTime' : 'arrivalTime';
  const form = useFormContext();
  const {
    input: { value: selectedDayStr },
  } = useField(fieldName, form);
  const selectedDay = useMemo(
    () => set(new Date(selectedDayStr), { hours: 0, minutes: 0 }),
    [selectedDayStr]
  );
  const handleChangeDay = useCallback(
    (direction: 'prev' | 'next') => {
      form.change(
        fieldName,
        formatDate(
          addDays(selectedDay, direction === 'next' ? 1 : -1),
          isoDateTimeFormat
        )
      );
      form.submit();
    },
    [fieldName, form, selectedDay]
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResultData = useSelector(tripsSelector);
  const resultLinksMap = useSelector(tripLinksMapSelector);
  const journeys = useMemo(
    () =>
      searchResultData.filter((journey) => journey.isOutbound === isOutbound),
    [searchResultData, isOutbound]
  );
  const fastestJourneyDuration = useMemo(() => {
    const [fastestJourney] = [...journeys].sort(
      (a, b) => toMinutes(parse(a.duration)) - toMinutes(parse(b.duration))
    );
    return fastestJourney?.duration;
  }, [journeys]);
  const lowestJourneyPrice = useMemo(() => {
    const [lowestPriceJourney] = [...journeys]
      .filter(({ lowestPrice }) => lowestPrice)
      .sort((a, b) => a.lowestPrice.amount - b.lowestPrice.amount);
    return lowestPriceJourney?.lowestPrice.amount;
  }, [journeys]);
  const getJourneyLegs = useCallback(
    (journey: Journey) => journey.trips.map(({ legs }) => legs).flat(),
    []
  );

  const selectOnlyOffers = useCallback(
    ({ trips, isOutbound, reference }: Journey) => {
      if (trips.every(({ offers }) => offers.length === 1)) {
        dispatch(
          selectTripOffer({
            reference,
            isOutbound,
            offers: trips.reduce<Array<Offer>>(
              (offers, trip) => [...offers, ...trip.offers],
              []
            ),
          })
        );
      }
    },
    [dispatch]
  );

  const getNotificationsShowHandler = useCallback(
    (row: Journey) => (e: MouseEvent) => {
      e.stopPropagation();
      setShowNotificationsFor(row);
    },
    []
  );
  const columns = useMemo<Column<Journey>[]>(
    () => [
      {
        id: 'resplus',
        accessor: ({ alliances }) =>
          alliances?.includes('RESPLUS') ? (
            <Icon name="resplus" height={52} width={14} />
          ) : null,
      },
      {
        id: 'summary',
        accessor: (row) => (
          <Typography variant="subtitle">
            <Stack direction="row" spacing={3} sx={{ mb: 0.5 }}>
              <div>{getTimeString(row.departureTime)}</div>
              <div>{row.originStop.name}</div>
            </Stack>
            <Stack direction="row" spacing={3}>
              <div>{getTimeString(row.arrivalTime)}</div>
              <div>{row.destinationStop.name}</div>
            </Stack>
          </Typography>
        ),
      },
      {
        id: 'details',
        accessor: (row) => (
          <>
            <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
              <Typography>
                {getDuration(row.departureTime, row.arrivalTime)}
              </Typography>
              <Typography
                fontWeight="600"
                color="warning.main"
                className={classes.changes}
              >
                {[
                  row.transfers && (
                    <span key="transfers">
                      <TransSubtitle
                        i18nKey="changes"
                        values={{
                          count: row.transfers,
                        }}
                        tOptions={{ postProcess: 'interval' }}
                      />
                    </span>
                  ),
                  !!getOnDemandServiceTexts(row).length && (
                    <span key="onDemand">
                      <TransSubtitle i18nKey="onDemandTransport" />
                    </span>
                  ),
                ].filter(Boolean)}
              </Typography>
              {getHasJourneyNotifications(row) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.25}
                  className={classes.notificationsBtn}
                  onClick={getNotificationsShowHandler(row)}
                >
                  <Icon name="info-circle" />
                  <Typography>
                    <TransSubtitle i18nKey="notifications" />
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack direction="row" rowGap={1} flexWrap="wrap">
              {getJourneyLegs(row).map((leg) => (
                <LegInfo key={leg.id} {...leg} className={classes.legInfo} />
              ))}
              {}
            </Stack>
          </>
        ),
      },
      {
        id: 'tags',
        accessor: ({ duration, lowestPrice }) => {
          const isLowestPrice =
            lowestPrice && lowestJourneyPrice === lowestPrice.amount;
          return (
            <Stack sx={{ '& > span': { alignSelf: 'flex-end' } }} spacing="4px">
              {duration === fastestJourneyDuration && (
                <Tag bold>
                  <TransSubtitle i18nKey="fastest" />
                </Tag>
              )}
              {isLowestPrice && (
                <Tag bold>
                  <TransSubtitle i18nKey="lowestPrice" />
                </Tag>
              )}
            </Stack>
          );
        },
      },
      {
        id: 'controls',
        accessor: (journey) => {
          if (!journey.lowestPrice)
            return (
              <Typography color="warning.main" align="center" fontWeight="bold">
                <TransButton i18nKey="notAvailable" />
              </Typography>
            );
          return (
            <CardHeader
              classes={{
                root: classes.selectTripBtn,
              }}
              title={
                <Typography color="text.primary" fontWeight="bold">
                  <TransButton i18nKey="selectTrip" />
                </Typography>
              }
              subheader={
                <Typography color="secondary.light" noWrap>
                  <TransSubtitle
                    i18nKey="priceFrom"
                    values={{
                      price: journey.lowestPrice.amount,
                      currency: journey.lowestPrice.currency,
                    }}
                  />
                </Typography>
              }
              action={<Icon name="chevron-right" color="black" size={20} />}
              onClick={() => {
                selectOnlyOffers(journey);
              }}
            />
          );
        },
      },
    ],
    [
      classes.changes,
      classes.notificationsBtn,
      classes.legInfo,
      classes.selectTripBtn,
      getNotificationsShowHandler,
      getJourneyLegs,
      lowestJourneyPrice,
      fastestJourneyDuration,
      selectOnlyOffers,
    ]
  );

  const renderSubRow = useCallback(
    (row: Row<Journey>) => {
      const { reference, trips, lowestPrice } = row.original;
      return (
        <JourneyInfo
          reference={reference}
          trips={trips}
          isOutbound={isOutbound}
          showOffers={!!lowestPrice}
        />
      );
    },
    [isOutbound]
  );

  const table = useTable<Journey>(
    {
      data: journeys,
      columns,
    },
    useExpanded
  );

  return (
    <Card>
      <Stack direction="row" className={classes.header} spacing={2}>
        <Typography variant="subtitle">
          <TransSubtitle i18nKey={isOutbound ? 'outbound' : 'inbound'} />
        </Typography>
        <Typography variant="body2" color="secondary.main">
          <TransSubtitle
            i18nKey="journeysFound"
            values={{ num: journeys.length }}
          />
        </Typography>
        <DaysSelector value={selectedDay} onChange={handleChangeDay} />
      </Stack>
      <Table
        table={table}
        getSubRow={renderSubRow}
        getHeaderGroupProps={{
          className: classes.hidden,
        }}
        getRowProps={(_, { row }) => ({
          className: classNames({ [classes.rowExpanded]: row.isExpanded }),
        })}
        getCellProps={(_, { cell }) => ({
          className: classNames(classes.cell, {
            [classes.resplusCell]: cell.column.id === 'resplus',
            [classes.controlCell]: cell.column.id === 'controls',
            [classes.tagsCell]: cell.column.id === 'tags',
            [classes.summaryCell]: cell.column.id === 'summary',
          }),
        })}
        getSubRowCellProps={{
          className: classes.subRowCell,
        }}
      />
      {isOutbound && (
        <Stack
          direction="row"
          justifyContent="center"
          p={1}
          component={Card}
          spacing={3}
        >
          <Button
            onClick={() =>
              dispatch(showTripsResultPage(resultLinksMap.previous.rel))
            }
            variant="text"
            className={classes.expandBtn}
            startIcon={<Icon name="chevron-left" />}
            disabled={!resultLinksMap.previous}
          >
            <TransButton i18nKey="prev" />
          </Button>
          <Button
            onClick={() =>
              dispatch(showTripsResultPage(resultLinksMap.next.rel))
            }
            variant="text"
            className={classes.expandBtn}
            endIcon={<Icon name="chevron-right" />}
            disabled={!resultLinksMap.next}
          >
            <TransButton i18nKey="next" />
          </Button>
        </Stack>
      )}
      {showNotificationsFor && (
        <ServiceTextsModal
          journey={showNotificationsFor}
          onClose={() => setShowNotificationsFor(undefined)}
        />
      )}
      {unconfiguredOffer && (
        <CompartmentPreferencesModal offer={unconfiguredOffer} />
      )}
    </Card>
  );
};
