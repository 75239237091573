import { Tooltip } from '@fleet/shared/mui';
import { FC, ReactNode, useMemo, useRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface OverflowEllipsisProps extends TypographyProps {
  content: ReactNode;
}

export const OverflowEllipsis: FC<OverflowEllipsisProps> = ({
  content,
  variant = 'body1',
  ...props
}) => {
  const contentRef = useRef<HTMLElement>(null);
  const isOverflown = useMemo(() => {
    return (
      !!contentRef.current &&
      contentRef.current.scrollWidth > contentRef.current.clientWidth
    );
  }, [content, contentRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Tooltip content={isOverflown ? content : null}>
      <Typography {...props} variant={variant} ref={contentRef} noWrap>
        {content}
      </Typography>
    </Tooltip>
  );
};
