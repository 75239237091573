import { PriceWithFee } from 'components/PriceWithFee';
import { Tag } from 'components/Tag';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Icon, Table, TableColumns, Tooltip } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { Row, useExpanded, useRowSelect, useTable } from 'react-table';
import { Button, Stack, Typography } from '@mui/material';
import { useModal, useRowSelectCheckbox } from '@fleet/shared/hooks';
import { TransButton } from 'i18n/trans/button';
import {
  BookingAdmission,
  BookingDetailsPassenger,
  FulfillmentStatus,
} from 'dto/booking';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBookingSelector,
  selectAdmissionSelection,
} from 'features/booking/bookingSelectors';
import { PassengerSubRow } from 'routes/bookingDetails/passengerSubRow/PassengerSubRow';
import { RefundModal } from 'routes/bookingDetails/modal/RefundModal';
import { CancelModal } from 'routes/bookingDetails/modal/CancelModal';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { TransField } from 'i18n/trans/field';
import uniq from 'lodash/uniq';
import { updateAdmissionsSelection } from 'features/booking/bookingActions';
import { TransParagraph } from 'i18n/trans/paragraph';

interface PassengersAndTicketsProps {
  isOverview?: boolean;
}

interface PassengerData extends BookingDetailsPassenger {
  admissions: BookingAdmission[];
}

let refreshKey = 0;
export const PassengersAndTickets: FC<PassengersAndTicketsProps> = ({
  isOverview,
}) => {
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState<'refund' | 'cancel'>();
  const { onOpen, onClose } = useModal();
  const toggleModal = useCallback(
    (type?: 'refund' | 'cancel') => {
      if (type) {
        setModalType(type);
        onOpen();
      } else {
        setModalType(undefined);
        onClose();
      }
    },
    [onOpen, onClose]
  );
  const selectedAdmissionsIds = useSelector(selectAdmissionSelection);
  const { bookedTrips, passengers } = useSelector(currentBookingSelector)!;
  const bookingAdmissions = useMemo(
    () =>
      bookedTrips.reduce<Array<BookingAdmission>>(
        (admissions, trip) => [...admissions, ...trip.bookedOffer.admissions],
        []
      ),
    [bookedTrips]
  );
  const selectedAdmissions = useMemo(
    () =>
      Object.values(selectedAdmissionsIds)
        .map((admissionIds) =>
          admissionIds.map(
            (admissionId) =>
              bookingAdmissions.find(({ id }) => id === admissionId)!
          )
        )
        .flat(),
    [selectedAdmissionsIds, bookingAdmissions]
  );
  const passengersData = useMemo<Array<PassengerData>>(
    () =>
      passengers.map((passenger) => {
        return {
          ...passenger,
          admissions: bookedTrips
            .map(({ bookedOffer }) => bookedOffer.admissions)
            .flat()
            .filter(({ passengerIds }) => passengerIds!.includes(passenger.id)),
        };
      }),
    [bookedTrips, passengers]
  );
  const getRowId = useCallback((row: PassengerData) => row.id, []);
  const passengerStatusAccessor = useCallback(
    ({ admissions }: PassengerData) => {
      const statuses = admissions.reduce<Array<FulfillmentStatus>>(
        (statuses, { status }) => uniq([...statuses, status]),
        []
      );

      if (statuses.length === 1) {
        return <TransField i18nKey={statuses[0]} />;
      } else {
        return (
          <Stack direction="row">
            <TransField i18nKey="FULFILLED" />
            <Tooltip content={<TransParagraph i18nKey="admissionStatusHint" />}>
              <Icon name="info-circle" color="warning" sx={{ ml: 0.25 }} />
            </Tooltip>
          </Stack>
        );
      }
    },
    []
  );
  const journeySummaryAccessor = useCallback(
    ({}) => {
      const originTrip = bookedTrips[0];
      const destinationTrip =
        bookedTrips.length === 1
          ? bookedTrips[0]
          : bookedTrips[bookedTrips.length - 1];
      const showResplus = bookedTrips.some(({ alliances }) =>
        alliances.includes('RESPLUS')
      );
      return (
        <Stack direction="row" spacing={1}>
          <Typography variant="body2" noWrap>
            {`${formatDate(
              originTrip.departureTime,
              currentDateTimeFormat
            )} - ${formatDate(
              destinationTrip.arrivalTime,
              currentDateTimeFormat
            )}`}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2" fontWeight="bold" noWrap>
              {originTrip.originStop.name}
            </Typography>
            <Icon name="journey-changeover" width={32} />
            <Typography variant="body2" fontWeight="bold" noWrap>
              {destinationTrip.destinationStop.name}
            </Typography>
            {showResplus && (
              <Icon name="resplus-horizontal" height={14} width={52} />
            )}
          </Stack>
        </Stack>
      );
    },
    [bookedTrips]
  );

  const columns = useMemo<TableColumns<PassengerData>>(
    () => [
      {
        id: 'passengerName',
        accessor: ({ firstName, lastName }) =>
          [firstName.value, lastName.value].filter(Boolean).join(' '),
        Header: <TransTableHead i18nKey="passenger" />,
        width: 250,
      },
      {
        id: 'status',
        accessor: passengerStatusAccessor,
        Header: <TransTableHead i18nKey="status" />,
        width: 80,
      },
      {
        id: 'journeySummary',
        width: 'auto',
        accessor: journeySummaryAccessor,
        Header: <TransTableHead i18nKey="journey" />,
      },
      {
        id: 'age',
        accessor: ({ age }) =>
          age && (
            <Tag variant="body2">
              <TransField i18nKey="age" values={{ age }} />
            </Tag>
          ),
        Header: <TransTableHead i18nKey="age" />,
        width: 100,
      },
      {
        id: 'totalPrice',
        accessor: ({ admissions }) => {
          const currency = admissions[0]?.price.currency;
          const { price, vatFees } = admissions.reduce(
            (acc, admission) => {
              return {
                price: acc.price + admission.price.amount,
                vatFees:
                  acc.vatFees +
                  (admission.price.vats?.reduce(
                    (total, { amount }) => total + amount,
                    0
                  ) ?? 0),
              };
            },
            { price: 0, vatFees: 0 }
          );
          return (
            <PriceWithFee price={price} vatFee={vatFees} currency={currency} />
          );
        },
        Header: <TransTableHead i18nKey="totalPrice" />,
      },
    ],
    [journeySummaryAccessor, passengerStatusAccessor]
  );
  const hiddenColumns = useMemo(
    () => (isOverview ? ['selection', 'status', 'journeySummary'] : []),
    [isOverview]
  );

  const table = useTable(
    {
      data: passengersData,
      columns,
      getRowId,
      initialState: {
        hiddenColumns,
      },
    },
    useExpanded,
    useRowSelect,
    useRowSelectCheckbox
  );
  const { selectedRowIds } = table.state;
  const selectedPassengerIds = useMemo(
    () =>
      uniq([
        ...Object.keys(selectedRowIds),
        ...Object.keys(selectedAdmissionsIds).filter(
          (passengerId) => !!selectedAdmissionsIds[passengerId].length
        ),
      ]),
    [selectedRowIds, selectedAdmissionsIds]
  );
  const disableRefundReleaseControls = useMemo(
    () =>
      Object.values(selectedAdmissionsIds).every(
        (selection) => !selection.length
      ),
    [selectedAdmissionsIds]
  );

  const selectPassengerAdmissions = useCallback(
    (selectedPassengerIds) => {
      dispatch(
        updateAdmissionsSelection({
          ...passengers.reduce(
            (acc, passenger) => ({
              ...acc,
              [passenger.id]: selectedPassengerIds.includes(passenger.id)
                ? bookingAdmissions
                    .filter(({ passengerIds }) =>
                      passengerIds.includes(passenger.id)
                    )
                    .map(({ id }) => id)
                : [],
            }),
            {}
          ),
        })
      );
      refreshKey++;
    },
    [dispatch, passengers, bookingAdmissions]
  );

  useEffect(() => {
    selectPassengerAdmissions(Object.keys(selectedRowIds));
  }, [selectPassengerAdmissions, selectedRowIds]);

  const renderSubRow = useCallback(
    (row: Row<PassengerData>) => {
      return (
        <PassengerSubRow
          key={refreshKey}
          row={row.original}
          isOverview={isOverview}
        />
      );
    },
    [isOverview]
  );

  return (
    <>
      {!isOverview && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Button
            variant="text"
            sx={{ fontSize: '12px' }}
            disabled
            startIcon={<Icon name="ticket-manage" />}
          >
            <TransButton i18nKey="modifySelected" />
          </Button>
          <Button
            variant="text"
            onClick={() => toggleModal('refund')}
            disabled={disableRefundReleaseControls}
            sx={{ fontSize: '12px' }}
            startIcon={<Icon name="ticket-refund" />}
          >
            <TransButton i18nKey="refundSelected" />
          </Button>
          <Button
            variant="text"
            onClick={() => toggleModal('cancel')}
            disabled={disableRefundReleaseControls}
            sx={{ fontSize: '12px' }}
            startIcon={<Icon name="ticket-cancel" />}
          >
            <TransButton i18nKey="cancelSelected" />
          </Button>
        </Stack>
      )}
      <Table<PassengerData>
        table={table}
        {...(isOverview && {
          getHeaderGroupProps: { sx: { display: 'none' } },
          getRowProps: {
            sx: {
              background: 'white',
              '& td:last-of-type .MuiTypography-root': { marginLeft: 'auto' },
            },
          },
        })}
        getSubRow={renderSubRow}
      />
      {modalType === 'refund' && (
        <RefundModal
          selectedAdmissions={selectedAdmissions}
          selectedPassengerIds={selectedPassengerIds}
          onClose={toggleModal}
        />
      )}
      {modalType === 'cancel' && (
        <CancelModal
          selectedAdmissions={selectedAdmissions}
          selectedPassengerIds={selectedPassengerIds}
          onClose={toggleModal}
        />
      )}
    </>
  );
};
