import { createAsyncThunk } from 'store/utils';
import {
  BookingComment,
  BookingDetails,
  BookingRefundOffer,
  BookingReleaseOffer,
  BookingSearchFilter,
  BookingsSearchResult,
} from 'dto/booking';
import { api } from 'features/api';
import { createAction } from '@reduxjs/toolkit';

import { PassengerOfferSelection, PassengerSpecification } from 'dto/trip';
export const setBookingFilter =
  createAction<Partial<BookingSearchFilter>>('booking/setFilter');

export const resetCurrentBooking = createAction('booking/resetCurrentBooking');

export const updatePassengerAdmissionSelection = createAction<{
  passengerId: string;
  selection: Array<string>;
}>('booking/updatePassengerAdmissionSelection');

export const updateAdmissionsSelection = createAction<
  Record<string, Array<string>>
>('booking/updateAdmissionsSelection');

export const searchBookings = createAsyncThunk<
  { bookingsSearchResults: Array<BookingsSearchResult> },
  Partial<BookingSearchFilter> | undefined
>('booking/search', async (filter, { dispatch, getState }) => {
  const filterParams = filter || getState().booking.filter;
  filter && dispatch(setBookingFilter(filter));
  return (await api.post('/bookings-search', filterParams)).data;
});

export const getBooking = createAsyncThunk<BookingDetails, string>(
  'booking/getBooking',
  async (id) => {
    return (await api.get(`/bookings/${id}`)).data;
  }
);

export const postBooking = createAsyncThunk<
  BookingDetails,
  {
    passengerSpecifications: PassengerSpecification[];
    offers: Array<{
      id: string;
      passengerExternalReferences: Array<string>;
      selections?: Array<PassengerOfferSelection>;
      alliances: Array<string>;
    }>;
  }
>('booking/postBooking', async (payload, { dispatch }) => {
  const {
    booking: { id },
  } = (await api.post('/bookings', payload)).data;
  return await dispatch(getBooking(id)).unwrap();
});

export const deleteBooking = createAsyncThunk(
  'booking/deleteBooking',
  async (_, { getState }) => {
    const {
      booking: { current },
    } = getState();
    return (await api.delete(`/bookings/${current!.id!}`)).data;
  }
);

export const triggerBookingFulfillment = createAsyncThunk<
  BookingDetails,
  string
>('booking/triggerFulfillment', async (bookingId) => {
  return (
    await api.post(`/bookings/${bookingId}/fulfillments`, undefined, {
      headers: {
        Requestor:
          'ewogICAgImFwcGxpY2F0aW9uIjogIlRSQUlEIFdlYiBBcHBsaWNhdGlvbiIKfQ==',
      },
    })
  ).data;
});

export const initiateRefund = createAsyncThunk<
  {
    bookingOffers: Array<BookingRefundOffer>;
    passengerOffers: Array<BookingRefundOffer>;
  },
  { bookingId: string; fulfillmentIds: Array<string>; overruleCode?: string }
>(
  'booking/initiateRefund',
  async ({ bookingId, fulfillmentIds, ...payload }) => {
    const refundOffersPerPassenger = (
      await Promise.all(
        fulfillmentIds.map((id) =>
          api.post(`/bookings/${bookingId}/refund-offers`, {
            fulfillmentIds: [id],
            ...payload,
          })
        )
      )
    )
      .map(({ data }) => data.refundOffers)
      .flat();
    const bookingRefundOffers = (
      await api.post(`/bookings/${bookingId}/refund-offers`, {
        fulfillmentIds,
        ...payload,
      })
    ).data.refundOffers;

    return {
      passengerOffers: refundOffersPerPassenger,
      bookingOffers: bookingRefundOffers,
    };
  }
);

export const confirmRefund = createAsyncThunk<
  void,
  { bookingId: string; refundOfferIds: Array<string> }
>('booking/confirmRefund', async ({ bookingId, refundOfferIds }) => {
  await Promise.all(
    refundOfferIds.map((id) =>
      api.patch(`/bookings/${bookingId}/refund-offers/${id}`, {
        status: 'CONFIRMED',
      })
    )
  );
});

export const initiateRelease = createAsyncThunk<
  Array<BookingReleaseOffer>,
  { bookingId: string; fulfillmentIds: Array<string> }
>('booking/initiateRelease', async ({ bookingId, fulfillmentIds }) => {
  return (
    await api.post(`/bookings/${bookingId}/release-offers`, { fulfillmentIds })
  ).data.releaseOffers;
});

export const confirmRelease = createAsyncThunk<
  void,
  { bookingId: string; releaseOfferIds: Array<string> }
>('booking/confirmRelease', async ({ bookingId, releaseOfferIds }) => {
  await Promise.all(
    releaseOfferIds.map((id) =>
      api.patch(`/bookings/${bookingId}/release-offers/${id}`, {
        status: 'CONFIRMED',
      })
    )
  );
});

export const sendConfirmation = createAsyncThunk<
  void,
  {
    bookingId: string;
    emailsOverride?: Array<string>;
    url: 'purchase-confirmation' | 'ticket-delivery';
    passengers?: Array<{ passengerId: string }>;
  }
>(
  'booking/sendConfirmation',
  async ({ url, ...payload }) =>
    (await api.post(`/notifications/${url}/send`, payload)).data
);

export const getComments = createAsyncThunk<BookingComment[], string>(
  'booking/getComments',
  async (bookingId) => {
    return (await api.get(`/bookings/${bookingId}/comments`)).data.items;
  }
);

export const addComment = createAsyncThunk<
  BookingComment,
  { content: string; type: string }
>('booking/addComment', async (payload, { getState }) => {
  const {
    booking: { current },
  } = getState();
  return (await api.post(`/bookings/${current!.id}/comments`, payload)).data;
});

export const updateComment = createAsyncThunk<
  BookingComment,
  { content: string; id: string }
>('booking/updateComment', async ({ id, ...payload }, { getState }) => {
  const {
    booking: { current },
  } = getState();
  return (await api.patch(`/bookings/${current!.id}/comments/${id}`, payload))
    .data;
});

export const deleteComment = createAsyncThunk<void, string>(
  'booking/deleteComment',
  async (id, { getState }) => {
    const {
      booking: { current },
    } = getState();
    return (await api.delete(`/bookings/${current!.id!}/comments/${id}`)).data;
  }
);

export const getAdditionalOffers = createAsyncThunk<unknown, Array<string>>(
  'booking/getAdditionalOffers',
  async (bookedOfferIds, { getState }) => {
    const {
      booking: { current },
    } = getState();
    return await Promise.all(
      bookedOfferIds.map((id) =>
        api.get(
          `/bookings/${current!.id!}/booked-offers/${id}/additional-offers`
        )
      )
    );
  }
);
