import { FC, useMemo } from 'react';
import { useTable } from 'react-table';
import { Table, TableColumns } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { TripLeg } from 'dto/trip';
import { makeStyles } from '@mui/styles';
import { Stack, Typography } from '@mui/material';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { TransportBadge } from 'components/TransportBadge';
import _capitalize from 'lodash/capitalize';

interface PassengerLegsProps {
  data: Array<TripLeg>;
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
  }),
  {
    name: 'PassengerLegs',
  }
);
export const PassengerLegs: FC<PassengerLegsProps> = ({ data }) => {
  const classes = useStyles();
  const columns = useMemo<TableColumns<TripLeg>>(
    () => [
      {
        id: 'origin',
        Header: <TransTableHead i18nKey="origin" />,
        accessor: ({ originStop, departureTime }) => (
          <Stack>
            <Typography variant="body2">
              {formatDate(departureTime, currentDateTimeFormat)}
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {originStop.name}
            </Typography>
          </Stack>
        ),
      },
      {
        id: 'destination',
        Header: <TransTableHead i18nKey="destination" />,
        accessor: ({ destinationStop, arrivalTime }) => (
          <Stack>
            <Typography variant="body2">
              {formatDate(arrivalTime, currentDateTimeFormat)}
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {destinationStop.name}
            </Typography>
          </Stack>
        ),
      },
      {
        id: 'carrier',
        Header: <TransTableHead i18nKey="carrier" />,
        accessor: ({ ptMode, carrier }) => (
          <TransportBadge ptMode={ptMode} label={carrier.name} />
        ),
      },
      {
        id: 'seatClass',
        accessor: ({ travelClass }) => _capitalize(travelClass),
        Header: <TransTableHead i18nKey="seatClass" />,
      },
    ],
    []
  );

  const table = useTable({
    data: useMemo(() => data, [data]),
    columns,
  });

  return <Table table={table} classes={{ table: classes.table }} />;
};
