import {
  Offer,
  OfferAccommodationType,
  OfferBedProperties,
  OfferGenderProperties,
  OfferPetsProperties,
} from 'dto/trip';
import { FC, useCallback, useContext, useMemo } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  FormProvider,
  Modal,
  RadioGroupField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { TransField } from 'i18n/trans/field';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { renderToString } from 'react-dom/server';
import { useDispatch } from 'store/utils';
import {
  removeOfferSelection,
  updateOfferSelection,
} from 'features/trip/tripActions';
import { useModal } from '@fleet/shared/hooks';

interface CompartmentPreferencesModalProps {
  offer: Offer;
}

interface SelectionForm {
  passengers: Array<{
    reference: string;
    gender?: OfferGenderProperties;
    bed?: OfferBedProperties;
    pets?: OfferPetsProperties;
  }>;
}

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 776,
      '&$small': {
        width: 400,
      },
    },
    small: {},
    hidden: {
      display: 'none',
    },
  }),
  { name: 'CompartmentPreferencesModal' }
);

const FORM_ID = 'compartment-preferences';

export const CompartmentPreferencesModal: FC<CompartmentPreferencesModalProps> =
  ({ offer }) => {
    const { open, onClose } = useModal({ open: true });
    const classes = useStyles();
    const dispatch = useDispatch();
    const { currentTab } = useContext(SearchTabsContext);
    const isBedType =
      offer.offerAccommodationType === OfferAccommodationType.BED;
    const passengerSpecifications = useMemo(
      () => currentTab?.params?.passengerSpecifications ?? [],
      [currentTab?.params?.passengerSpecifications]
    );
    const [genderOptions, bedOptions, petsOptions] = [
      OfferGenderProperties,
      OfferBedProperties,
      OfferPetsProperties,
    ].map((props) =>
      Object.values(props).map((key) => ({
        label: renderToString(<TransSubtitle i18nKey={key} />),
        value: key,
      }))
    );

    const handleOnClose = useCallback(() => {
      onClose();
      dispatch(removeOfferSelection(offer.id));
    }, [dispatch, offer.id, onClose]);

    const onSubmit = useCallback(
      ({ passengers }: SelectionForm) => {
        const { id: offerId, reservationLegCoverage } = offer;
        const selections = reservationLegCoverage
          .map(({ tripId, legId, ...rest }) =>
            passengers.map(({ reference, ...properties }) => ({
              passengerIds: [reference],
              tripLegCoverage: {
                tripId,
                legId,
              },
              ...rest,
              placeProperties: Object.values(properties).filter(Boolean),
            }))
          )
          .flat();
        dispatch(updateOfferSelection({ offerId, selections }));
      },
      [dispatch, offer]
    );

    const { form, handleSubmit } = useForm<SelectionForm>({
      onSubmit,
      initialValues: {
        passengers: passengerSpecifications.map(({ externalReference }) => ({
          reference: externalReference,
          pets: petsOptions[0].value,
        })),
      },
    });

    return (
      <Modal
        classes={{
          paper: classNames(classes.paper, {
            [classes.small]: passengerSpecifications.length === 1,
          }),
        }}
        title={<TransTitle i18nKey="compartmentPreferences" />}
        open={open}
        onClose={handleOnClose}
        showCloseControl={false}
        actionButton={
          <Button variant="contained" form={FORM_ID} type="submit">
            <TransButton i18nKey="confirmSelection" />
          </Button>
        }
      >
        <FormProvider {...form}>
          <Grid
            component="form"
            id={FORM_ID}
            container
            columns={passengerSpecifications.length === 1 ? 1 : 2}
            spacing={1.5}
            onSubmit={handleSubmit}
          >
            {passengerSpecifications.map(
              ({ externalReference }, idx, passengers) => (
                <Grid item key={externalReference} xs={1}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle">
                      {`${idx + 1} / `}
                      <Typography variant="subtitle" color="text.secondary">
                        {passengers.length}
                      </Typography>
                    </Typography>
                    <div className={classes.hidden}>
                      <TextField
                        name={`passengers[${idx}].reference`}
                        disabled
                      />
                    </div>
                    {isBedType && (
                      <>
                        <SelectField
                          label={<TransField i18nKey="compartmentGender" />}
                          name={`passengers[${idx}].gender`}
                          options={genderOptions}
                          required={!!genderOptions.length}
                        />
                        <SelectField
                          label={<TransField i18nKey="bedPreference" />}
                          name={`passengers[${idx}].bed`}
                          options={bedOptions}
                          required={!!bedOptions.length}
                        />
                      </>
                    )}
                    <RadioGroupField
                      name={`passengers[${idx}].pets`}
                      options={petsOptions}
                      required={!!petsOptions.length}
                      inline
                    />
                  </Stack>
                </Grid>
              )
            )}
          </Grid>
        </FormProvider>
      </Modal>
    );
  };
