import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Grid, Link, Stack } from '@mui/material';
import { ReadOnlyField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import { makeStyles } from '@mui/styles';
import { TransButton } from 'i18n/trans/button';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { JourneysSummaries } from 'routes/bookingDetails/JourneySummaries';
import { TransportBadge } from 'components/TransportBadge';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { Price } from 'dto/trip';

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiTypography-body2': {
        minHeight: 0,
      },
    },
  }),
  { name: 'BookingsDetailsCard' }
);
interface BookingDetailsCardProps {
  onShowFullRoute: () => void;
}
export const BookingsDetailsCard: FC<BookingDetailsCardProps> = ({
  onShowFullRoute,
}) => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const handleViewFullRoute = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onShowFullRoute();
    },
    [onShowFullRoute]
  );
  const createdOn = useMemo(() => {
    const createdDate = booking?.bookedTrips?.[0].bookedOffer.createdOn;
    return createdDate && formatDate(createdDate, currentDateTimeFormat);
  }, [booking]);
  const getPrice = useCallback(
    (amount?: Price) => amount && `${amount.amount} ${amount.currency}`,
    []
  );
  const currentPrice = useMemo(
    () =>
      booking?.bookedTrips
        .map(({ bookedOffer }) => bookedOffer.admissions)
        .flat()
        .filter(({ fulfillments }) => fulfillments[0]?.status !== 'REFUNDED')
        .reduce<Price>(
          (acc, admission) => ({
            amount: acc.amount + admission.price.amount,
            currency: admission.price.currency,
          }),
          { amount: 0, currency: booking?.confirmedPrice.currency ?? '' }
        ),
    [booking?.bookedTrips, booking?.confirmedPrice.currency]
  );
  const bookingCarriers = useMemo(() => {
    const carriers = booking?.bookedTrips
      .map(({ legs }) =>
        legs.map(({ carrier, ptMode, serviceCode, lineNumber }) => ({
          ptMode,
          label: `${carrier.name} ${serviceCode ?? lineNumber}`,
        }))
      )
      .flat();

    return _uniqWith(carriers, _isEqual);
  }, [booking?.bookedTrips]);
  const journeys = useMemo(
    () => booking?.bookingParts.map(({ journey }) => journey).filter(Boolean),
    [booking]
  );

  return (
    <Grid
      container
      columns={6}
      spacing={2}
      rowSpacing={1}
      classes={{
        root: classes.root,
      }}
    >
      <Grid item xs={1}>
        <ReadOnlyField
          value={createdOn}
          label={<TransField i18nKey="createdOn" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField label={<TransField i18nKey="soldBy" />} />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField label={<TransField i18nKey="paymentMethod" />} />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={booking?.passengers.length}
          label={<TransField i18nKey="numOfPassengers" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={getPrice(booking?.provisionalPrice)}
          label={<TransField i18nKey="totalPriceInclVAT" />}
        />
      </Grid>
      <Grid item xs={1}>
        <ReadOnlyField
          value={getPrice(currentPrice)}
          label={<TransField i18nKey="remainingPrice" />}
        />
      </Grid>
      <Grid item xs={3} mt={3}>
        <ReadOnlyField
          contentComponent="div"
          value={<JourneysSummaries data={journeys} />}
          label={
            <>
              <TransField i18nKey="journeys" />
              &nbsp;
              <Link onClick={handleViewFullRoute}>
                <TransButton i18nKey="viewFullRoute" />
              </Link>
            </>
          }
        />
      </Grid>
      <Grid item xs={3} mt={3}>
        <ReadOnlyField
          contentComponent="div"
          value={
            <Stack direction="row" spacing={1}>
              {bookingCarriers.map((carrier, idx) => (
                <TransportBadge key={idx} {...carrier} />
              ))}
            </Stack>
          }
          label={<TransField i18nKey="carrier" />}
        />
      </Grid>
    </Grid>
  );
};
