import { createReducer } from '@reduxjs/toolkit';
import {
  Journey,
  JourneyLink,
  Offer,
  PassengerOfferSelection,
  TripLeg,
} from 'dto/trip';
import {
  resetTripOffers,
  searchTrips,
  selectTripOffer,
  showTripStops,
  setPdfDownload,
  showTripsResultPage,
  updateOfferSelection,
  removeOfferSelection,
} from 'features/trip/tripActions';
import omit from 'lodash/omit';

interface TripState {
  list: Array<Journey>;
  links: Array<JourneyLink>;
  showStopsFor?: TripLeg;
  downloadPdfTickets: boolean;
  selectedOffers: {
    reference?: string;
    list: Array<Offer>;
    selectionMap: Record<string, Array<PassengerOfferSelection>>;
  };
}

const initialState: TripState = {
  list: [],
  links: [],
  downloadPdfTickets: true,
  selectedOffers: {
    list: [],
    selectionMap: {},
  },
};

export const tripReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showTripStops, (state, action) => {
      state.showStopsFor = action.payload;
    })
    .addCase(setPdfDownload, (state, action) => {
      state.downloadPdfTickets = action.payload;
    })
    .addCase(resetTripOffers, (state, action) => {
      state.selectedOffers.reference = action.payload;
      state.selectedOffers = initialState.selectedOffers;
    })
    .addCase(selectTripOffer, (state, action) => {
      const { offers, reference } = action.payload;
      state.selectedOffers.reference = reference;
      state.selectedOffers.list = offers;
    })
    .addCase(updateOfferSelection, (state, action) => {
      const { offerId, selections } = action.payload;
      state.selectedOffers.selectionMap[offerId] = selections;
    })
    .addCase(removeOfferSelection, (state, { payload: offerId }) => {
      const { list, selectionMap } = state.selectedOffers;
      state.selectedOffers.list = list.filter(({ id }) => id !== offerId);
      state.selectedOffers.selectionMap = omit(selectionMap, offerId);
    })
    .addMatcher(
      (action) =>
        [searchTrips.pending.type, showTripsResultPage.pending.type].includes(
          action.type
        ),
      (state) => {
        state.list = [];
        state.selectedOffers = initialState.selectedOffers;
      }
    )
    .addMatcher(
      (action) =>
        [
          searchTrips.fulfilled.type,
          showTripsResultPage.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        state.list = action.payload.journeys;
        state.links = action.payload.links;
      }
    );
});
