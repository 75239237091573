import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { selector } from 'store/utils';
import { RootState } from 'store';

export const selectUser = selector((state: RootState) => state.user.current);

export const selectUserUnits = selector((state: RootState) => {
  const { organizationId, salesUnitCode, pointOfSaleId } = state.user;
  return {
    organizationId,
    salesUnitCode,
    pointOfSaleId,
    salesApplication: 'TRAID',
  };
});

export const selectFavoriteTrips = selector(
  (state: RootState) => state.user.favoriteTrips
);

export const selectShowRetailerModal = selector(
  (state: RootState) => state.user.showRetailerModal
);

export const selectPosConfiguration = selector(
  (state: RootState) => state.user.posConfiguration
);

export const selectEmailSendAvailable = selector((state: RootState) => {
  const conf = selectPosConfiguration(state);
  return !!(conf?.purchaseConfirmationEnabled || conf?.ticketDeliveryEnabled);
});

export const selectRequestorHeaderContent = selector((state: RootState) => {
  const userUnits = selectUserUnits(state);
  return (
    !_isEmpty(userUnits) &&
    window.btoa(JSON.stringify(_omit(userUnits, 'organizationId')))
  );
});

export const selectDefaultCurrency = selector(
  (state: RootState) => state.user.defaultCurrency
);
