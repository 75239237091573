import { Stack, Typography } from '@mui/material';
import { Button } from '@fleet/shared';
import { FC, ReactNode, useMemo, useState } from 'react';
import { PassengerSelection } from './PassengerSelection';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { selectRefundOffers } from 'features/booking/bookingSelectors';
import { TransParagraph } from 'i18n/trans/paragraph';
import { Price } from 'dto/trip';

interface PassengersSelectionProps {
  type: 'refund' | 'cancel';
  selected: Array<string>;
  submitDisabled?: boolean;
  submitIcon?: ReactNode;
  submitLabel: ReactNode;
  onSubmit: () => void;
  onClose: () => void;
}
export const PassengersSelection: FC<PassengersSelectionProps> = ({
  type,
  selected,
  submitIcon,
  submitLabel,
  submitDisabled,
  onSubmit,
  onClose,
}) => {
  const [initialSelected] = useState(selected);
  const refundOffers = useSelector(selectRefundOffers);
  const totalRefundAmount = useMemo<Price | undefined>(() => {
    if (submitDisabled || !refundOffers?.bookingOffers) return;
    return refundOffers?.bookingOffers?.reduce(
      (total, cur) => ({
        amount: total.amount + cur.refundableAmount.amount,
        currency: cur.refundableAmount.currency,
      }),
      { amount: 0, currency: '' }
    );
  }, [submitDisabled, refundOffers]);

  return (
    <Stack direction="column" spacing={3}>
      <Typography
        variant="body1"
        color={submitDisabled ? 'warning.main' : undefined}
      >
        <TransParagraph
          i18nKey={
            submitDisabled
              ? 'admissionSelectionCanceled'
              : type === 'refund'
              ? 'refundWarning'
              : 'cancelWarning'
          }
        />
      </Typography>
      <Stack direction="column">
        {initialSelected.map((passengerId) => (
          <PassengerSelection
            key={passengerId}
            passengerId={passengerId}
            type={type}
          />
        ))}
      </Stack>
      <Stack
        justifyContent={totalRefundAmount ? 'space-between' : 'flex-end'}
        direction="row"
      >
        {totalRefundAmount && (
          <Typography variant="subtitle">
            <TransSubtitle
              i18nKey="totalRefundValue"
              values={{
                value: [
                  totalRefundAmount.amount,
                  totalRefundAmount.currency,
                ].join(' '),
              }}
            />
          </Typography>
        )}
        <Stack direction="row" justifyContent="flex-end" justifySelf="flex-end">
          <Button variant="text" onClick={onClose}>
            <TransButton i18nKey="close" />
          </Button>
          <Button
            variant="contained"
            startIcon={submitIcon}
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            {submitLabel}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
