import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Icon } from '@fleet/shared';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { Tooltip } from '@fleet/shared/mui';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      background: 'white',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    },
    dark: {
      color: 'white',
      background: theme.palette.secondary.main,
    },
    content: {
      minWidth: 976,
      maxWidth: 1416,
      padding: '16px 24px',
      margin: '0 auto',
    },
    tooltip: {
      padding: 0,
      maxWidth: 'none',
      top: '-1rem',
    },
    total: {
      cursor: 'pointer',
    },
  }),
  { name: 'CartTotal' }
);

interface CartTotalProps {
  isDark?: boolean;
  offersTotal?: { amount: number; currency: string };
}

export const CartTotal: FC<CartTotalProps> = ({
  isDark,
  offersTotal,
  children,
}) => {
  const booking = useSelector(currentBookingSelector);
  const total = offersTotal ?? booking?.provisionalPrice;
  const classes = useStyles();
  return (
    <Box className={classNames(classes.root, { [classes.dark]: isDark })}>
      <Stack
        className={classes.content}
        direction="row"
        justifyContent="space-between"
      >
        <Tooltip
          theme="light"
          placement="top-start"
          content={<JourneyOverview />}
          className={classes.tooltip}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.total}
          >
            <Icon size={24} name="cart" />
            <Typography variant="h2">
              <TransSubtitle
                i18nKey="cartTotal"
                values={{
                  total: total && `${total.amount} ${total.currency}`,
                }}
              />
            </Typography>
          </Stack>
        </Tooltip>
        <Stack direction="row">{children}</Stack>
      </Stack>
    </Box>
  );
};
