import type { FC } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'History',
  }
);

interface HistoryProps {}

export const History: FC<HistoryProps> = () => {
  const classes = useStyles();

  return <div className={classes.root}>History</div>;
};
