import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { selectPosConfiguration } from 'features/user/userSelector';
import { TransButton } from 'i18n/trans/button';
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { CheckboxGroupField } from '@fleet/shared/form';
import { FieldArray, FormProvider, TextField } from '@fleet/shared';
import { Icon } from '@fleet/shared/mui';
import { TransField } from 'i18n/trans/field';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { FormApi } from 'final-form';
import classNames from 'classnames';

export interface TicketSelectionPayload {
  confirmationRecipient: Array<string>;
  additionalConfirmationRecipients: Array<string>;
  emailRecipients: Array<string>;
}

interface SendTicketsSelectionProps {
  form: FormApi<TicketSelectionPayload>;
  formId?: string;
  purchaserEmail?: string;
  isInline?: boolean;
  onSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    indicator: {
      height: 1,
    },
    hidden: {
      display: 'none',
    },
    tabs: {
      padding: '0 1rem',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    bgGray: {
      background: theme.palette.background.default,
    },
    addBtn: {
      alignSelf: 'flex-start',
      color: theme.palette.primary.main,
      background: alpha(theme.palette.action.hover, 0.05),
      '&:hover': {
        background: alpha(theme.palette.action.hover, 0.2),
      },
    },
  }),
  { name: 'SendTicketsSelection' }
);

enum SelectionTabs {
  sendBookingConfirmation = 'sendBookingConfirmation',
  sendTicketsToPassengers = 'sendTicketsToPassengers',
}
export const SendTicketsSelection: FC<SendTicketsSelectionProps> = ({
  form,
  formId,
  purchaserEmail,
  isInline,
  onSubmit,
}) => {
  const posConfiguration = useSelector(selectPosConfiguration);
  const classes = useStyles();
  const { purchaser, passengers } = useSelector(currentBookingSelector)!;
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const purchaserOptions = useMemo(() => {
    const email =
      purchaserEmail ?? purchaser.contactInformation.emailAddress.value;
    return [
      {
        label: (
          <TransSubtitle
            i18nKey="sendToPayer"
            values={{
              email,
            }}
          />
        ),
        value: email,
      },
    ];
  }, [purchaser.contactInformation.emailAddress.value, purchaserEmail]);

  useEffect(() => {
    if (!posConfiguration || !posConfiguration.purchaseConfirmationEnabled)
      return;
    form.reset({ confirmationRecipient: [purchaserOptions[0].value] });
  }, [form, posConfiguration, purchaserOptions]);

  const tabs = useMemo<Array<SelectionTabs>>(
    () => [
      ...(posConfiguration?.purchaseConfirmationEnabled
        ? [SelectionTabs.sendBookingConfirmation]
        : []),
      ...(posConfiguration?.ticketDeliveryEnabled
        ? [SelectionTabs.sendTicketsToPassengers]
        : []),
    ],
    [posConfiguration]
  );

  const activeTab = tabs[activeTabIdx];
  const passengerOptions = useMemo(
    () =>
      passengers.map(
        ({
          firstName,
          lastName,
          contactInformation: { emailAddress },
          id,
        }) => ({
          label: `${firstName.value} ${lastName.value} (${emailAddress.value})`,
          value: id,
        })
      ),
    [passengers]
  );

  return (
    <Card elevation={0}>
      <Tabs
        value={activeTabIdx}
        classes={{
          root: classNames({ [classes.tabs]: !isInline }),
          indicator: classNames(classes.indicator, {
            [classes.hidden]: isInline,
          }),
        }}
        onChange={(event: SyntheticEvent, nextIdx: number) => {
          setActiveTabIdx(nextIdx);
        }}
      >
        {tabs.map((key) => (
          <Tab
            key={key}
            classes={{ selected: classNames({ [classes.bgGray]: isInline }) }}
            label={
              <Typography variant="subtitle">
                <TransButton i18nKey={key} />
              </Typography>
            }
          />
        ))}
      </Tabs>
      <FormProvider {...form}>
        <CardContent
          id={formId}
          component="form"
          className={classNames({ [classes.bgGray]: isInline })}
          onSubmit={onSubmit}
        >
          {activeTab === SelectionTabs.sendBookingConfirmation && (
            <Stack spacing={2}>
              <CheckboxGroupField
                name="confirmationRecipient"
                options={purchaserOptions}
                required={!posConfiguration?.ticketDeliveryEnabled}
              />
              <Divider />
              <FieldArray name="additionalConfirmationRecipients">
                {({ fields }) => (
                  <>
                    {fields.value?.map((_, idx) => (
                      <Stack direction="row" spacing={1} key={idx}>
                        <TextField
                          required
                          email
                          name={`${fields.name}[${idx}]`}
                          label={
                            <TransField i18nKey="additionalEmailRecipient" />
                          }
                        />
                        <IconButton
                          sx={{ alignSelf: 'flex-start', top: '24px' }}
                          onClick={() => fields.remove(idx)}
                        >
                          <Icon name="close" />
                        </IconButton>
                      </Stack>
                    ))}
                    <Button
                      className={classes.addBtn}
                      startIcon={<Icon name="add" />}
                      variant="contained"
                      onClick={() => fields.push('')}
                    >
                      <TransButton i18nKey="addRecipient" />
                    </Button>
                  </>
                )}
              </FieldArray>
            </Stack>
          )}
          {activeTab === SelectionTabs.sendTicketsToPassengers && (
            <CheckboxGroupField
              name="passengerSelection"
              options={passengerOptions}
              required={!posConfiguration?.purchaseConfirmationEnabled}
            />
          )}
        </CardContent>
      </FormProvider>
    </Card>
  );
};
