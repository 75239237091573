import { FC, useCallback, useMemo } from 'react';
import { CardContent, Stack, Typography } from '@mui/material';
import { Icon, Table, TableColumns, Tooltip } from '@fleet/shared';
import { useColumnOrder, useTable } from 'react-table';
import { makeStyles } from '@mui/styles';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TableColumnSettings } from 'components/TableColumnSettings';
import { BookingsSearchResult } from 'dto/booking';
import { bookingsSearchResultsSelector } from 'features/booking/bookingSelectors';
import { useSelector } from 'store/utils';
import { Link } from 'react-router-dom';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';

interface BookingsTableProps {}

const useStyles = makeStyles(
  (theme) => ({
    thead: {
      zIndex: 0,
    },
    tableCell: {
      '&:first-of-type > div': {
        paddingLeft: 24,
      },
    },
    passengerCount: {
      textAlign: 'center',
      marginLeft: '0.5rem',
      cursor: 'pointer',
      width: 20,
      lineHeight: '20px',
      borderRadius: '50%',
      background: theme.palette.divider,
    },
    origin: {
      '& span': {
        marginLeft: 4,
        fontWeight: 'bold',
      },
    },
  }),
  {
    name: 'BookingsTable',
  }
);

export const BookingsTable: FC<BookingsTableProps> = () => {
  const classes = useStyles();
  const bookings = useSelector(bookingsSearchResultsSelector);
  const columns = useMemo<TableColumns<BookingsSearchResult>>(
    () => [
      {
        id: 'bookingReference',
        accessor: ({ id }) => (
          <Stack direction="row" spacing="4px">
            <Link to={`/booking/${id}`}>{id}</Link>
          </Stack>
        ),
        Header: <TransTableHead i18nKey="bookingReference" />,
      },
      {
        id: 'type',
        accessor: () => <Icon name="oneway" />,
        Header: <TransTableHead i18nKey="type" />,
        width: '50px',
      },
      {
        id: 'journeyOrigin',
        accessor: ({ journeyOrigin }) =>
          journeyOrigin && (
            <Typography variant="body2" className={classes.origin}>
              {formatDate(journeyOrigin.time, currentDateTimeFormat)}
              <span>{journeyOrigin.name}</span>
            </Typography>
          ),
        Header: <TransTableHead i18nKey="journeyOrigin" />,
      },
      {
        id: 'journeyDestination',
        accessor: ({ journeyDestination }) =>
          journeyDestination && (
            <Typography variant="body2" className={classes.origin}>
              {formatDate(journeyDestination.time, currentDateTimeFormat)}
              <span>{journeyDestination.name}</span>
            </Typography>
          ),
        Header: <TransTableHead i18nKey="journeyDestination" />,
      },
      {
        id: 'passengers',
        accessor: ({ passengers }) => {
          const [first, second, ...rest] = passengers.map(
            ({ firstName, lastName }) => `${firstName} ${lastName}`
          );
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              {[first, second, rest.length && '…'].filter(Boolean).join(', ')}
              {!!rest.length && (
                <Tooltip content={rest.join(', ')}>
                  <div className={classes.passengerCount}>
                    {`+${rest.length}`}
                  </div>
                </Tooltip>
              )}
            </Stack>
          );
        },
        Header: <TransTableHead i18nKey="passengers" />,
      },
      {
        id: 'paymentStatus',
        accessor: ({ status }) => (
          <Stack direction="row" spacing={1}>
            {status === 'PAID' && <Icon name="check" />}
            <Typography variant="body2">{status}</Typography>
          </Stack>
        ),
        Header: <TransTableHead i18nKey="paymentStatus" />,
      },
      {
        id: 'createdOn',
        accessor: ({ createdOn }) =>
          formatDate(createdOn, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="createdOn" />,
      },
    ],
    [classes.origin, classes.passengerCount]
  );

  const getRowId = useCallback((row) => row.id, []);
  const table = useTable<BookingsSearchResult>(
    {
      data: bookings,
      columns,
      getRowId,
    },
    useColumnOrder
  );

  return (
    <Table
      classes={{
        thead: classes.thead,
        cell: classes.tableCell,
      }}
      caption={
        <CardContent>
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle" fontWeight="700">
              <TransSubtitle i18nKey="searchResults" />
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
              <TransSubtitle
                i18nKey="bookingQty"
                count={bookings?.length}
                tOptions={{ postProcess: 'interval' }}
              />
            </Typography>
            {table && <TableColumnSettings table={table} />}
          </Stack>
        </CardContent>
      }
      table={table}
    />
  );
};
