import { Card, Stack, Typography } from '@mui/material';
import { Button, Icon } from '@fleet/shared';
import { FC, useCallback, useContext, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { useSelector } from 'store/utils';
import { downloadPdfFlagSelector } from 'features/trip/tripSelector';
import { useForm } from 'react-final-form';
import { v4 } from 'uuid';
import { TripSearchParams } from 'dto/trip';
import { SearchTab, SearchTabsContext } from 'components/SearchTabsContext';
import { downloadBookingTickets } from 'utils/trip';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { formatDate } from '@fleet/shared/utils/date';

let downloadTimeout: undefined | ReturnType<typeof setTimeout>;

interface BookingSuccessProps extends RouteComponentProps {
  initBooking: (
    tab: SearchTab,
    updateTab: SearchTabsContext['updateTab']
  ) => void;
}
export const BookingSuccess: FC<BookingSuccessProps> = ({
  history,
  initBooking,
}) => {
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const booking = useSelector(currentBookingSelector);
  const form = useForm<TripSearchParams>();
  const { activeTabIdx, currentTab, updateTab, closeTab } =
    useContext(SearchTabsContext);
  const downloadTicket = useCallback(() => {
    downloadPdf && downloadBookingTickets(booking);
  }, [booking, downloadPdf]);

  useEffect(() => {
    initBooking(currentTab!, updateTab);
    return () => {
      closeTab(activeTabIdx);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!booking) return;
    downloadTimeout = setTimeout(() => downloadTicket(), 5000);
    return () => clearTimeout(downloadTimeout);
  }, [booking, downloadTicket]);

  const onButtonDownload = useCallback(() => {
    clearTimeout(downloadTimeout);
    downloadTicket();
  }, [downloadTicket]);

  return (
    <Card sx={{ px: 4, py: 10 }}>
      <Stack
        sx={{ maxWidth: 448, m: 'auto' }}
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Icon size={34} name="check" />
        <Typography variant="title">
          <TransTitle i18nKey="bookingComplete" />
        </Typography>
        <Typography variant="body1" textAlign="center">
          <TransParagraph i18nKey="bookingSuccess" />
        </Typography>
        <Button
          fullWidth
          startIcon={<Icon name="download" />}
          onClick={onButtonDownload}
          label={<TransButton i18nKey="download" />}
        />
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            history.replace('/tickets');
            updateTab({
              params: {},
              bookingId: undefined,
              name: '',
            });
            form.restart({
              passengerSpecifications: [
                {
                  type: 'PERSON',
                  externalReference: v4(),
                },
              ],
              departureTime: formatDate(new Date()),
              promotionCodes: [],
              corporateCodes: [],
            });
          }}
          label={<TransButton i18nKey="backToSearch" />}
        />
      </Stack>
    </Card>
  );
};
