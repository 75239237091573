import { createAsyncThunk } from 'store/utils';
import { api } from 'features/api';
import {
  TripSearchParams,
  Journey,
  TripLeg,
  Offer,
  JourneyLink,
  PassengerOfferSelection,
} from 'dto/trip';
import { createAction } from '@reduxjs/toolkit';
import { stringify } from 'qs';

export const showTripStops = createAction<TripLeg | undefined>(
  'trip/showStops'
);

export const updateOfferSelection = createAction<{
  offerId: string;
  selections: Array<PassengerOfferSelection>;
}>('trip/updateOfferSelection');

export const resetTripOffers = createAction<string>('trip/resetTripOffers');
export const setPdfDownload = createAction<boolean>('trip/setPdfDownload');

export const selectTripOffer = createAction<{
  reference: string;
  isOutbound: boolean;
  offers: Array<Offer>;
}>('trip/selectTripOffer');

export const removeOfferSelection = createAction<string>(
  'trip/removeOfferSelection'
);

export const searchTrips = createAsyncThunk<
  { journeys: Array<Journey>; links: Array<JourneyLink> },
  TripSearchParams
>('trip/search', async (payload) => {
  return (await api.post('/offers/search', payload)).data;
});

export const showTripsResultPage = createAsyncThunk<
  { journeys: Array<Journey>; links: Array<JourneyLink> },
  'next' | 'previous'
>('trip/showTripsResultPage', async (page, { getState }) => {
  const {
    trip: { links },
  } = getState();
  const resultsLink = links.find(({ rel }) => rel === page);
  return resultsLink ? (await api.get(resultsLink.href)).data : [];
});

export interface PassengerDetailsPayload {
  bookingId: string;
  passengerId: string;
  externalReference: string;
  firstName: string;
  lastName: string;
  gender?: string;
  birthDate?: string;
  email?: string;
  phone?: {
    areaCode?: string;
    number: string;
  };
}

export interface PurchaserDetailsPayload extends PassengerDetailsPayload {
  address: {
    zipCode: string;
    streetName: string;
    city: string;
    countryName: string;
  };
  company: {
    name: string;
    registrationNumber: string;
    taxId: string;
  };
}

export const updatePassengerDetails = createAsyncThunk<
  void,
  PassengerDetailsPayload
>(
  'trip/updatePassengerDetails',
  async ({ bookingId, passengerId, ...payload }) => {
    return await api.patch(
      `/bookings/${bookingId}/passengers/${passengerId}`,
      payload
    );
  }
);

export const updatePurchaserDetails = createAsyncThunk<
  void,
  Omit<PassengerDetailsPayload, 'passengerId'> & {
    address: {
      streetName: string;
      zipCode: string;
      city: string;
      countryCode: string;
    };
    company: {
      name: string;
      registrationNumber: string;
    };
  }
>('trip/updatePurchaserDetails', async ({ bookingId, ...payload }) => {
  return await api.patch(`/bookings/${bookingId}/purchaser`, payload);
});

export const searchStops = createAsyncThunk<
  { stops: Array<{ reference: string; name: string }> },
  string
>('trip/searchStops', async (search: string) => {
  return (
    await api.get(
      `/places${stringify(
        { prefix: search, numberOfResults: 8 },
        { addQueryPrefix: true }
      )}`
    )
  ).data;
});
