import { Offer } from 'dto/trip';
import { Card, Grid, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import find from 'lodash/find';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Icon, Tooltip } from '@fleet/shared/mui';
import _capitalize from 'lodash/capitalize';
import _startCase from 'lodash/startCase';
import { FC, useMemo } from 'react';
import { useSelector } from 'store/utils';
import { selectSelectedOffers } from 'features/trip/tripSelector';
import { makeStyles } from '@mui/styles';

interface OfferCardProps extends Offer {
  onClick: () => void;
}

const useStyles = makeStyles(
  (theme) => ({
    offerCard: {
      border: `thin solid ${theme.palette.divider}`,
      padding: '8px 12px',
      cursor: 'pointer',
      '&:hover:not($offerCardSelected)': {
        background: theme.palette.primary.light,
        color: theme.palette.action.hover,
        '& $flexibility svg': {
          color: theme.palette.primary.main,
        },
      },
    },
    offerCardSelected: {
      background: theme.palette.secondary.main,
      '&, svg': {
        color: 'white!important',
      },
    },
  }),
  { name: 'JourneyOffer' }
);

export const OfferCard: FC<OfferCardProps> = ({ onClick, ...offer }) => {
  const {
    admissions,
    exchangeable,
    refundable,
    travelClass,
    serviceClass,
    flexibility,
  } = offer;
  const classes = useStyles();
  const selectedOffersInfo = useSelector(selectSelectedOffers);
  const isExchangeable = exchangeable !== 'NO';
  const isRefundable = refundable !== 'NO';
  const displayName = useMemo(
    () =>
      [travelClass, serviceClass.name || serviceClass.code]
        .map(_capitalize)
        .join(' / '),
    [serviceClass.code, serviceClass.name, travelClass]
  );
  const productDescriptiveTexts = useMemo(
    () =>
      admissions.reduce<Array<string>>(
        (texts, { productDescriptiveTexts }) => [
          ...texts,
          ...productDescriptiveTexts.map(({ description }) => description),
        ],
        []
      ),
    [admissions]
  );
  return (
    <Grid item xs={3} xl={2}>
      <Card
        onClick={onClick}
        className={classNames(classes.offerCard, {
          [classes.offerCardSelected]: find(selectedOffersInfo.list, offer),
        })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={0.5}
          mb={0.5}
        >
          {!!productDescriptiveTexts.length && (
            <Tooltip
              content={
                <Typography whiteSpace="break-spaces">
                  {productDescriptiveTexts.join('\n')}
                </Typography>
              }
            >
              <Icon name="info-circle" color="warning" />
            </Tooltip>
          )}
          <Typography variant="body1" fontWeight="bold" flex={1}>
            {displayName}
          </Typography>
          {offer.availablePlaces && (
            <Typography variant="body2">
              <TransSubtitle
                i18nKey="availableNr"
                values={{ count: offer.availablePlaces }}
              />
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1" noWrap>
            {`${offer.price.amount} ${offer.price.currency}`}
          </Typography>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Icon
                name={isExchangeable ? 'check-circle' : 'deactivate'}
                size={isExchangeable ? 16 : 14}
                color={isExchangeable ? 'success' : 'error'}
              />
              <Typography variant="body2">
                <TransSubtitle
                  i18nKey={isExchangeable ? 'changeable' : 'nonChangeable'}
                />
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Icon
                name={isRefundable ? 'check-circle' : 'deactivate'}
                size={isRefundable ? 16 : 14}
                color={isRefundable ? 'success' : 'error'}
              />
              <Typography variant="body2">
                <TransSubtitle
                  i18nKey={isRefundable ? 'refundable' : 'nonRefundable'}
                />
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <Icon name="ticket-change" />
            <Typography variant="body2">
              {_capitalize(_startCase(flexibility))}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};
