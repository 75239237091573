import { createReducer } from '@reduxjs/toolkit';
import {
  getPlaces,
  getCarriers,
  getPtModes,
  getCards,
  getCommentTypes,
} from 'features/classification/classificationActions';
import { Card, Classification } from 'dto/classification';

const initialState: {
  STOP: Array<Classification>;
  PT_MODE: Array<Classification>;
  CARRIER: Array<Classification>;
  CARD: Array<Card>;
  COMMENT_TYPE: Array<Classification>;
} = {
  STOP: [],
  PT_MODE: [],
  CARRIER: [],
  CARD: [],
  COMMENT_TYPE: [],
};
export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCards.fulfilled, (state, action) => {
      state.CARD = action.payload;
    })
    .addCase(getCarriers.fulfilled, (state, action) => {
      state.CARRIER = action.payload;
    })
    .addCase(getPtModes.fulfilled, (state, action) => {
      state.PT_MODE = action.payload;
    })
    .addCase(getPlaces.fulfilled, (state, action) => {
      state.STOP = action.payload;
    })
    .addCase(getCommentTypes.fulfilled, (state, action) => {
      state.COMMENT_TYPE = action.payload;
    });
});
