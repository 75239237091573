import { Modal, useForm, FormProvider, SelectField } from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { useDispatch, useSelector } from 'store/utils';
import { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import {
  getPosConfiguration,
  selectRetailer,
  setShowRetalerModal,
  UserOrganization,
  UserUnit,
} from 'features/user/userActions';
import { TransField } from 'i18n/trans/field';
import { makeStyles } from '@mui/styles';
import { selectUser, selectUserUnits } from 'features/user/userSelector';
import {
  RETAILER_SELECTION_LS_KEY,
  useLocalStorage,
} from 'hooks/useLocalStorage';

interface RetailerForm {
  organizationId: string;
  salesUnitCode: string;
  pointOfSaleId: string;
}

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiBackdrop-root': {
        background: 'rgba(0, 0, 0, 0.5)',
      },
    },
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 400,
    },
  }),
  { name: 'RetailerModal' }
);

const FORM_ID = 'retailer-selection';
export const RetailerModal = () => {
  const { initialValue: lsUserUnits, setData } =
    useLocalStorage<RetailerForm | null>({
      key: RETAILER_SELECTION_LS_KEY,
      getDefaultValue: (v) => v,
      isSessionStorage: true,
    });
  const classes = useStyles();
  const { open, onClose } = useModal({ open: true });
  const user = useSelector(selectUser)!;
  const isDsInvolved = process.env.REACT_APP_IS_DS_INVOLVED === 'true';
  const userUnits = useSelector(selectUserUnits);
  const dispatch = useDispatch();
  const prepareOptions = useCallback(
    (units?: Array<UserOrganization | UserUnit>) =>
      units?.map(({ name, id }) => ({ label: name, value: id })) ?? [],
    []
  );
  const onModalClose = useCallback(() => {
    dispatch(setShowRetalerModal(false));
    onClose();
  }, [dispatch, onClose]);
  const handleRetailerSelect = useCallback(
    (payload: RetailerForm) => {
      const { pointOfSaleId } = payload;
      dispatch(selectRetailer(payload));
      dispatch(getPosConfiguration(pointOfSaleId));
      setData(payload);
      onModalClose();
    },
    [dispatch, onModalClose, setData]
  );
  const { form, handleSubmit, values, invalid } = useForm<RetailerForm>({
    onSubmit: handleRetailerSelect,
    initialValues: {
      organizationId:
        user.primaryOrganization?.id ??
        user.organizations[0].id ??
        lsUserUnits?.organizationId,
        salesUnitCode: user.primaryUnit?.code ?? lsUserUnits?.salesUnitCode,
      pointOfSaleId: user.primaryPointOfSale?.id ?? lsUserUnits?.pointOfSaleId,
    },
    subscription: { values: true, invalid: true },
  });
  const modalCloseAllowed = useMemo(
    () =>
      isDsInvolved
        ? userUnits.organizationId && userUnits.salesUnitCode
        : userUnits.organizationId,
    [isDsInvolved, userUnits]
  );

  useEffect(() => {
    if (!lsUserUnits?.organizationId || !!userUnits.organizationId) return;
    handleRetailerSelect(lsUserUnits);
  }, [dispatch, handleRetailerSelect, lsUserUnits, userUnits.organizationId]);

  const currentOrganization = useMemo(
    () => user?.organizations.find(({ id }) => id === values.organizationId),
    [user?.organizations, values.organizationId]
  );
  const unitOptions =
    currentOrganization?.units.map(({ name, code }) => ({
      label: name,
      value: code,
    })) ?? [];
  const pointOfSaleOptions = prepareOptions(currentOrganization?.pointsOfSale);
  const organizationOptions = prepareOptions(user?.organizations);

  const onCloseHandler = useCallback(() => {
    if (invalid) return form.submit();
    modalCloseAllowed && onModalClose();
  }, [form, invalid, modalCloseAllowed, onModalClose]);

  return (
    <Modal
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="chooseRetailer" />}
      open={open}
      onClose={onCloseHandler}
      showCloseControl={false}
      actionButton={
        <Button form={FORM_ID} type="submit" variant="contained">
          <TransButton i18nKey="confirm" />
        </Button>
      }
    >
      <FormProvider {...form}>
        <Stack
          component="form"
          id={FORM_ID}
          onSubmit={handleSubmit}
          spacing={2}
        >
          <SelectField
            name="organizationId"
            label={<TransField i18nKey="selectRetailer" />}
            options={organizationOptions}
            required
          />
          <SelectField
            name="salesUnitCode"
            label={<TransField i18nKey="selectUnit" />}
            disabled={!values.organizationId}
            options={unitOptions}
            required={isDsInvolved}
          />
          <SelectField
            name="pointOfSaleId"
            label={<TransField i18nKey="selectPointOfSale" />}
            disabled={!values.organizationId}
            options={pointOfSaleOptions}
          />
        </Stack>
      </FormProvider>
    </Modal>
  );
};
