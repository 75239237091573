import { TabPanel, Tabs } from '@fleet/shared';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BookingAdmissionAncillary,
  BookingDetailsPassenger,
} from 'dto/booking';
import { TripLeg } from 'dto/trip';
import { updatePassengerAdmissionSelection } from 'features/booking/bookingActions';
import {
  currentBookingSelector,
  selectAdmissionSelection,
} from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import {
  PassengerAdmissionsColumns,
  PassengerAdmissionsTable,
} from 'routes/bookingDetails/PassengerAdmissionsTable';
import { PassengerAddons } from 'routes/bookingDetails/passengerSubRow/PassengerAddons';
import { PassengerLegs } from 'routes/bookingDetails/passengerSubRow/PassengerLegs';
import { useDispatch, useSelector } from 'store/utils';
import { getBookingAdmissions } from 'utils/trip';
// import { PassengerFees } from 'routes/bookingDetails/passengerSubRow/PassengerFees';
import { PassengerData } from './PassengerData';

interface PassengerTabsProps {
  row: BookingDetailsPassenger;
  isOverview?: boolean;
}

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      background: theme.palette.background.default,
      padding: '8px 16px',
    },
  }),
  {
    name: 'PassengerTabs',
  }
);

export const PassengerSubRow: FC<PassengerTabsProps> = ({
  row: { id: passengerId },
  isOverview,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedAdmissions = useSelector(selectAdmissionSelection);
  const booking = useSelector(currentBookingSelector)!;
  const { bookedTrips, passengers } = booking;
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );

  const currentPassenger = useMemo(
    () => passengers.find(({ id }) => id === passengerId)!,
    [passengers, passengerId]
  );
  const bookingLegs = useMemo(
    () => bookedTrips.map(({ legs }) => legs).flat(),
    [bookedTrips]
  );
  const passengerAdmissions = useMemo(
    () =>
      bookingAdmissions.filter(({ passengerIds }) =>
        passengerIds.includes(passengerId)
      ),
    [bookingAdmissions, passengerId]
  );
  const passengerLegs = useMemo(
    () =>
      passengerAdmissions.reduce<Array<TripLeg>>(
        (legs, { coveredLegIds, travelClass }) => {
          return [
            ...legs,
            ...coveredLegIds
              .map((legId) => ({
                ...bookingLegs.find(({ id }) => id === legId)!,
                travelClass,
              }))
              .sort(
                (a, b) =>
                  new Date(a.departureTime).getTime() -
                  new Date(b.departureTime).getTime()
              ),
          ];
        },
        []
      ),
    [bookingLegs, passengerAdmissions]
  );
  const passengerAncillaries = useMemo(
    () =>
      passengerAdmissions.reduce<Array<BookingAdmissionAncillary>>(
        (ancillaries, admission) => [...ancillaries, ...admission.ancillaries],
        []
      ),
    [passengerAdmissions]
  );

  // const passengerFees = useMemo(
  //   () =>
  //     passengerAdmissions.reduce<Array<PassengerFee>>(
  //       (fees, admission) => [...fees, ...admission.fees],
  //       []
  //     ),
  //   [passengerAdmissions]
  // );

  const onAdmissionSelectionUpdate = useCallback(
    (selection: Array<string>) => {
      dispatch(
        updatePassengerAdmissionSelection({
          passengerId,
          selection,
        })
      );
    },
    [dispatch, passengerId]
  );

  const tabs = useMemo(
    () => [
      {
        tab: <TransSubtitle i18nKey="admissions" />,
        component: (
          <PassengerAdmissionsTable
            onRowSelectionUpdate={onAdmissionSelectionUpdate}
            hiddenColumns={[
              PassengerAdmissionsColumns.refundableAmount,
              ...(isOverview
                ? [
                    PassengerAdmissionsColumns.selection,
                    PassengerAdmissionsColumns.status,
                    PassengerAdmissionsColumns.ticketNumber,
                    PassengerAdmissionsColumns.changeable,
                    PassengerAdmissionsColumns.refundable,
                  ]
                : [
                    PassengerAdmissionsColumns.productName,
                    PassengerAdmissionsColumns.passengerType,
                    PassengerAdmissionsColumns.passengerCards,
                    PassengerAdmissionsColumns.flexibility,
                  ]),
            ]}
            selected={selectedAdmissions[passengerId]}
            data={passengerAdmissions}
          />
        ),
      },
      {
        tab: <TransSubtitle i18nKey="legInfo" />,
        component: <PassengerLegs data={passengerLegs} />,
      },
      {
        tab: <TransSubtitle i18nKey="purchasedAddons" />,
        component: <PassengerAddons data={passengerAncillaries} />,
      },
      // {
      //   tab: <TransSubtitle i18nKey="fees" />,
      //   component: <PassengerFees data={passengerFees} />,
      // },
      {
        tab: <TransSubtitle i18nKey="passengerData" />,
        component: <PassengerData value={currentPassenger} />,
      },
    ],
    [
      isOverview,
      onAdmissionSelectionUpdate,
      selectedAdmissions,
      passengerId,
      passengerAdmissions,
      passengerLegs,
      passengerAncillaries,
      currentPassenger,
    ]
  );
  return (
    <Box className={classes.wrapper}>
      <Tabs>
        {tabs.map(({ tab, component }, idx) => (
          <TabPanel value={String(idx)} key={idx} label={tab}>
            {component}
          </TabPanel>
        ))}
      </Tabs>
    </Box>
  );
};
