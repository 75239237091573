import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { PassengersSelection } from 'routes/bookingDetails/modal/PassengersSelection';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBookingSelector,
  selectReleaseOffers,
} from 'features/booking/bookingSelectors';
import { BookingAdmission } from 'dto/booking';
import {
  confirmRelease,
  getBooking,
  initiateRefund,
  initiateRelease,
} from 'features/booking/bookingActions';
import _uniq from 'lodash/uniq';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';

interface CancelModalProps {
  selectedAdmissions: Array<BookingAdmission>;
  selectedPassengerIds: Array<string>;
  onClose: () => void;
}

export const CancelModal: FC<CancelModalProps> = ({
  selectedAdmissions,
  selectedPassengerIds,
  onClose,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(currentBookingLoadingSelector);
  const { id } = useSelector(currentBookingSelector)!;
  const releaseOffers = useSelector(selectReleaseOffers);
  const selectedFulfillmentIds = useMemo(
    () =>
      _uniq(
        selectedAdmissions
          .map(({ fulfillments }) => fulfillments.map(({ id }) => id))
          .flat()
      ),
    [selectedAdmissions]
  );
  const submitDisabled = useMemo(
    () =>
      selectedAdmissions.some(({ fulfillments }: BookingAdmission) =>
        fulfillments.some(({ status }) =>
          ['REFUNDED', 'RELEASED'].includes(status)
        )
      ),
    [selectedAdmissions]
  );

  const initRelease = useCallback(async () => {
    try {
      await Promise.all([
        dispatch(
          initiateRefund({
            bookingId: id,
            fulfillmentIds: selectedFulfillmentIds,
          })
        ).unwrap(),
        dispatch(
          initiateRelease({
            bookingId: id,
            fulfillmentIds: selectedFulfillmentIds,
          })
        ).unwrap(),
      ]);
    } catch (e) {
      onClose();
    }
  }, [dispatch, id, selectedFulfillmentIds, onClose]);

  const onReleaseSubmit = useCallback(async () => {
    onClose();
    await dispatch(
      confirmRelease({
        bookingId: id,
        releaseOfferIds: releaseOffers!.map(({ id }) => id),
      })
    );
    await dispatch(getBooking(id));
  }, [dispatch, id, onClose, releaseOffers]);

  useEffect(() => {
    !submitDisabled && initRelease();
  }, [submitDisabled, initRelease]);

  return (
    <ModalWrap
      open={!loading}
      onClose={() => onClose()}
      title={<TransTitle i18nKey="cancelSelected" />}
      maxWidth="xl"
    >
      <PassengersSelection
        type="cancel"
        selected={selectedPassengerIds}
        submitLabel={<TransButton i18nKey="cancelSelected" />}
        onSubmit={onReleaseSubmit}
        submitDisabled={submitDisabled}
        onClose={onClose}
      />
    </ModalWrap>
  );
};
