import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { TableInstance } from 'react-table';
import { move } from 'utils/array';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { useModal } from '@fleet/shared/hooks';
import { makeStyles } from '@mui/styles';
import { Icon, Modal } from '@fleet/shared/mui';
import classNames from 'classnames';
import _partition from 'lodash/partition';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .MuiPaper-root': {
        width: '588px',
      },
    },
    colItem: {
      padding: '4px 12px',
    },
    visible: {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
      background: theme.palette.common.white,
    },
    hidden: {
      background: theme.palette.background.default,
    },
  }),
  {
    name: 'TableColumnSettings',
  }
);

export const TableColumnSettings = <D extends object>({
  table,
}: {
  table: TableInstance<D>;
}) => {
  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const initialCols: Record<string, Array<string>> = useMemo(
    () => ({
      visible: table.columns.map(({ id }) => id),
      hidden: [],
    }),
    [table.columns]
  );
  const { setHiddenColumns, setColumnOrder } = table;
  const [columns, setColumns] = useState(initialCols);
  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) return;

      const sourceCols = columns[source.droppableId];
      const destinationCols = columns[destination.droppableId];

      if (source.droppableId === destination.droppableId) {
        setColumns({
          ...columns,
          [source.droppableId]: move(
            sourceCols,
            source.index,
            destination.index
          ),
        });
      } else {
        setColumns({
          [source.droppableId]: sourceCols.filter(
            (_, idx) => idx !== source.index
          ),
          [destination.droppableId]: [
            ...destinationCols.slice(0, destination.index),
            sourceCols[source.index],
            ...destinationCols.slice(destination.index),
          ],
        });
      }
    },
    [columns]
  );

  const onSave = useCallback(() => {
    setColumnOrder(columns.visible);
    setHiddenColumns(columns.hidden);
    onClose();
  }, [onClose, setColumnOrder, setHiddenColumns, columns]);

  const onModalClose = useCallback(
    (_, reason) => {
      if (reason !== 'action') {
        const [visibleCols, hiddenCols] = _partition(
          table.columns,
          ({ isVisible }) => isVisible
        );

        setColumns({
          visible: visibleCols.map(({ id }) => id),
          hidden: hiddenCols.map(({ id }) => id),
        });
      }
      onClose();
    },
    [onClose, table]
  );

  return (
    <>
      <Button
        onClick={onOpen}
        sx={{ px: 1, py: 0, fontSize: '12px', marginLeft: 'auto' }}
        startIcon={<Icon name="settings" />}
      >
        <TransButton i18nKey="searchResultsSettings" />
      </Button>
      <Modal
        title="Search result settings"
        className={classes.root}
        actionButton={
          <Button onClick={onSave} variant="contained">
            <TransButton i18nKey="save" />
          </Button>
        }
        open={open}
        onClose={onModalClose}
        maxWidth={false}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Stack direction="row" spacing={2}>
            {(['visible', 'hidden'] as const).map((id) => (
              <Droppable droppableId={id} key={id}>
                {(provided) => (
                  <Stack flex={1}>
                    <Typography variant="subtitle" mb={3}>
                      <Icon
                        name={`visibility-${id === 'visible' ? 'on' : 'off'}`}
                        size={24}
                        sx={{ marginRight: 1 }}
                      />
                      <TransSubtitle i18nKey={`${id}Columns`} />
                    </Typography>
                    <Stack
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      flex={1}
                      spacing={0.5}
                    >
                      {columns[id].map((colId, index) => (
                        <Draggable
                          key={colId}
                          draggableId={colId}
                          index={index}
                        >
                          {(provided) => (
                            <Stack
                              direction="row"
                              alignItems="center"
                              className={classNames(
                                classes.colItem,
                                classes[id]
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Icon
                                name="draggable"
                                size={24}
                                sx={{ marginRight: 1 }}
                              />
                              {/* @ts-ignore */}
                              <TransTableHead i18nKey={colId} />
                            </Stack>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Stack>
                  </Stack>
                )}
              </Droppable>
            ))}
          </Stack>
        </DragDropContext>
      </Modal>
    </>
  );
};
