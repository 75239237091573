import { Icon } from '@fleet/shared/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { LegWrapper } from 'components/LegWrapper';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { makeStyles } from '@mui/styles';
import { PassengersAndTickets } from 'routes/bookingDetails/tabs/PassengersAndTickets';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { FC } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:before': {
        display: 'none',
      },
      '&&': {
        margin: 0,
      },
      '&:not($collapsible)': {
        borderRadius: '0.25rem',
        boxShadow: 'none',
        pointerEvents: 'none',
      },
      '&&$collapsible': {
        marginTop: '1rem',
      },
    },
    details: {
      padding: '1rem 0.5rem',
      background: theme.palette.background.default,
      boxShadow:
        'inset 0 8px 8px -5px rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.1)',
    },
    header: {
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-content': {
        margin: 0,
        display: 'block',
      },
    },
    summaryInfo: {
      borderRadius: '4px',
      padding: '9px 24px',
    },
    borders: {
      border: `thin solid ${theme.palette.divider}`,
      borderRadius: '0.1875rem',
    },
    collapsible: {},
  }),
  { name: 'JourneyOverview' }
);

interface JourneyOverviewProps {
  collapsible?: boolean;
}
export const JourneyOverview: FC<JourneyOverviewProps> = ({ collapsible }) => {
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);

  if (!booking) return null;

  const { bookedTrips, passengers, provisionalPrice } = booking;
  const journeyOrigin = bookedTrips[0].originStop.name;
  const journeyOriginTime = bookedTrips[0].departureTime;
  const journeyDestination =
    bookedTrips[bookedTrips.length - 1].destinationStop.name;
  const journeyDestinationTime =
    bookedTrips[bookedTrips.length - 1].arrivalTime;
  const showResplusIcon = bookedTrips[0].alliances.includes('RESPLUS');

  return (
    <Accordion
      square={collapsible}
      classes={{
        root: classNames(classes.root, {
          [classes.collapsible]: collapsible,
        }),
      }}
      elevation={2}
    >
      <AccordionSummary
        classes={{
          root: classNames(classes.header, {
            [classes.borders]: !collapsible,
          }),
        }}
        expandIcon={
          collapsible && (
            <Icon name="chevron-down" size={28} margin color="secondary" />
          )
        }
      >
        <Stack
          className={classes.summaryInfo}
          direction="row"
          spacing={6}
          alignItems="center"
          justifyContent="space-between"
        >
          {showResplusIcon && (
            <Icon
              name="resplus"
              height={44}
              width={12}
              sx={{
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            />
          )}
          <Typography variant="body1" fontWeight="bold">
            <TransSubtitle
              i18nKey="passengersQty"
              count={passengers.length}
              tOptions={{ postProcess: 'interval' }}
            />
          </Typography>
          <LegWrapper small>
            {[journeyOrigin, journeyDestination].map((title, idx) => (
              <OverflowEllipsis
                key={idx}
                content={title}
                variant="body1"
                fontWeight="bold"
                sx={{ flex: 1, marginBottom: idx ? 0 : 0.5 }}
              />
            ))}
          </LegWrapper>
          <Box>
            {[journeyOriginTime, journeyDestinationTime].map((time, idx) => (
              <Typography
                key={idx}
                variant="body1"
                sx={{ marginBottom: idx ? 0 : 0.5 }}
              >
                {formatDate(time, `EEE, ${currentDateTimeFormat}`)}
              </Typography>
            ))}
          </Box>
          <Typography variant="h2" sx={{ flex: 1, textAlign: 'right' }}>
            {`${provisionalPrice.amount} ${provisionalPrice.currency}`}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <PassengersAndTickets isOverview />
      </AccordionDetails>
    </Accordion>
  );
};
