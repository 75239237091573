import { alpha } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import { Tooltip } from '@fleet/shared/mui';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { FC, ReactNode, useMemo } from 'react';
import { TransportType } from 'dto/trip';

interface TransportBadgeProps {
  ptMode: {
    code: TransportType;
    name: string;
  };
  label?: ReactNode;
  rounded?: boolean;
}

const useStyles = makeStyles(
  () => ({
    root: {
      whiteSpace: 'nowrap',
      width: 'fit-content',
      padding: '0.25rem 0.5rem',
    },
    rounded: {
      borderRadius: '0.1875rem',
    },
  }),
  { name: 'TransportBadge' }
);

export const TransportBadge: FC<TransportBadgeProps> = ({
  ptMode,
  label,
  rounded = true,
}) => {
  const classes = useStyles();
  const badgeBgBaseColor = useMemo(() => {
    switch (ptMode.code) {
      case TransportType.HIGH_SPEED_TRAIN:
      case TransportType.INTERCITY_REGIONAL:
      case TransportType.LOCAL_TRAIN:
      case TransportType.TRAIN:
        return '#4B00FF';
      case TransportType.BUS:
      case TransportType.LOCAL_BUS:
      case TransportType.LONG_DISTANCE:
        return '#FF6B00';
      case TransportType.AIR_TRAFFIC:
        return '#0019FD';
      case TransportType.SUBWAY:
      case TransportType.UNDERGROUND:
      case TransportType.TRAM:
      case TransportType.TRAMS:
        return '#FF0000';
      case TransportType.FERRY:
      case TransportType.SHIP:
        return '#00F0FF';
      case TransportType.TAXI:
      case TransportType.SHARED_TAXI:
        return '#FAFF00';
      default: {
        return '#4B00FF';
      }
    }
  }, [ptMode.code]);
  const badgeIcon = useMemo(() => {
    switch (ptMode.code) {
      case TransportType.HIGH_SPEED_TRAIN:
      case TransportType.INTERCITY_REGIONAL:
      case TransportType.LOCAL_TRAIN:
      case TransportType.TRAIN:
        return 'train';
      case TransportType.LONG_DISTANCE:
      case TransportType.LOCAL_BUS:
      case TransportType.BUS:
        return 'bus';
      case TransportType.TRAM:
      case TransportType.TRAMS:
      case TransportType.SUBWAY:
      case TransportType.UNDERGROUND:
        return 'metro';
      case TransportType.FERRY:
      case TransportType.SHIP:
        return 'ferry';
      case TransportType.TAXI:
      case TransportType.SHARED_TAXI:
        return 'taxi';
      case TransportType.AIR_TRAFFIC:
        return 'airport';
      default: {
        return 'train';
      }
    }
  }, [ptMode.code]);
  const preparedLabel = useMemo(
    () => [label, ptMode.name].filter(Boolean).join(' - '),
    [label, ptMode.name]
  );

  return (
    <Tooltip content={preparedLabel}>
      <Stack
        direction="row"
        alignItems="center"
        className={classNames(classes.root, { [classes.rounded]: rounded })}
        sx={{
          boxShadow: [alpha(badgeBgBaseColor, 0.1), 'white']
            .map((color) => `inset 0 0 0 2rem ${color}`)
            .join(','),
        }}
        spacing={0.5}
      >
        {badgeIcon && <Icon size={12} name={badgeIcon} />}
        <Typography
          variant="body2"
          fontWeight={700}
          noWrap
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {preparedLabel}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
