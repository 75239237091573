import { makeStyles } from '@mui/styles';
import { LegWrapper } from 'components/LegWrapper';
import { Icon } from '@fleet/shared/mui';
import { Typography, Divider, Stack, Button } from '@mui/material';
import { Journey, Offer, TripLeg } from 'dto/trip';
import { FC, Fragment, useMemo } from 'react';
import { getLegTransferTime, getLegDuration, getTimeString } from 'utils/trip';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { showTripStops } from 'features/trip/tripActions';
import _isEqual from 'lodash/isEqual';
import { LegInfo } from './LegInfo';
import { formatDate } from '@fleet/shared/utils/date';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { BookingAdmission, BookingDetailsPassenger } from 'dto/booking';
import { LegOffers } from 'components/LegOffers';
import classNames from 'classnames';
import { AlertCard } from 'components/AlertCard';
import { TransAlert } from 'i18n/trans/alert';

const useStyles = makeStyles(
  (theme) => ({
    serviceText: {
      maxWidth: 'calc(60% - 0.25rem)',
    },
    routeWithOffers: {
      flexShrink: 0,
      width: '25%',
    },
    transferDivider: {
      lineHeight: 1,
      margin: '32px 0',
      '& .MuiDivider-wrapper': {
        padding: '0 16px',
      },
      '&:after, &:before': {
        borderTop: `thin solid ${theme.palette.warning.main}`,
      },
    },
  }),
  { name: 'JourneyInfo' }
);

interface JourneyInfoProps {
  reference?: string;
  trips: Journey['trips'];
  showOffers?: boolean;
  isOutbound?: boolean;
}

export const JourneyInfo: FC<JourneyInfoProps> = ({
  reference,
  trips,
  showOffers,
  isOutbound = false,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const passengersByLeg = useMemo<
    Record<string, Array<BookingDetailsPassenger>>
  >(() => {
    if (!booking) return {};
    const { bookedTrips, passengers } = booking;
    const admissions = bookedTrips.reduce<Array<BookingAdmission>>(
      (admissions, trip) => [...admissions, ...trip.bookedOffer.admissions],
      []
    );
    return admissions.reduce<Record<string, Array<BookingDetailsPassenger>>>(
      (acc, { coveredLegIds, passengerIds }) => {
        const currentPassengers = passengers.filter(({ id }) =>
          passengerIds.includes(id)
        );
        coveredLegIds.forEach((legId) => {
          acc[legId] = currentPassengers;
        });
        return acc;
      },
      {}
    );
  }, [booking]);

  const legsWithOffersAndPassengers = useMemo(
    () =>
      trips.reduce<
        Array<
          TripLeg & {
            offers: Array<Offer>;
            passengers?: Array<BookingDetailsPassenger>;
          }
        >
      >((acc, trip) => {
        const { legs, offers } = trip;
        return [
          ...acc,
          ...legs.map((leg) => {
            return {
              ...leg,
              passengers: passengersByLeg[leg.id],
              offers: offers?.filter(({ coveredLegIds }) =>
                coveredLegIds.includes(leg.id)
              ),
            };
          }),
        ];
      }, []),
    [passengersByLeg, trips]
  );

  return (
    <>
      {legsWithOffersAndPassengers.map(({ offers, ...leg }, idx) => (
        <Fragment key={idx}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <LegWrapper
              className={classNames({
                [classes.routeWithOffers]: showOffers,
              })}
            >
              <Stack spacing={3}>
                <Typography variant="body1">
                  <b>{getTimeString(leg.departureTime)}</b>
                  <Typography variant="body2" component="span" ml={0.5} mr={1}>
                    {`(${formatDate(leg.departureTime)})`}
                  </Typography>
                  <b> {leg.originStop.name}</b>
                </Typography>
                <Stack spacing={1}>
                  <LegInfo key={idx} isBgWhite={showOffers} {...leg} />
                  <Stack direction="row" alignItems="center">
                    <Icon margin width={10} name="clock" />
                    <Typography variant="body2">
                      <TransSubtitle
                        i18nKey="travelTime"
                        values={{ time: getLegDuration(leg) }}
                      />
                    </Typography>
                    <Button
                      onClick={() => dispatch(showTripStops(leg))}
                      variant="text"
                      sx={{
                        p: 0,
                        pl: 1,
                        minWidth: 0,
                        fontSize: '12px',
                        textDecoration: 'underline',
                      }}
                    >
                      <TransButton i18nKey="viewStops" />
                    </Button>
                  </Stack>
                </Stack>
                <Typography variant="body1">
                  <b>{getTimeString(leg.arrivalTime)}</b>
                  <Typography variant="body2" component="span" ml={0.5} mr={1}>
                    {`(${formatDate(leg.arrivalTime)})`}
                  </Typography>
                  <b> {leg.destinationStop.name}</b>
                </Typography>
              </Stack>
            </LegWrapper>
            {showOffers && (
              <Stack spacing={0.5} flex={1}>
                <Stack alignItems="flex-end" spacing={0.5}>
                  {leg.serviceTexts?.map((text, idx) => (
                    <AlertCard
                      key={idx}
                      message={text}
                      className={classes.serviceText}
                    />
                  ))}
                  {leg.onDemandTexts?.map((text) => (
                    <AlertCard
                      title={<TransAlert i18nKey="advanceOrderRequired" />}
                      message={text}
                      className={classes.serviceText}
                    />
                  ))}
                </Stack>
                <LegOffers
                  isShown={
                    !_isEqual(
                      offers,
                      legsWithOffersAndPassengers[idx - 1]?.offers
                    )
                  }
                  reference={reference}
                  isOutbound={isOutbound}
                  leg={leg}
                  offers={offers}
                />
              </Stack>
            )}
          </Stack>
          {idx !== legsWithOffersAndPassengers.length - 1 && (
            <Divider
              className={classNames(classes.transferDivider, {
                [classes.routeWithOffers]: showOffers,
              })}
            >
              <Stack direction="row" spacing={1}>
                <Icon color="warning" name="transfer" />
                <Typography variant="body2">
                  <TransSubtitle
                    i18nKey="transferTime"
                    values={{
                      time: getLegTransferTime(
                        leg,
                        legsWithOffersAndPassengers[idx + 1]
                      ),
                    }}
                  />
                </Typography>
              </Stack>
            </Divider>
          )}
        </Fragment>
      ))}
    </>
  );
};
