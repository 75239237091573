import { createLoadingSelector, selector } from 'store/utils';
import { RootState } from 'store';
import { postBooking } from './bookingActions';
import { BookingAdmission } from 'dto/booking';
import { isAfter } from 'date-fns';

export const bookingsSearchResultsSelector = selector(
  (state) => state.booking.list
);

export const bookingLoadingSelector = createLoadingSelector(postBooking);

export const currentBookingSelector = selector(
  (state: RootState) => state.booking.current
);

export const bookingsFilterSelector = selector((state) => state.booking.filter);

export const selectAdmissionSelection = selector(
  (state) => state.booking.selectedAdmissions
);

export const selectRefundOffers = selector(
  (state) => state.booking.refundOffers
);

export const selectReleaseOffers = selector(
  (state) => state.booking.releaseOffers
);

export const bookingPrebookedSelector = selector((state) => {
  const currentBooking = state.booking.current;
  return (
    !currentBooking ||
    currentBooking.bookedTrips
      .reduce<Array<BookingAdmission>>(
        (admissions, trip) => [...admissions, ...trip.bookedOffer.admissions],
        []
      )
      .every(({ status }) => status === 'PREBOOKED')
  );
});

export const bookingExpiredSelector = selector((state) => {
  const isPrebooked = bookingPrebookedSelector(state);
  const currentBooking = state.booking.current;
  if (currentBooking) {
    return (
      isPrebooked &&
      currentBooking.bookedTrips
        .reduce<Array<BookingAdmission>>(
          (admissions, trip) => [...admissions, ...trip.bookedOffer.admissions],
          []
        )
        .some(({ confirmableUntil }) =>
          isAfter(new Date(), new Date(confirmableUntil))
        )
    );
  }
});

export const bookingCommentsSelector = selector(
  (state) => state.booking.comments
);
