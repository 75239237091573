import { Icon, Tooltip } from '@fleet/shared/mui';
import { makeStyles } from '@mui/styles';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useFormContext } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { CheckboxGroupField, SelectField } from '@fleet/shared/form';
import { TransportBadge } from 'components/TransportBadge';
import { useField } from '@fleet/shared/form/hooks/useField';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { selectPtModes } from 'features/classification/classificationSelectors';
import { TransportType, TripSearchParams } from 'dto/trip';

interface SearchFiltersProps {}

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '0 8px',
    width: 64,
    '&&': {
      flexBasis: 'auto',
      flexShrink: 0,
      boxSizing: 'border-box',
    },
  },
  card: {
    maxHeight: 'calc(100vh - 14rem)',
    overflowY: 'scroll',
  },
  filterControl: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > .MuiInputLabel-root': {
      marginBottom: 0,
      color: theme.palette.text.primary,

      '& ~ *:not(.MuiSwitch-root)': {
        width: '55%',
      },
    },
  },
  accordionRoot: {
    '&:before': {
      display: 'none',
    },
  },
  accordionDetails: {
    background: theme.palette.background.default,
  },
  transportBadge: {
    borderRadius: '2px',
  },
}));

export const SearchFilters: FC<SearchFiltersProps> = () => {
  const classes = useStyles();
  const [controlsShown, setControlsShown] = useState(false);
  const [meansOfTransportShown, setMeansOfTransportShown] = useState(false);
  const carrierOptions = useClassificationOptions(ClassificationGroup.CARRIER);
  const stopOptions = useClassificationOptions(ClassificationGroup.STOP);
  const ptModes = useSelector(selectPtModes);
  const form = useFormContext<Partial<TripSearchParams>>();
  const {
    input: { value: searchCriteria },
  } = useField<'searchCriteria', TripSearchParams['searchCriteria']>(
    'searchCriteria',
    form,
    {
      subscription: { value: true },
    }
  );
  const {
    input: { value: transferStopValue },
  } = useField<'via.viaStop.code', string>('via.viaStop.code', form, {
    subscription: { value: true },
  });
  const appliedFiltersNumber = useMemo(() => {
    if (searchCriteria) {
      const { ptModesFilter, ...rest } = searchCriteria;
      return (
        Object.keys(rest).length +
        (transferStopValue ? 1 : 0) +
        (ptModesFilter?.ptModes.length ? 1 : 0)
      );
    }
  }, [searchCriteria, transferStopValue]);
  const onClickOutside = useCallback(() => {
    if (controlsShown) {
      setControlsShown(false);
    }
  }, [controlsShown]);
  const transportOptions = useMemo(
    () =>
      ptModes.map(({ id, name }) => ({
        label: <TransportBadge ptMode={{ code: id as TransportType, name }} />,
        value: id,
      })),
    [ptModes]
  );
  const resetHandler = useCallback(() => {
    form.restart({
      ...form.getState().values,
      searchCriteria: undefined,
      via: undefined,
    });
  }, [form]);

  return (
    <Tooltip
      arrow={false}
      open={controlsShown}
      theme="light"
      placement="bottom-end"
      disableFocusListener
      offset={[0, 0]}
      PopperProps={{
        sx: {
          zIndex: 1000,
          '& > .MuiTooltip-tooltipPlacementBottom': {
            maxWidth: 'none',
            width: 373,
            padding: 0,
            marginTop: '8px',
          },
        },
      }}
      content={
        <ClickAwayListener onClickAway={onClickOutside}>
          <Card className={classes.card}>
            <CardContent sx={{ pb: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Typography variant="subtitle">
                  <TransSubtitle i18nKey="filters" />
                </Typography>
                <Button onClick={resetHandler} variant="text">
                  <TransButton i18nKey="clearAll" />
                </Button>
              </Stack>
              <Stack spacing={2}>
                <SelectField
                  className={classes.filterControl}
                  name="searchCriteria.transfersFilter.transferLimit"
                  label={<TransField i18nKey="transferLimit" />}
                  options={[1, 2, 3, 4, 5].map((num) => ({
                    label: `${num}`,
                    value: num,
                  }))}
                />
                <SelectField
                  className={classes.filterControl}
                  name="via.viaStop.code"
                  label={<TransField i18nKey="transferStation" />}
                  options={stopOptions}
                />
                <SelectField
                  className={classes.filterControl}
                  name="searchCriteria.transfersFilter.minTransferTime"
                  label={<TransField i18nKey="minTransferTime" />}
                  options={[15, 30, 45, 60].map((min) => ({
                    label: `${min}min`,
                    value: min,
                  }))}
                />
                <SelectField
                  className={classes.filterControl}
                  name="searchCriteria.carriersFilter.carriers"
                  label={<TransField i18nKey="carrier" />}
                  options={carrierOptions}
                  multiple
                />
              </Stack>
            </CardContent>
            <Accordion
              disableGutters
              elevation={1}
              expanded={meansOfTransportShown}
              onChange={() => setMeansOfTransportShown(!meansOfTransportShown)}
              classes={{
                root: classes.accordionRoot,
              }}
            >
              <AccordionSummary>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icon
                    name={`chevron-${meansOfTransportShown ? 'down' : 'up'}`}
                  />
                  <Typography variant="body2">
                    <TransSubtitle i18nKey="meansOfTransport" />
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetails }}>
                <CheckboxGroupField
                  name="searchCriteria.ptModesFilter.ptModes"
                  useSwitchCheckbox
                  labelPlacement="start"
                  options={transportOptions}
                />
              </AccordionDetails>
            </Accordion>
          </Card>
        </ClickAwayListener>
      }
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        onClick={() => setControlsShown(true)}
        className={classes.section}
      >
        <Icon name="filter" size={24} />
        <Typography variant="body2">
          <TransSubtitle i18nKey="filters" />
          {!!appliedFiltersNumber && `(${appliedFiltersNumber})`}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
