import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { getBookingTrips } from 'utils/trip';
import { JourneyInfo } from 'components/JourneyInfo';

const useStyles = makeStyles(
  () => ({
    section: {
      width: '25rem',
    },
  }),
  {
    name: 'Route',
  }
);

interface RouteProps {}

export const Route: FC<RouteProps> = () => {
  const classes = useStyles();
  // const [showSeatingInfo, setShowSeatingInfo] = useState(false);
  const booking = useSelector(currentBookingSelector)!;
  const { passengers } = booking;
  // const handleSeatingInfoToggle = useCallback(
  //   (e: ChangeEvent<HTMLInputElement>) => {
  //     setShowSeatingInfo(e.target.checked);
  //   },
  //   []
  // );
  const [outboundTrips, inboundTrips] = getBookingTrips(booking);

  return (
    <>
      {/*<Stack direction="row" spacing={1} alignItems="center" mb={2}>*/}
      {/*  <Switch value={showSeatingInfo} onChange={handleSeatingInfoToggle} />*/}
      {/*  <Typography variant="body2">*/}
      {/*    <TransSubtitle i18nKey="seatingInfo" />*/}
      {/*  </Typography>*/}
      {/*</Stack>*/}
      <Typography variant="subtitle">
        <TransSubtitle
          i18nKey="passengersQty"
          count={passengers.length}
          tOptions={{ postProcess: 'interval' }}
        />
      </Typography>
      <Stack
        direction="row"
        mt={1}
        divider={<Divider orientation="vertical" />}
      >
        {(['outbound', 'inbound'] as const).map((type) => {
          const currentTrips = {
            outbound: outboundTrips,
            inbound: inboundTrips,
          }[type];
          return (
            currentTrips.length && (
              <Stack spacing={2} key={type} className={classes.section}>
                <Typography variant="subtitle">
                  <TransSubtitle i18nKey={type} />
                </Typography>
                <Box
                  p={2}
                  sx={{
                    background: (theme) => theme.palette.background.default,
                  }}
                >
                  <JourneyInfo trips={currentTrips} />
                </Box>
              </Stack>
            )
          );
        })}
      </Stack>
    </>
  );
};
