import { selectEmailSendAvailable } from 'features/user/userSelector';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Icon,
  Layout,
  Loadable,
  Modal,
  TabPanel,
  Tabs,
} from '@fleet/shared';
import { CardHeader } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';
import { BookingsDetailsCard } from 'routes/bookingDetails/BookingsDetailsCard';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useHistory, useParams } from 'react-router-dom';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBooking,
  getComments,
  resetCurrentBooking,
  sendConfirmation,
} from 'features/booking/bookingActions';
import { currentBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { noop } from '@fleet/shared/utils/noop';
import { PassengersAndTickets } from 'routes/bookingDetails/tabs/PassengersAndTickets';
import { BookingAddOns } from 'routes/bookingDetails/tabs/BookingAddOns';
import { Fees } from 'routes/bookingDetails/tabs/Fees';
import { Route } from 'routes/bookingDetails/tabs/Route';
import { PayerData } from 'routes/bookingDetails/tabs/PayerData';
import { History } from 'routes/bookingDetails/tabs/History';
import { Comments } from 'routes/bookingDetails/tabs/Comments';
import {
  bookingCommentsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { useForm } from '@fleet/shared/form';
import { useModal } from '@fleet/shared/hooks';
import {
  SendTicketsSelection,
  TicketSelectionPayload,
} from 'components/SendTicketsSelection';
import { makeStyles } from '@mui/styles';
import { downloadBookingTickets } from 'utils/trip';

interface BookingsDetailsProps {}

const tabKeys = [
  'passengersAndTickets',
  // 'bookingAddOns',
  // 'fees',
  'route',
  'payerData',
  'history',
  'comments',
] as const;

const SEND_TICKETS_FORM_ID = 'sendTicketsForm';

const useStyles = makeStyles(
  (theme) => ({
    sendTicketsModal: {
      '& .MuiPaper-root': {
        width: '31rem',
      },
      '& .MuiDialogTitle-root > .MuiTypography-root': {
        fontSize: theme.typography.h2.fontSize,
      },
      '& .MuiDialogContent-root': {
        padding: 0,
        paddingBottom: '1rem',
      },
    },
    commentIcon: {
      transform: 'translateY(-0.375rem)',
      marginLeft: '0.25rem',
    },
  }),
  { name: 'BookingDetails' }
);

export const BookingDetails: FC<BookingsDetailsProps> = () => {
  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const currentBooking = useSelector(currentBookingSelector);
  const [activeTab, setActiveTab] = useState<typeof tabKeys[number]>(
    tabKeys[0]
  );
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(currentBookingLoadingSelector);
  const emailSendAvailable = useSelector(selectEmailSendAvailable);
  const comments = useSelector(bookingCommentsSelector);
  const handleConfirmationSend = useCallback(
    async ({
      confirmationRecipient = [],
      additionalConfirmationRecipients = [],
      passengerSelection = [],
    }) => {
      const confirmationRecipients = [
        ...confirmationRecipient,
        ...additionalConfirmationRecipients,
      ];
      await Promise.all(
        [
          confirmationRecipients.length &&
            dispatch(
              sendConfirmation({
                url: 'purchase-confirmation',
                bookingId: id,
                emailsOverride: confirmationRecipients,
              })
            ).unwrap(),
          passengerSelection.length &&
            dispatch(
              sendConfirmation({
                url: 'ticket-delivery',
                bookingId: id,
                passengers: passengerSelection.map((passengerId: string) => ({
                  passengerId,
                })),
              })
            ).unwrap(),
        ].filter(Boolean)
      );
      onClose();
    },
    [dispatch, id, onClose]
  );

  const { form: emailsForm, handleSubmit } = useForm<TicketSelectionPayload>({
    onSubmit: handleConfirmationSend,
  });

  const onTicketDownload = useCallback(async () => {
    downloadBookingTickets(currentBooking);
  }, [currentBooking]);

  const tabsContent = useMemo<Record<typeof tabKeys[number], JSX.Element>>(
    () => ({
      passengersAndTickets: <PassengersAndTickets />,
      bookingAddOns: <BookingAddOns />,
      fees: <Fees />,
      route: <Route />,
      payerData: <PayerData />,
      history: <History />,
      comments: <Comments />,
    }),
    []
  );
  const actionButtons = useMemo(
    () =>
      [
        {
          icon: <Icon name="mail" />,
          label: <TransButton i18nKey="sendTickets" />,
          hidden: !emailSendAvailable,
          onClick: onOpen,
        },
        {
          icon: <Icon width={13} name="download" />,
          label: <TransButton i18nKey="downloadTickets" />,
          onClick: onTicketDownload,
        },
        {
          icon: <Icon width={13} name="print" />,
          label: <TransButton i18nKey="printTickets" />,
          disabled: true,
          onClick: noop,
        },
      ].filter((btn) => !btn.hidden),
    [onOpen, onTicketDownload, emailSendAvailable]
  );

  useEffect(() => {
    dispatch(getBooking(id));
    dispatch(getComments(id));
    return () => {
      dispatch(resetCurrentBooking());
    };
  }, [dispatch, id]);

  return (
    <Loadable loading={loading}>
      <Layout
        sx={{ flex: '0 !important', minHeight: 'unset !important' }}
        header={
          <CardHeader
            sx={{ py: 2, px: 3 }}
            title={
              <Stack direction="row">
                <IconButton
                  onClick={() => history.push('/bookings')}
                  color="inherit"
                >
                  <Icon name="arrow-left" />
                </IconButton>
                <TransTitle
                  i18nKey="booking"
                  values={{ num: currentBooking?.id }}
                />
              </Stack>
            }
            action={
              <>
                {actionButtons.map(
                  ({ icon, label, onClick, disabled }, idx) => (
                    <Button
                      key={idx}
                      startIcon={icon}
                      variant="text"
                      disabled={disabled}
                      onClick={onClick}
                    >
                      <Typography variant="body2">{label}</Typography>
                    </Button>
                  )
                )}
              </>
            }
          />
        }
      >
        <Box sx={{ p: 3, pt: 2, pb: 2 }}>
          <Stack sx={{ mb: 2 }}>
            <Typography variant="subtitle">
              <TransSubtitle i18nKey="details" />
            </Typography>
          </Stack>
          <BookingsDetailsCard onShowFullRoute={() => setActiveTab('route')} />
        </Box>
      </Layout>
      <Box p={3}>
        {currentBooking && (
          <Tabs onChange={(_, tab) => setActiveTab(tab)} value={activeTab}>
            {tabKeys.map((tab) => (
              <TabPanel
                key={tab}
                label={
                  <Stack direction="row" alignItems="center">
                    <TransSubtitle i18nKey={tab} />
                    {tab === 'comments' && !!comments.length && (
                      <Icon name="comment" className={classes.commentIcon} />
                    )}
                  </Stack>
                }
                value={tab}
              >
                {tabsContent[tab]}
              </TabPanel>
            ))}
          </Tabs>
        )}
      </Box>
      <Modal
        className={classes.sendTicketsModal}
        title={<TransButton i18nKey="sendTickets" />}
        open={open}
        onClose={onClose}
        actionButton={
          <Button
            loading={loading}
            startIcon={<Icon name="mail" />}
            variant="contained"
            form={SEND_TICKETS_FORM_ID}
            type="submit"
          >
            <TransButton i18nKey="sendTickets" />
          </Button>
        }
      >
        {currentBooking && (
          <SendTicketsSelection
            form={emailsForm}
            formId={SEND_TICKETS_FORM_ID}
            onSubmit={handleSubmit}
          />
        )}
      </Modal>
    </Loadable>
  );
};
