import { FC } from 'react';
import { alpha, Box, Card, CardContent, Typography } from '@mui/material';
import { TransParagraph } from 'i18n/trans/paragraph';
import { makeStyles } from '@mui/styles';
import { Icon } from '@fleet/shared';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 300,
    },
    content: {
      width: 250,
      margin: '0 auto',
      textAlign: 'center',
    },
    iconWrap: {
      width: 80,
      height: 80,
      borderRadius: '50%',
      background: alpha(theme.palette.warning.main, 0.1),
      color: alpha(theme.palette.warning.main, 0.8),
      display: 'inline-flex',
      margin: '32px 0',
    },
  }),
  {
    name: 'EmptyResults',
  }
);

interface EmptyResultsProps {}

export const EmptyResults: FC<EmptyResultsProps> = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Box className={classes.iconWrap}>
          <Icon
            name="search"
            sx={{ m: 'auto', strokeWidth: '2px' }}
            size={20}
          />
        </Box>
        <Typography color="text.secondary">
          <TransParagraph i18nKey="emptySearch" />
        </Typography>
      </CardContent>
    </Card>
  );
};
