import React, { useCallback, useMemo } from 'react';
import { Header as FleetHeader, Icon } from '@fleet/shared';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useDispatch, useSelector } from 'store/utils';
import { setShowRetalerModal } from 'features/user/userActions';
import { Stack, Typography } from '@mui/material';
import { selectUser, selectUserUnits } from 'features/user/userSelector';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    organizationSelect: {
      cursor: 'pointer',
      '&:hover > *': {
        color: theme.palette.action.hover,
      },
    },
  }),
  { name: 'Header' }
);
export const Header = () => {
  const classes = useStyles();
  const auth = useAuth();
  const user = useSelector(selectUser);
  const { organizationId } = useSelector(selectUserUnits);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const steps = useMemo(
    () => [
      {
        label: <TransSubtitle i18nKey="sellTickets" />,
        path: '/tickets',
        basePath: '/tickets',
      },
      {
        label: <TransSubtitle i18nKey="bookingSearch" />,
        path: '/bookings',
        basePath: '/bookings',
      },
    ],
    []
  );
  const currentOrganization = useMemo(
    () => user?.organizations.find(({ id }) => id === organizationId),
    [organizationId, user?.organizations]
  );
  const handleMenuClick = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const showRetailerSelectModal = useCallback(() => {
    dispatch(setShowRetalerModal(true));
  }, [dispatch]);

  return (
    <FleetHeader
      steps={steps}
      backLinks={[]}
      pathname={pathname}
      onLogout={auth.signoutRedirect}
      helpPath="https://help.turnit.com/th/latest"
      onMenuClick={handleMenuClick}
      additionalControls={
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          className={classes.organizationSelect}
          onClick={showRetailerSelectModal}
        >
          <Icon name="profile" width={16} height={20} />
          <Typography>{currentOrganization?.name}</Typography>
        </Stack>
      }
    />
  );
};
