import { FC, useMemo } from 'react';
import { useTable } from 'react-table';
import { Icon, Table, TableColumns } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { BookingAdmissionAncillary } from 'dto/booking';
import { makeStyles } from '@mui/styles';

interface PassengerAddonsProps {
  data: Array<BookingAdmissionAncillary>;
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
  }),
  {
    name: 'PassengerAddons',
  }
);

export const PassengerAddons: FC<PassengerAddonsProps> = ({ data }) => {
  const classes = useStyles();
  const columns = useMemo<TableColumns<BookingAdmissionAncillary>>(
    () => [
      {
        accessor: 'productDescription',
        Header: <TransTableHead i18nKey="addon" />,
      },
      {
        accessor: 'amountOf',
        Header: <TransTableHead i18nKey="qty" />,
      },
      {
        id: 'changeable',
        Header: <TransTableHead i18nKey="changeable" />,
        accessor: (addon) => {
          const exchangeable = addon.exchangeable === 'YES';
          return (
            <Icon
              name={exchangeable ? 'check-circle' : 'deactivate'}
              size={exchangeable ? 16 : 14}
              color={exchangeable ? 'success' : 'error'}
            />
          );
        },
      },
      {
        id: 'refundable',
        Header: <TransTableHead i18nKey="refundable" />,
        accessor: (addon) => {
          const exchangeable = addon.refundable === 'YES';
          return (
            <Icon
              name={exchangeable ? 'check-circle' : 'deactivate'}
              size={exchangeable ? 16 : 14}
              color={exchangeable ? 'success' : 'error'}
            />
          );
        },
      },
      {
        id: 'price',
        Header: <TransTableHead i18nKey="price" />,
        accessor: ({ price }) => `${price.amount} ${price.currency}`,
      },
    ],
    []
  );

  const addonsData = useMemo(() => data, [data]);

  const table = useTable({
    data: addonsData,
    columns,
  });

  return <Table table={table} classes={{ table: classes.table }} />;
};
