import { Route, RouteComponentProps, Switch } from 'react-router';
import { Dashboard } from 'routes/tickets/dashboard/Dashboard';
import { SearchResults } from 'routes/tickets/searchResults/SearchResults';
import { Checkout } from 'routes/tickets/checkout/Checkout';
import React, { FC, useCallback, useEffect } from 'react';
import {
  SearchTab,
  SearchTabsContext,
  SearchTabsProvider,
} from 'components/SearchTabsContext';
import { SearchBar } from 'components/searchBar/SearchBar';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';
import { BookingSuccess } from './tickets/checkout/BookingSuccess';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBooking,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { Loadable } from '@fleet/shared';
import { ticketsLoading } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    padding: '24px 24px 0',
    boxSizing: 'content-box',
    width: 'calc(100% - 48px)',
    minWidth: 976,
    maxWidth: 1416,
    margin: '0 auto',
    overflow: 'auto',
  },
}));

interface TicketsProps extends RouteComponentProps {}
export const Tickets: FC<TicketsProps> = ({ match: { path }, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const loading = useSelector(ticketsLoading);
  const handleTabState = useCallback(
    ({ bookingId, isCompleted }: SearchTab) =>
      history.replace(
        bookingId ? `${path}/${isCompleted ? 'success' : 'checkout'}` : path
      ),
    [history, path]
  );
  const handleSearchRedirect = useCallback(
    () => history.replace(`${path}/search`),
    [history, path]
  );
  const initBooking = useCallback(
    async (
      currentTab: SearchTab,
      updateTab: SearchTabsContext['updateTab']
    ) => {
      const { bookingId } = currentTab;
      if (!bookingId) return history.replace('/tickets');
      try {
        if (bookingId !== booking?.id) {
          await dispatch(getBooking(bookingId)).unwrap();
        }
      } catch (e) {
        updateTab({ bookingId: undefined });
        history.replace('/tickets');
      }
    },
    [booking?.id, dispatch, history]
  );

  useEffect(() => {
    return () => {
      dispatch(resetCurrentBooking());
    };
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      {({ loading }) =>
        !loading && (
          <Stack className={classes.content}>
            <SearchTabsProvider onTabChange={handleTabState}>
              <SearchBar beforeSubmit={handleSearchRedirect}>
                <Switch>
                  <Route path={path} exact component={Dashboard} />
                  <Route
                    path={`${path}/search`}
                    exact
                    component={SearchResults}
                  />
                  <Route
                    path={`${path}/checkout`}
                    exact
                    render={(props) => (
                      <Checkout {...props} initBooking={initBooking} />
                    )}
                  />
                  <Route
                    path={`${path}/success`}
                    exact
                    render={(props) => (
                      <BookingSuccess {...props} initBooking={initBooking} />
                    )}
                  />
                </Switch>
              </SearchBar>
            </SearchTabsProvider>
          </Stack>
        )
      }
    </Loadable>
  );
};
