import { FC, useCallback, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Icon, Switch } from '@fleet/shared/mui';
import { FieldArray, SelectOption, Theme } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { CheckboxGroupField, SelectField, TextField } from '@fleet/shared/form';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import { cardsSelector } from 'features/classification/classificationSelectors';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';
import { CustomerCard } from 'dto/trip';

interface PassengerCardProps {
  number?: number;
  width: number;
  name: string;
  onRemove: () => void;
}

const useStyles = makeStyles<Theme, PassengerCardProps>(
  (theme) => ({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      width: ({ width }) => `${width}px`,
      background: theme.palette.background.default,
      height: 'fit-content',
      '& .MuiCardContent-root': {
        padding: '1rem',
      },
    },
    card: {
      overflow: 'visible',
      width: '100%',
      position: 'relative',
      background: 'white',
      padding: '0.5rem',
      '& $deleteBtn': {
        padding: '0.25rem',
        background: theme.palette.error.main,
        color: 'white!important',
        position: 'absolute',
        top: -4,
        right: -8,
      },
    },
    accordion: {},
    deleteBtn: {},
  }),
  {
    name: 'PassengerCard',
  }
);

export const PassengerCard: FC<PassengerCardProps> = (props) => {
  const cards = useSelector(cardsSelector);
  const { number, name, onRemove } = props;
  const classes = useStyles(props);
  const [areDetailsShown, setDetailsShown] = useState(false);
  const [prmNeedsShown, setPrmNeedsShown] = useState(false);
  const checkIfCardIsRequired = useCallback(
    ({ code, type }) =>
      cards.find((card) => code === card.code && type === card.type.id)
        ?.cardIdRequired,
    [cards]
  );
  const cardsByType = useMemo(
    () => _groupBy(cards, (card) => card.type.id),
    [cards]
  );
  const cardTypeOptions = useMemo(
    () =>
      Object.keys(cardsByType).map((type) => ({
        value: type,
        label: cardsByType[type][0].type.name,
      })),
    [cardsByType]
  );
  const cardCodeOptionsMap = useMemo<Record<string, Array<SelectOption>>>(
    () =>
      _mapValues(cardsByType, (cards) =>
        cards
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map(({ code, name }) => ({
            label: name.text,
            value: code,
          }))
      ),
    [cardsByType]
  );
  const prmNeedsOptions = useMemo(
    () => [
      //NEED_PRM_SUPPORT", "WHEELCHAIR", "ACCOMPANYING_DOG"
      { label: 'NEED_PRM_SUPPORT', value: 'NEED_PRM_SUPPORT' },
      { label: 'WHEELCHAIR', value: 'WHEELCHAIR' },
      { label: 'ACCOMPANYING_DOG', value: 'ACCOMPANYING_DOG' },
    ],
    []
  );

  return (
    <Card className={classes.root}>
      <CardContent>
        <Stack spacing={1} alignItems="flex-start">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="body1" fontWeight="bold">
              <TransSubtitle i18nKey="passengerTitle" values={{ number }} />
            </Typography>
            <Button
              startIcon={<Icon name="trash" />}
              sx={{ px: 0 }}
              onClick={onRemove}
            >
              <TransButton i18nKey="delete" />
            </Button>
          </Stack>
          <TextField
            name={`${name}.age`}
            label={<TransField i18nKey="passengerAge" />}
          />
          <FieldArray<CustomerCard> name={`${name}.cards`}>
            {({ fields }) => (
              <>
                {fields.value?.map((card, idx) => (
                  <Card className={classes.card} key={idx} elevation={0}>
                    <Stack direction="row" spacing={1} mb={1} key={idx}>
                      <SelectField
                        required
                        name={`${fields.name}[${idx}].type`}
                        options={cardTypeOptions}
                        defaultValue={cardTypeOptions[0]}
                        label={<TransField i18nKey="cardType" />}
                      />
                      <SelectField
                        required
                        name={`${fields.name}[${idx}].code`}
                        options={cardCodeOptionsMap[card.type]}
                        label={<TransField i18nKey="cardName" />}
                      />
                    </Stack>
                    <TextField
                      key={card.code}
                      name={`${fields.name}[${idx}].number`}
                      label={<TransField i18nKey="cardNumber" />}
                      required={checkIfCardIsRequired(card)}
                    />
                    <IconButton
                      className={classes.deleteBtn}
                      onClick={() => fields.remove(idx)}
                    >
                      <Icon name="close" />
                    </IconButton>
                  </Card>
                ))}
                <Button
                  startIcon={<Icon name="add" />}
                  sx={{ px: 0 }}
                  onClick={() =>
                    fields.push({
                      type: cardTypeOptions[0].value,
                    })
                  }
                >
                  <TransButton i18nKey="addPassengerCard" />
                </Button>
              </>
            )}
          </FieldArray>
        </Stack>
      </CardContent>
      <Accordion
        className={classes.accordion}
        disableGutters
        elevation={1}
        expanded={areDetailsShown}
        onChange={() => setDetailsShown(!areDetailsShown)}
      >
        <AccordionSummary>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon name={`chevron-${areDetailsShown ? 'down' : 'up'}`} />
            <Typography variant="body1">Details</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography variant="body1">PRM needs</Typography>
            <Switch
              value={prmNeedsShown}
              onChange={({ target }) => setPrmNeedsShown(target.checked)}
            />
          </Stack>
          {prmNeedsShown && (
            <CheckboxGroupField
              name={`${name}.prmNeed`}
              options={prmNeedsOptions}
              margin="normal"
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
