import { SearchTextField } from 'components/searchBar/SearchTextField';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { Button, Icon } from '@fleet/shared/mui';
import { FormField } from '@fleet/shared/form';
import { SearchStopsSelect } from 'components/searchBar/SearchStopsSelect';
import { Box, Divider, Stack, Typography, ButtonBase } from '@mui/material';
import { selectDefaultCurrency } from 'features/user/userSelector';
import { useLocation } from 'react-router';
import { FormProvider, useForm } from '@fleet/shared/form';
import {
  useMemo,
  useCallback,
  useContext,
  useEffect,
  FC,
  useState,
} from 'react';
import { SearchPassengers } from './SearchPassengers';
import { useDispatch, useSelector } from 'store/utils';
import { searchTrips } from 'features/trip/tripActions';
import { TripSearchParams } from 'dto/trip';
import { selectTripLoading } from 'features/trip/tripSelector';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { PromoCodes } from 'components/searchBar/PromoCodes';
import { SearchFilters } from 'components/searchBar/SearchFilters';
import { v4 } from 'uuid';
import { DateField } from '@fleet/shared';
import {
  RECENT_STOPS_LS_KEY,
  RECENT_TRIPS_LS_KEY,
  useLocalStorage,
} from 'hooks/useLocalStorage';
import { SelectOption } from '@fleet/shared/mui/Select';
import { stopsSelector } from 'features/classification/classificationSelectors';
import _take from 'lodash/take';
import _uniqBy from 'lodash/uniqBy';
import { RecentTrip } from 'features/user/userActions';

const useStyles = makeStyles((theme) => ({
  searchBar: {
    height: 80,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    background: theme.palette.common.white,

    '& > div': {
      flexBasis: '100%',
    },
  },
  switchBtnWrap: {
    position: 'relative',
    zIndex: 1,
    '& + hr': {
      display: 'none',
    },
  },
  switchBtn: {
    padding: 4,
    position: 'absolute',
    top: '50%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
    background: theme.palette.common.white,
    transform: 'translate3d(-50%, -50%, 0)',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: [
        alpha(theme.palette.action.hover, 0.2),
        theme.palette.common.white,
      ]
        .map((color) => `inset 0 0 0 2rem ${color}`)
        .join(','),
    },
  },
  submitBtn: {
    flexBasis: '100%',
    padding: 0,
    borderRadius: 0,
  },
}));

interface SearchBarProps {
  beforeSubmit: () => void;
}

export const SearchBar: FC<SearchBarProps> = ({ children, beforeSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const stops = useSelector(stopsSelector);
  const defaultCurrency = useSelector(selectDefaultCurrency);
  const searchLoading = useSelector(selectTripLoading);
  const { initialValue: lsRecentStops, setData: setLsRecentStops } =
    useLocalStorage<Array<SelectOption>>({
      key: RECENT_STOPS_LS_KEY,
      getDefaultValue: (value) => value || [],
    });
  const { initialValue: lsRecentTrips, setData: setLsRecentTrips } =
    useLocalStorage<Array<RecentTrip>>({
      key: RECENT_TRIPS_LS_KEY,
      getDefaultValue: (value) => value || [],
    });
  const [recentStops, setRecentStops] = useState(lsRecentStops);
  const updateRecentStops = useCallback(
    ([originStop, destinationStop]: Array<{ code: string }>) => {
      if (!stops.length) return;
      const updatedRecentStops = _take(
        _uniqBy(
          [
            ...[originStop.code, destinationStop.code].map((code) => {
              const stop = stops.find(({ id }) => id === code)!;
              return {
                label: stop.name,
                value: stop.id,
              };
            }),
            ...recentStops,
          ],
          'value'
        ),
        10
      );
      setLsRecentStops(updatedRecentStops);
      setRecentStops(updatedRecentStops);
    },
    [recentStops, setLsRecentStops, stops]
  );
  const updateRecentTrips = useCallback(
    ([originStop, destinationStop]: Array<{ code: string }>) => {
      if (!stops.length) return;
      const presentInRecentTrips = lsRecentTrips.find(
        ({ origin, destination }) =>
          origin.code === originStop.code &&
          destination.code === destinationStop.code
      );
      if (!presentInRecentTrips) {
        const [origin, destination] = [originStop, destinationStop].map(
          ({ code }) => {
            const currentStop = stops.find(({ id }) => id === code)!;
            return {
              name: currentStop.name,
              code: currentStop.id,
            };
          }
        );

        setLsRecentTrips([{ origin, destination }, ...lsRecentTrips]);
      }
    },
    [lsRecentTrips, setLsRecentTrips, stops]
  );
  const { activeTabIdx, currentTab, updateTab } = useContext(SearchTabsContext);
  const onSubmit = useCallback(
    async ({ searchCriteria, ...rest }: TripSearchParams) => {
      let payload;
      const { originStop, destinationStop } = rest;
      updateRecentStops([originStop, destinationStop]);
      updateRecentTrips([originStop, destinationStop]);

      if (!searchCriteria) {
        payload = rest;
      } else {
        const { ptModesFilter, transfersFilter, carriersFilter } =
          searchCriteria;
        payload = {
          ...rest,
          searchCriteria: {
            ...(ptModesFilter?.ptModes.length && { ptModesFilter }),
            ...(carriersFilter?.carriers.length && { carriersFilter }),
            transfersFilter,
          },
        };
      }
      beforeSubmit();
      await dispatch(
        searchTrips({
          ...payload,
          currency: defaultCurrency!,
        })
      );
    },
    [
      updateRecentStops,
      updateRecentTrips,
      beforeSubmit,
      dispatch,
      defaultCurrency,
    ]
  );
  const hideSearchBar = useMemo(
    () =>
      ['checkout', 'success'].some((path) => location.pathname.includes(path)),
    [location]
  );
  const initialValues = useMemo(
    () => ({
      passengerSpecifications: [
        {
          type: 'PERSON',
          externalReference: v4(),
        },
      ],
      departureTime: new Date().toISOString(),
      promotionCodes: [],
      corporateCodes: [],
    }),
    []
  );

  const { form, handleSubmit, invalid } = useForm<TripSearchParams>({
    subscription: { invalid: true, values: true },
    initialValues,
    onSubmit,
  });

  const updateCurrentTabParams = useCallback(() => {
    updateTab(
      {
        params: form.getState().values,
      },
      activeTabIdx
    );
  }, [form, updateTab, activeTabIdx]);

  useEffect(() => {
    form.restart({ ...initialValues, ...currentTab?.params });
  }, [form, initialValues, currentTab]);

  useEffect(() => {
    return () => updateCurrentTabParams();
  }, [updateCurrentTabParams]);

  const switchDestinations = useCallback(() => {
    const { getState, reset } = form;
    const {
      values: { originStop, destinationStop, ...values },
    } = getState();
    reset({
      ...values,
      originStop: destinationStop,
      destinationStop: originStop,
    });
  }, [form]);

  return (
    <FormProvider {...form}>
      <Stack
        component="form"
        name="searchBar"
        sx={{
          mb: 3,
          ...(hideSearchBar && {
            display: 'none',
          }),
        }}
        onSubmit={handleSubmit}
      >
        <Stack
          className={classes.searchBar}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <FormField<string>
            required
            name="originStop.code"
            render={({ input }) => (
              <SearchStopsSelect
                label={<TransField i18nKey="from" />}
                onChange={input.onChange}
                value={input.value}
                recentStops={recentStops}
              />
            )}
          />
          <Box className={classes.switchBtnWrap} component="span">
            <ButtonBase
              className={classes.switchBtn}
              onClick={switchDestinations}
            >
              <Icon name="switch" size={24} />
            </ButtonBase>
          </Box>
          <FormField<string>
            required
            name="destinationStop.code"
            render={({ input }) => (
              <SearchStopsSelect
                label={<TransField i18nKey="to" />}
                onChange={input.onChange}
                value={input.value}
                recentStops={recentStops}
              />
            )}
          />
          {(['departureTime', 'arrivalTime'] as const).map((field) => (
            <DateField
              key={field}
              name={field}
              dateFormat="EEE, dd/MM HH:mm"
              shouldCloseOnSelect={false}
              showWeekNumbers
              showTimeInput
              {...(field === 'departureTime'
                ? { minDate: new Date(), required: true }
                : { disabled: true })}
              minDate={new Date()}
              isClearable={false}
              customInput={
                <SearchTextField label={<TransField i18nKey={field} />} />
              }
            />
          ))}
          <SearchPassengers name="passengerSpecifications" />
          <SearchFilters />
          <Button
            size="large"
            type="submit"
            className={classes.submitBtn}
            disabled={invalid}
            loading={searchLoading}
            label={
              <Typography variant="h2">
                <TransButton i18nKey="search" />
              </Typography>
            }
            onClick={updateCurrentTabParams}
          />
        </Stack>
        <PromoCodes />
      </Stack>
      {children}
    </FormProvider>
  );
};
