import { Stack, Typography } from '@mui/material';
import { Button, Icon } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { CartTotal } from 'components/CartTotal';
import { FC } from 'react';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransParagraph } from 'i18n/trans/paragraph';
import { useSelector } from 'store/utils';
import { bookingExpiredSelector } from 'features/booking/bookingSelectors';

interface AncillariesProps {
  goToNextStep: () => void;
}

const MockAddonsSelection = ({ isLegLevel }: { isLegLevel?: boolean }) => (
  <Stack alignItems="center" sx={{ width: '100%' }}>
    <Typography variant="h2" sx={{ alignSelf: 'flex-start' }}>
      <TransSubtitle
        i18nKey={isLegLevel ? 'addonsLegLevel' : 'addonsBookingLevel'}
      />
    </Typography>
    <Icon size={80} name="empty" sx={{ margin: '16px 0' }} />
    <Typography variant="body1" color="text.secondary">
      <TransParagraph
        i18nKey={isLegLevel ? 'addonsLegLevelEmpty' : 'addonsBookingLevelEmpty'}
      />
    </Typography>
  </Stack>
);
export const Ancillaries: FC<AncillariesProps> = ({ goToNextStep }) => {
  const isBookingExpired = useSelector(bookingExpiredSelector);

  return (
    <>
      <Stack spacing={2} alignItems="flex-start">
        <Typography variant="h1">
          <TransTitle i18nKey="addons" />
        </Typography>
        <Button
          disabled
          label={<TransButton i18nKey="addAddon" />}
          startIcon={<Icon name="add" />}
        />
        <MockAddonsSelection />
      </Stack>

      <CartTotal>
        <>
          <Button
            variant="text"
            disabled={isBookingExpired}
            label={<TransButton i18nKey="resetFields" />}
          />
          <Button
            variant="contained"
            type="submit"
            form="passengerDetails"
            disabled={isBookingExpired}
            onClick={goToNextStep}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                <TransButton i18nKey="paymentOverview" />
              </>
            }
          />
        </>
      </CartTotal>
    </>
  );
};
