import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '&$small': {
      alignItems: 'center',
    },
    '&$small $connectIcon': {
      height: 12,
    },
  },
  tripIcon: {
    marginRight: 16,
    '& $tripPoint': {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
      '&:last-of-type': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& $connectIcon': {
      width: '2px',
      background: theme.palette.divider,
      margin: '0 auto',
      height: 'calc(100% - 24px)',
    },
  },
  tripPoint: {},
  connectIcon: {},
  tripInfo: {
    flex: 1,
  },
  small: {},
}));

interface TripInfoWrapperProps {
  className?: string;
  small?: boolean;
}

export const LegWrapper: FC<TripInfoWrapperProps> = ({
  className,
  children,
  small,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.small]: small,
      })}
    >
      <div className={classNames(classes.tripIcon)}>
        <div className={classes.tripPoint} />
        <div className={classes.connectIcon} />
        <div className={classes.tripPoint} />
      </div>
      <div className={classes.tripInfo}>
        <>{children}</>
      </div>
    </div>
  );
};
