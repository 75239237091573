import { FC, useCallback, useMemo } from 'react';
import {
  Button,
  DateField,
  FormProvider,
  Icon,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { BookingDetailsPassenger } from 'dto/booking';
import { updatePassengerDetails } from 'features/trip/tripActions';
import { getBooking } from 'features/booking/bookingActions';
import { useDispatch, useSelector } from 'store/utils';
import { trimPhoneAreaCode } from 'utils/trip';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { passengerUpdateLoading } from 'features/loading/loadingSelectors';

interface PassengerDataProps {
  value: BookingDetailsPassenger;
}
export const PassengerData: FC<PassengerDataProps> = ({ value }) => {
  const dispatch = useDispatch();
  const currentBooking = useSelector(currentBookingSelector);
  const loading = useSelector(passengerUpdateLoading);
  const genderOptions = useMemo(
    () => [
      { label: 'Male', value: 'MALE' },
      { label: 'Female', value: 'FEMALE' },
      { label: 'X', value: 'X' },
    ],
    []
  );
  const initialValues = useMemo(() => {
    const { firstName, lastName, contactInformation, gender } = value;
    return {
      firstName: firstName.value,
      lastName: lastName.value,
      emailAddress: contactInformation.emailAddress.value,
      phoneNumber: trimPhoneAreaCode(contactInformation.phoneNumber.value),
      gender: gender.value,
    };
  }, [value]);
  const onSubmit = useCallback(
    async ({
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      gender,
      birthDate,
    }) => {
      await dispatch(
        updatePassengerDetails({
          ...value,
          bookingId: currentBooking!.id,
          passengerId: value.id,
          firstName,
          lastName,
          phone: {
            areaCode: '+46',
            number: phoneNumber,
          },
          birthDate,
          gender,
          email: emailAddress,
        })
      ).unwrap();
      await dispatch(getBooking(currentBooking!.id));
    },
    [dispatch, currentBooking, value]
  );
  const { form, handleSubmit } = useForm({
    initialValues,
    onSubmit,
  });
  return (
    <FormProvider {...form}>
      <Stack component="form" onSubmit={handleSubmit}>
        <Grid container columns={4} spacing={2} rowSpacing={2}>
          <Grid item xs={1}>
            <TextField
              disabled={loading}
              required={value.firstName.isRequired}
              name="firstName"
              label={<TransField i18nKey="passengerNameFirst" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              disabled={loading}
              required={value.lastName.isRequired}
              name="lastName"
              label={<TransField i18nKey="passengerNameLast" />}
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              disabled={loading}
              required={value.gender.isRequired}
              name="gender"
              label={<TransField i18nKey="gender" />}
              options={genderOptions}
            />
          </Grid>
          <Grid item xs={1}>
            <DateField
              disabled={loading}
              yearDropdownItemNumber={100}
              name="birthDate"
              label={<TransField i18nKey="birthday" />}
              withPortal
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              disabled={loading}
              required={value.contactInformation.emailAddress.isRequired}
              name="emailAddress"
              email
              label={<TransField i18nKey="email" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              disabled={loading}
              required={value.contactInformation.phoneNumber.isRequired}
              name="phoneNumber"
              label={<TransField i18nKey="mobileNumber" />}
            />
          </Grid>
        </Grid>
        <Button
          sx={{ marginLeft: 'auto' }}
          type="submit"
          startIcon={<Icon name="check" />}
          loading={loading}
        >
          <TransButton i18nKey="saveChanges" />
        </Button>
      </Stack>
    </FormProvider>
  );
};
