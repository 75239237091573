import { Divider, Card, CardHeader, Typography, Stack } from '@mui/material';
import { TripsTable } from 'routes/tickets/searchResults/TripsTable';
import { useDispatch, useSelector } from 'store/utils';
import {
  selectSelectedOffers,
  selectTripLoading,
  tripLinksMapSelector,
  tripsSelector,
} from 'features/trip/tripSelector';
import { FC, useCallback, useContext, useMemo } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { EmptyResults } from 'components/EmptyResults';
import { Button } from '@fleet/shared/mui';
import { CartTotal } from 'components/CartTotal';
import { postBooking } from 'features/booking/bookingActions';
import { RouteChildrenProps } from 'react-router';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { alpha } from '@mui/material/styles';
import { getBookingDestinations } from 'utils/trip';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { useFormContext } from '@fleet/shared';
import { useField } from 'react-final-form-hooks';
import { PassengerSpecification } from 'dto/trip';
import { bookingLoadingSelector } from 'features/booking/bookingSelectors';

interface SearchResultsProps extends RouteChildrenProps {}

export const SearchResults: FC<SearchResultsProps> = ({ history }) => {
  const dispatch = useDispatch();
  const form = useFormContext();
  const {
    input: { value: passengerSpecifications },
  } = useField<Array<PassengerSpecification>>('passengerSpecifications', form);
  const tripResults = useSelector(tripsSelector);
  const postBookingLoading = useSelector(bookingLoadingSelector);
  const {
    list: selectedOffers,
    reference: journeyReference,
    selectionMap,
  } = useSelector(selectSelectedOffers);
  const offersTotal = useMemo(() => {
    return selectedOffers.reduce(
      (total, offer) => ({
        amount: total.amount + offer.price.amount,
        currency: offer.price.currency,
      }),
      { amount: 0, currency: '' }
    );
  }, [selectedOffers]);
  const loading = useSelector(selectTripLoading);
  const resultLinksMap = useSelector(tripLinksMapSelector);
  const emptyResults = useMemo(
    () =>
      !loading &&
      !tripResults.length &&
      !resultLinksMap.previous &&
      !resultLinksMap.next,
    [loading, tripResults, resultLinksMap]
  );
  const offersSelectionIncomplete = useMemo(() => {
    const coveredLegIds = selectedOffers.reduce<string[]>(
      (ids, { coveredLegIds }) => _uniq([...ids, ...coveredLegIds]),
      []
    );
    const currentTripLegsIds = tripResults
      .find(({ reference }) => journeyReference === reference)
      ?.trips.reduce<string[]>(
        (legIds, trip) => [...legIds, ...trip.legs.map(({ id }) => id)],
        []
      );
    return !_isEqual(currentTripLegsIds?.sort(), coveredLegIds.sort());
  }, [journeyReference, selectedOffers, tripResults]);
  const { currentTab, updateTab } = useContext(SearchTabsContext);
  const postBookingHandler = useCallback(async () => {
    const currentJourney = tripResults.find(
      ({ reference }) => journeyReference === reference
    );
    const booking = await dispatch(
      postBooking({
        offers: selectedOffers.map(({ id }) => ({
          alliances: currentJourney!.alliances!,
          id,
          passengerExternalReferences: passengerSpecifications.map(
            ({ externalReference }) => externalReference
          ),
          selections: selectionMap[id],
        })),
        passengerSpecifications,
      })
    ).unwrap();
    updateTab({
      ...currentTab!,
      name: getBookingDestinations(booking).join(' - '),
      bookingId: booking.id,
    });
    history.push('/tickets/checkout');
  }, [
    tripResults,
    dispatch,
    selectedOffers,
    passengerSpecifications,
    updateTab,
    currentTab,
    history,
    journeyReference,
    selectionMap,
  ]);
  return (
    <>
      <Card sx={{ overflow: 'visible', marginBottom: '72px' }}>
        <CardHeader
          sx={{ px: 4 }}
          title={
            <Typography variant="h1" mr={2}>
              <TransTitle i18nKey="searchResults" />
            </Typography>
          }
        />
        <Divider />
        {emptyResults ? (
          <EmptyResults />
        ) : (
          <Stack
            spacing={3}
            sx={{ background: (theme) => theme.palette.background.default }}
          >
            <TripsTable isOutbound />
          </Stack>
        )}
      </Card>
      {!!offersTotal.currency && (
        <CartTotal offersTotal={offersTotal} isDark>
          <Button
            loading={postBookingLoading}
            disabled={offersSelectionIncomplete}
            sx={{
              background: 'white!important',
              '&:hover': {
                boxShadow: (theme) =>
                  [
                    alpha(theme.palette.action.hover, 0.2),
                    theme.palette.common.white,
                  ]
                    .map((color) => `inset 0 0 0 2rem ${color}`)
                    .join(','),
              },
            }}
            variant="outlined"
            label={<TransButton i18nKey="continueCheckout" />}
            onClick={postBookingHandler}
          />
        </CartTotal>
      )}
    </>
  );
};
