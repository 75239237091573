import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { FC, MouseEvent, ReactNode, useCallback } from 'react';
import { Icon } from '@fleet/shared/mui';
import { IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import classNames from 'classnames';
import { BookmarkedTrip, RecentTrip } from 'features/user/userActions';
import { Tooltip } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface TripsCarouselProps {
  title: ReactNode;
  trips: Array<RecentTrip | BookmarkedTrip>;
  addToFavorites?: (trip: { origin: string; destination: string }) => void;
  removeFromFavorites?: (pairId: string) => void;
  onTripClick: (trip: RecentTrip | BookmarkedTrip) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    tripsTabs: {
      position: 'relative',
      top: '-10px',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTab-root': {
        padding: '10px 7px 2px 0',
        '&:not(:last-of-type)': {
          marginRight: '9px',
        },
      },
    },
    scrollBtnWrapper: {
      top: 'calc(50% - 0.75rem)',
      position: 'absolute',
      zIndex: 1,
      display: 'inline-flex',
      alignItems: 'center',
      '&:empty': {
        display: 'none',
      },
      '& > .MuiButtonBase-root': {
        height: 32,
        background: alpha(theme.palette.primary.light, 0.05),
        '&.Mui-disabled': {
          display: 'none',
        },
        '&:hover': {
          background: alpha(theme.palette.primary.light, 0.1),
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '3rem',
        height: '4rem',
      },
      '&:first-of-type': {
        left: 0,
        '&:before': {
          right: '-1rem',
          backgroundImage: `linear-gradient(to left, rgba(246, 246, 246, 0), 30%,rgb(246, 246, 246))`,
        },
      },
      '&:last-of-type': {
        right: 0,

        '&:before': {
          left: '-1rem',
          backgroundImage: `linear-gradient(to right, rgba(246, 246, 246, 0),30%,rgb(246, 246, 246))`,
        },
      },
    },
    tripCard: {
      margin: '0.5rem',
      padding: '1.25rem',
      overflow: 'visible',
      position: 'relative',
      fontWeight: 'normal',
      '&:hover': {
        '& $favBtn': {
          opacity: 1,
        },
      },
    },
    favBtn: {
      position: 'absolute',
      top: '-10px',
      right: '-7px',
      stroke: alpha(theme.palette.secondary.light, 0.7),

      '&$active': {
        color: theme.palette.primary.main,
      },
      '&:not($active)': {
        opacity: 0,
        color: 'white',

        '&:hover': {
          color: 'rgba(248, 245, 255, 1)',
          stroke: theme.palette.action.hover,
        },
      },
    },
    active: {},
  }),
  { name: 'TripsCarousel' }
);

export const TripsCarousel: FC<TripsCarouselProps> = ({
  title,
  trips,
  addToFavorites,
  removeFromFavorites,
  onTripClick,
}) => {
  const classes = useStyles();
  const getActionHandler = useCallback(
    (trip: RecentTrip | BookmarkedTrip) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (addToFavorites) {
        addToFavorites({
          origin: trip.origin.code,
          destination: trip.destination.code,
        });
      }
      if (removeFromFavorites) {
        removeFromFavorites((trip as BookmarkedTrip).id);
      }
    },
    [addToFavorites, removeFromFavorites]
  );
  if (!trips.length) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="h2">{title}</Typography>
      {!!trips.length && (
        <Tabs
          value={0}
          variant="scrollable"
          scrollButtons="auto"
          className={classes.tripsTabs}
          ScrollButtonComponent={(props) => (
            <div className={classes.scrollBtnWrapper}>
              <IconButton {...props}>
                <Icon color="primary" name={`direction-${props.direction}`} />
              </IconButton>
            </div>
          )}
        >
          {trips.map((trip, idx) => {
            const { origin, destination } = trip;
            return (
              <Tab
                key={idx}
                value={idx}
                onClick={() => onTripClick(trip)}
                label={
                  <Card className={classes.tripCard} elevation={2}>
                    <Tooltip
                      content={
                        <TransSubtitle
                          i18nKey={
                            removeFromFavorites
                              ? 'removeFromFavorites'
                              : 'addToFavorites'
                          }
                        />
                      }
                    >
                      <Icon
                        className={classNames(classes.favBtn, {
                          [classes.active]: !!removeFromFavorites,
                        })}
                        name="star-filled"
                        onClick={getActionHandler(trip)}
                      />
                    </Tooltip>
                    <OverflowEllipsis
                      variant="body1"
                      maxWidth="18rem"
                      content={[origin.name, destination.name].join(' - ')}
                    />
                  </Card>
                }
              />
            );
          })}
        </Tabs>
      )}
    </Stack>
  );
};
