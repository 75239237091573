import {
  CustomerCard,
  Journey as TripJourney,
  Offer,
  Price,
  Trip,
} from 'dto/trip';
import { Classification } from 'dto/classification';

interface Fee {
  amount: number;
  currency: string;
  vats: Array<{
    amount: number;
    percentage: number;
  }>;
}
export interface BookingDetails {
  id: string;
  externalReference: string;
  provisionalPrice: Price;
  confirmedPrice: Price;
  bookingParts: Array<BookingPart>;
  bookedTrips: Array<BookingTripWithAdmissions>;
  purchaser: BookingDetailsPurchaser;
  passengers: Array<BookingDetailsPassenger>;
  possibleFulfillmentOptions: Array<{
    type: string;
    media: string;
  }>;
  possiblePaymentMethods: Array<{
    type: string;
    voucher: {
      issuer: string;
      code: string;
    };
    remainingPrice: Price;
  }>;
}

export enum FulfillmentStatus {
  AVAILABLE = 'AVAILABLE',
  PREBOOKED = 'PREBOOKED',
  FULFILLED = 'FULFILLED',
  REFUNDED = 'REFUNDED',
  RELEASED = 'RELEASED',
}

export interface BookingFulfillment {
  id: string;
  status: FulfillmentStatus;
  controlNumber?: string;
  mediumType: string;
  documentType: string;
  downloadLink: string;
  documentContent: string;
  documentFormat: string;
  downloadExpiry: string;
}

export interface BookingPart {
  journey: TripJourney;
  fee: {
    productDescription: string;
    price: Price;
    conditions: [
      {
        type: string;
        description: string;
        fee: Price;
      }
    ];
    fulfillments: Array<BookingFulfillment>;
  };
}

export type PassengerFee = {
  description: string;
  price: Price;
};

export interface BookingTripWithAdmissions extends Omit<Trip, 'offers'> {
  alliances: Array<string>;
  isOutbound: boolean;
  bookedOffer: {
    id: string;
    createdOn: string;
    admissions: Array<BookingAdmission>;
  };
}
export interface BookingAdmission
  extends Omit<
    Offer,
    | 'productDescription'
    | 'conditions'
    | 'appliedReductions'
    | 'offerMode'
    | 'passengers'
  > {
  confirmableUntil: string;
  description: string;
  status: FulfillmentStatus;
  reservations: Array<BookingReservation>;
  ancillaries: Array<BookingAdmissionAncillary>;
  fees: Array<PassengerFee>;
  fulfillments: Array<BookingFulfillment>;
  passengerTypes: Array<string>;
  passengerCards: Array<string>;
}

export interface BookingReservation {
  status: string;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  price: Price;
  placeAllocations: Array<{
    legId: string;
    accomodationType: string;
    accommodationSubType: Array<string>;
    reservedPlaces: Array<{
      carriageNumber: string;
      placeNumber: string;
      placeProperties: Array<string>;
    }>;
  }>;
}
export interface BookingRefundOffer {
  id: string;
  summary: string;
  status: string;
  fulfillmentId: string;
  refundFee: Fee;
  refundableAmount: Fee;
  createdOn: string;
  validUntil: string;
  confirmedOn: string;
  appliedOverruleCode: string;
}

export interface BookingReleaseOffer {
  id: string;
  fulfillmentId: string;
  summary: string;
  status: string;
  createdOn: string;
  validUntil: string;
  confirmedOn: string;
}

export interface BookingAdmissionAncillary {
  type: string;
  status: string;
  productDescription: string;
  amountOf: number;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  price: Price;
}

export interface BookingDetailsPurchaser {
  externalReference: string;
  firstName: {
    isRequired: boolean;
    value: string;
  };
  lastName: {
    isRequired: boolean;
    value: string;
  };
  companyName: {
    isRequired: boolean;
    value: string;
  };
  companyRegistrationNumber: {
    isRequired: boolean;
    value: string;
  };
  vatNumber: {
    isRequired: boolean;
    value: string;
  };
  contactInformation: {
    isRequired: true;
    emailAddress: {
      isRequired: boolean;
      value: string;
    };
    phoneNumber: {
      isRequired: boolean;
      value: string;
    };
  };
  address: {
    isRequired: boolean;
    streetName: string;
    zipCode: string;
    city: string;
    countryCode: string;
  };
}

export interface BookingDetailsPassenger {
  id: string;
  externalReference: string;
  age: number;
  firstName: {
    isRequired: true;
    value: string;
  };
  lastName: {
    isRequired: true;
    value: string;
  };
  gender: {
    isRequired: false;
    value: string;
  };
  prmNeeds: {
    isRequired: false;
    value: Array<string>;
  };
  contactInformation: {
    isRequired: true;
    emailAddress: {
      isRequired: boolean;
      value: string;
    };
    phoneNumber: {
      isRequired: boolean;
      value: string;
    };
  };
  address: {
    isRequired: boolean;
    streetName: string;
    zipCode: string;
    city: string;
    countryCode: string;
  };
  isCardsRequired: boolean;
  cards: Array<CustomerCard>;
}

export interface BookingRefundOffer {
  refundCalculationId: string;
  passengers: Array<{
    id: string;
    firstName: string;
    lastName: string;
    refundOffers: Array<{
      id: string;
      leg: {
        isRefundable: boolean;
        isChangeable: boolean;
        origin: {
          departureDate: Date;
          stopName: string;
        };
        destination: {
          arrivalDate: Date;
          stopName: string;
        };
        price: { amount: number; currency: string };
      };
      refundFee: { amount: number; currency: string };
      refundableAmount: { amount: number; currency: string };
      validUntil: string;
    }>;
  }>;
}

interface Duration {
  ticks: number;
  days: number;
  hours: number;
  milliseconds: number;
  minutes: number;
  seconds: number;
  totalDays: number;
  totalHours: number;
  totalMilliseconds: number;
  totalMinutes: number;
  totalSeconds: number;
}

export type Journey = {
  [index in 'origin' | 'destination']: {
    arrivalDate?: string;
    departureDate?: string;
    stopName: string;
  };
};

export interface Booking {
  id: string;
  bookingReference: string;
  createdOn: string;
  retailerName: string;
  totalPrice: number;
  currency: string;
  paymentStatus: string;
  journeys: Array<{
    outbound: Journey | null;
    inbound: Journey | null;
  }>;
  pointOfSaleType: {
    id: string;
    name: string;
  };
  passengerNames: Array<string>;
  hasComments: boolean;
}

interface Passenger {
  id: string;
  firstName: string;
  lastName: string;
  places: [
    {
      placeClass: string | null;
      number: number | null;
      vehicle: string | null;
    }
  ];
}

export interface Leg {
  orderNumber: number;
  carrierName: string;
  duration: Duration;
  originStop: {
    departureDate: Date;
    stopName: string;
  };
  destinationStop: {
    arrivalDate: Date;
    stopName: string;
  };
  passenger: Array<Passenger>;
}

export interface JourneySummary {
  outbound: {
    legs: Array<Leg>;
  };
  inbound: {
    legs: Array<Leg>;
  };
}

export interface BookingTrip {
  id: string;
  status: {
    id: string;
    name: string;
  };
  legs: [
    {
      isRefundable: boolean;
      isChangeable: boolean;
      orderNumber: number;
      carrierName: string;
      duration: Duration;
      originStop: {
        departureDate: Date;
        stopName: string;
      };
      destinationStop: {
        arrivalDate: Date;
        stopName: string;
      };
      places: Array<string | null>;
      ancillaries: Array<string | null>;
      fees: Array<string | null>;
    }
  ];
}

interface Gender {
  id: string;
  name: string;
}

interface Phone {
  areacode: string;
  number: string;
}

interface Passenger {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: {
    year: number;
    month: number;
    day: number;
    dayOfWeek: string;
    dayOfYear: number;
    dayNumber: number;
  };
  type: {
    id: string;
    name: string;
  };
  gender: Gender;
  email: string;
  phone: Phone;
  document: {
    number: string;
    type: {
      id: string;
      name: string;
    };
  };
  nationality: {
    id: string;
    name: string;
  };
}

export interface BookingSearchFilter {
  referenceNumber: string;
  passenger: {
    emailAddress: string;
    phoneNumber: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
  purchaser: {
    emailAddress: string;
    phoneNumber: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
  filterParameters: {
    travelDateRange?: {
      fromDate: string;
      toDate: string;
    };
    purchaseDateRange?: {
      fromDate: string;
      toDate: string;
    };
    corporateAgreement?: string;
    originStop?: string;
    destinationStop?: string;
    serviceCode?: string;
    travelAgencyName?: string;
    status?: string;
  };
}

export interface BookingsSearchResult {
  id: string;
  hasMultipleJourneys: boolean;
  createdOn: string;
  status: string;
  journeyOrigin: {
    name: string;
    time: string;
  };
  journeyDestination: {
    name: string;
    time: string;
  };
  passengers: Array<{
    extRef: string;
    firstName: string;
    id: string;
    lastName: string;
    prmNeed: Array<string>;
    type: string;
  }>;
  totalPrice: {
    amount: number;
    currency: string;
  };
}

export interface BookingComment {
  id: string;
  bookingId: string;
  type: Classification;
  content: string;
  isDeleted: boolean;
  createInfo: {
    userId: string;
    userName: string;
    createdOn: string;
    lastChangedOn: string;
  };
}

export interface BookingAdditionalOffer {
  offerId: string;
  ancillaryOffers: Array<BookingAncillaryOffer>;
  reservationOffers: Array<BookingReservationOffer>;
}

export interface BookingAncillaryOffer {
  ancillaryOfferId: string;
  type: string;
  productDescription: string;
  price: Price;
  tripCoverage: {
    tripId: string;
    journeyReference: string;
    coveredLegIds: Array<string>;
  };
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  afterSalesConditions: Array<{
    type: string;
    fee: Fee;
  }>;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
}

export interface BookingReservationOffer {
  reservationOfferId: string;
  productDescription: string;
  price: Price;
  tripCoverage: {
    tripId: string;
    journeyReference: string;
    coveredLegIds: Array<string>;
  };
  travelClass: string;
  serviceClass: {
    code: string;
    name: string;
  };
  afterSalesConditions: Array<{
    type: string;
    fee: Fee;
  }>;
  appliedReductions: Array<{
    type: string;
    code: string;
  }>;
  refundable: 'YES' | 'NO' | 'WITH_CONDITIONS';
  exchangeable: 'YES' | 'NO' | 'WITH_CONDITIONS';
}
