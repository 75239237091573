import {
  Button,
  DateField,
  FieldArray,
  FormProvider,
  Icon,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack, Typography } from '@mui/material';
import { CartTotal } from 'components/CartTotal';
import { Tag } from 'components/Tag';
import { BookingDetailsPassenger } from 'dto/booking';
import { getBooking } from 'features/booking/bookingActions';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { passengerUpdateLoading } from 'features/loading/loadingSelectors';
import {
  PassengerDetailsPayload,
  updatePassengerDetails,
} from 'features/trip/tripActions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { FC, Fragment, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { getBookingAdmissions, trimPhoneAreaCode } from 'utils/trip';
import { cardsSelector } from 'features/classification/classificationSelectors';

interface PassengersPayload
  extends Array<BookingDetailsPassenger & PassengerDetailsPayload> {}

interface PassengerDetailsProps {
  goToNextStep: () => void;
}

export const PassengerDetails: FC<PassengerDetailsProps> = ({
  goToNextStep,
}) => {
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const cards = useSelector(cardsSelector);
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );
  const getCardName = useCallback(
    (card: string) => {
      const [cardCode, cardNumber] = card.split(' ');
      return [
        cards.find(({ code }) => code === cardCode)?.name.text,
        cardNumber,
      ]
        .filter(Boolean)
        .join(' ');
    },
    [cards]
  );
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const loading = useSelector(passengerUpdateLoading);
  const genderOptions = useMemo(
    () => [
      { label: 'Male', value: 'MALE' },
      { label: 'Female', value: 'FEMALE' },
      { label: 'X', value: 'X' },
    ],
    []
  );
  const getPassengerAdmission = useCallback(
    (passengerId: string) => {
      const admission = bookingAdmissions.find(({ passengerIds }) =>
        passengerIds.includes(passengerId)
      );
      if (admission) {
        return {
          ...admission,
          passengerTypes: admission.passengerTypes.filter(
            (type) => type !== 'PERSON'
          ),
        };
      }
    },
    [bookingAdmissions]
  );
  const onSubmit = useCallback(
    async ({ passengers }: { passengers: PassengersPayload }) => {
      try {
        await Promise.all(
          passengers.map(async (passenger) => {
            const {
              id,
              externalReference,
              firstName,
              lastName,
              gender,
              birthDate,
              contactInformation,
            } = passenger;
            await dispatch(
              updatePassengerDetails({
                bookingId: booking!.id,
                passengerId: id,
                externalReference,
                firstName: firstName.value,
                lastName: lastName.value,
                phone: {
                  // temp while area code input is missing
                  number: trimPhoneAreaCode(
                    contactInformation.phoneNumber.value
                  ),
                  areaCode: '+46',
                },
                birthDate,
                gender: gender.value,
                email: contactInformation.emailAddress.value,
              })
            ).unwrap();
          })
        );
        await dispatch(getBooking(booking!.id)).unwrap();
        goToNextStep();
      } catch (e) {}
    },
    [booking, dispatch, goToNextStep]
  );
  const initialFormValues = useMemo(
    () => ({
      // temp while area code input is missing
      passengers: booking?.passengers.map(
        ({ contactInformation, ...passenger }) => ({
          ...passenger,
          contactInformation: {
            ...contactInformation,
            phoneNumber: {
              ...contactInformation.phoneNumber,
              value: trimPhoneAreaCode(contactInformation.phoneNumber.value),
            },
          },
        })
      ) as PassengersPayload,
    }),
    [booking]
  );

  const { form, handleSubmit } = useForm<{
    passengers: PassengersPayload;
  }>({
    subscription: { invalid: true },
    initialValues: initialFormValues,
    onSubmit,
  });

  return (
    <>
      <FormProvider {...form}>
        <Typography variant="h1">
          <TransTitle i18nKey="passengerDetails" />
        </Typography>
        <form onSubmit={handleSubmit} id="passengerDetails">
          <FieldArray name="passengers">
            {({ fields }) =>
              fields.value?.map((value, idx) => {
                const {
                  id,
                  contactInformation: { emailAddress, phoneNumber },
                  firstName,
                  lastName,
                  gender,
                  age,
                } = value;
                const { passengerTypes, passengerCards } =
                  getPassengerAdmission(id)!;
                return (
                  <Fragment key={idx}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      py={1}
                    >
                      <Typography variant="h2" mr={1}>
                        {`${idx + 1}/${fields.value.length}`}
                      </Typography>
                      {age && (
                        <Tag variant="body2">
                          <TransField i18nKey="age" values={{ age }} />
                        </Tag>
                      )}

                      {!!passengerTypes.length && (
                        <Tag color="primary" variant="body2">
                          <TransField
                            i18nKey="passengerType"
                            values={{ type: passengerTypes[0] }}
                          />
                        </Tag>
                      )}

                      {passengerCards.map((card, idx) => (
                        <Tag color="success" key={card} variant="body2">
                          {idx ? (
                            getCardName(card)
                          ) : (
                            <TransField
                              i18nKey="card"
                              values={{ number: getCardName(card) }}
                            />
                          )}
                        </Tag>
                      ))}
                    </Stack>

                    <Grid container columns={4} spacing={2} rowSpacing={2}>
                      <Grid item xs={1}>
                        <TextField
                          required={firstName.isRequired}
                          name={`${fields.name}[${idx}].firstName.value`}
                          label={<TransField i18nKey="passengerNameFirst" />}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          required={lastName.isRequired}
                          name={`${fields.name}[${idx}].lastName.value`}
                          label={<TransField i18nKey="passengerNameLast" />}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <SelectField
                          showEmptyOption
                          required={gender.isRequired}
                          name={`${fields.name}[${idx}].gender.value`}
                          label={<TransField i18nKey="gender" />}
                          options={genderOptions}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <DateField
                          yearDropdownItemNumber={100}
                          name={`${fields.name}[${idx}].birthDate`}
                          label={<TransField i18nKey="birthday" />}
                          withPortal
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          required={emailAddress.isRequired}
                          email
                          name={`${fields.name}[${idx}].contactInformation.emailAddress.value`}
                          label={<TransField i18nKey="email" />}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <TextField
                          required={phoneNumber.isRequired}
                          name={`${fields.name}[${idx}].contactInformation.phoneNumber.value`}
                          label={<TransField i18nKey="mobileNumber" />}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })
            }
          </FieldArray>
        </form>
      </FormProvider>
      <CartTotal>
        <>
          <Button
            variant="text"
            onClick={() => form.restart()}
            disabled={isBookingExpired}
            label={<TransButton i18nKey="resetFields" />}
          />
          <Button
            variant="contained"
            type="submit"
            form="passengerDetails"
            loading={loading}
            disabled={isBookingExpired}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                <TransButton i18nKey="addonSelection" />
              </>
            }
          />
        </>
      </CartTotal>
    </>
  );
};
