import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  addonPrice: (
    <Trans i18nKey="subtitle.addonPrice" defaults="Add-on price incl. VAT" />
  ),
  addons: <Trans i18nKey="subtitle.addons" defaults="Add-ons" />,
  addonsBookingLevel: (
    <Trans i18nKey="subtitle.addonsBookingLevel" defaults="Leg level add-ons" />
  ),
  addonsLegLevel: (
    <Trans i18nKey="subtitle.addonsLegLevel" defaults="Booking level add-ons" />
  ),
  addToFavorites: (
    <Trans i18nKey="subtitle.addToFavorites" defaults="Add to favorites" />
  ),
  admissions: <Trans i18nKey="subtitle.admissions" defaults="Admissions" />,
  adults: <Trans i18nKey="subtitle.adults" defaults="{{count}} x adults" />,
  availableNr: (
    <Trans i18nKey="subtitle.availableNr" defaults="Available: {{count}}" />
  ),
  BED: <Trans i18nKey="subtitle.bed" defaults="Bed in compartment" />,
  bookingAddons: (
    <Trans i18nKey="subtitle.bookingAddons" defaults="Booking add-on options" />
  ),
  bookingAddOns: (
    <Trans i18nKey="subtitle.bookingAddOns" defaults="Booking addons" />
  ),
  bookingComplete: (
    <Trans i18nKey="subtitle.bookingComplete" defaults="Booking complete" />
  ),
  bookingQty: (
    <Trans
      i18nKey="subtitle.bookingQty"
      defaults="(0)[0 bookings];(1)[{{count}} booking];(2-inf)[{{count}} bookings];"
    />
  ),
  bookingSearch: (
    <Trans i18nKey="subtitle.bookingSearch" defaults="Booking search" />
  ),
  bookingSummary: (
    <Trans i18nKey="subtitle.bookingSummary" defaults="Booking Summary" />
  ),
  cancelValueHint: (
    <Trans
      i18nKey="subtitle.cancelValueHint"
      defaults="(Cancel value: {{amount}})"
    />
  ),
  cartTotal: <Trans i18nKey="subtitle.cartTotal" defaults="Total: {{total}}" />,
  changeable: <Trans i18nKey="subtitle.changeable" defaults="Changeable" />,
  exchangeable: <Trans i18nKey="subtitle.exchangeable" defaults="Changeable" />,
  changes: (
    <Trans
      i18nKey="subtitle.changes"
      defaults="(0)[0 changes];(1)[{{count}} change];(2-inf)[{{count}} changes];"
    />
  ),
  comments: <Trans i18nKey="subtitle.comments" defaults="Comments" />,
  COMPARTMENT: <Trans i18nKey="subtitle.compartment" defaults="Compartment" />,
  detailedTripInfo: (
    <Trans i18nKey="subtitle.detailedTripInfo" defaults="Detailed trip info" />
  ),
  details: <Trans i18nKey="subtitle.details" defaults="Details" />,
  fastest: <Trans i18nKey="subtitle.fastest" defaults="Fastest" />,
  favoriteTrips: (
    <Trans i18nKey="subtitle.favoriteTrips" defaults="Favorite trips" />
  ),
  fees: <Trans i18nKey="subtitle.fees" defaults="Fees" />,
  FEMALE: <Trans i18nKey="subtitle.FEMALE" defaults="Female" />,
  filters: <Trans i18nKey="subtitle.filters" defaults="Filters" />,
  hiddenColumns: (
    <Trans i18nKey="subtitle.hiddenColumns" defaults="Hidden columns" />
  ),
  history: <Trans i18nKey="subtitle.history" defaults="History" />,
  inbound: <Trans i18nKey="subtitle.inbound" defaults="Inbound" />,
  journeysFound: (
    <Trans i18nKey="subtitle.journeysFound" defaults="{{num}} journeys found" />
  ),
  legAddons: (
    <Trans i18nKey="subtitle.legAddons" defaults="Leg level add-on options" />
  ),
  legInfo: <Trans i18nKey="subtitle.legInfo" defaults="Leg information" />,
  LOWER_BED: <Trans i18nKey="subtitle.LOWER_BED" defaults="Lower bed" />,
  lowestPrice: <Trans i18nKey="subtitle.lowestPrice" defaults="Lowest price" />,
  MALE: <Trans i18nKey="subtitle.MALE" defaults="Male" />,
  meansOfTransport: (
    <Trans i18nKey="subtitle.meansOfTransport" defaults="Means of transport" />
  ),
  MIDDLE_BED: <Trans i18nKey="subtitle.MIDDLE_BED" defaults="Middle bed" />,
  MIXED: <Trans i18nKey="subtitle.MIXED" defaults="Mixed" />,
  NEAR_ANIMALS: <Trans i18nKey="subtitle.NEAR_ANIMALS" defaults="Near pets" />,
  nonChangeable: (
    <Trans i18nKey="subtitle.nonChangeable" defaults="Non-changeable" />
  ),
  nonRefundable: (
    <Trans i18nKey="subtitle.nonRefundable" defaults="Non-refundable" />
  ),
  notifications: (
    <Trans i18nKey="subtitle.notifications" defaults="Notifications" />
  ),
  onDemandTransport: (
    <Trans
      i18nKey="subtitle.onDemandTransport"
      defaults="On demand transport"
    />
  ),
  outbound: <Trans i18nKey="subtitle.outbound" defaults="Outbound" />,
  overview: (
    <Trans i18nKey="subtitle.overview" defaults="Overview and payment" />
  ),
  passengerData: (
    <Trans i18nKey="subtitle.passengerData" defaults="Passenger data" />
  ),
  passengerDetails: (
    <Trans i18nKey="subtitle.passengerDetails" defaults="Passenger details" />
  ),
  passengersAndTickets: (
    <Trans
      i18nKey="subtitle.passengersAndTickets"
      defaults="Passengers and tickets"
    />
  ),
  passengersQty: (
    <Trans
      i18nKey="subtitle.passengersQty"
      defaults="(0)[{{count}} passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers];"
    />
  ),
  passengerTitle: (
    <Trans i18nKey="subtitle.passengerTitle" defaults="Passenger #{{number}}" />
  ),
  payerData: <Trans i18nKey="subtitle.payerData" defaults="Payer data" />,
  priceFrom: (
    <Trans
      i18nKey="subtitle.priceFrom"
      defaults="from {{price}} {{currency}}"
    />
  ),
  purchasedAddons: (
    <Trans i18nKey="subtitle.purchasedAddons" defaults="Purchased addons" />
  ),
  recentSearches: (
    <Trans i18nKey="subtitle.recentSearches" defaults="Recent searches" />
  ),
  recentTrips: <Trans i18nKey="subtitle.recentTrips" defaults="Recent trips" />,
  refundable: <Trans i18nKey="subtitle.refundable" defaults="Refundable" />,
  refundValueHint: (
    <Trans
      i18nKey="subtitle.refundValueHint"
      defaults="(Refund value: {{amount}})"
    />
  ),
  removeFromFavorites: (
    <Trans
      i18nKey="subtitle.removeFromFavorites"
      defaults="Remove from favorites"
    />
  ),
  route: <Trans i18nKey="subtitle.route" defaults="Route" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  searchResultsCount: (
    <Trans
      i18nKey="subtitle.searchResultsCount"
      defaults="{{count}} journey found"
    />
  ),
  SEAT: <Trans i18nKey="subtitle.seat" defaults="Seat" />,
  seatingInfo: <Trans i18nKey="subtitle.seatingInfo" defaults="Seating info" />,
  sellTickets: <Trans i18nKey="subtitle.sellTickets" defaults="Sell tickets" />,
  sendToPayer: (
    <Trans
      i18nKey="subtitle.sendToPayer"
      defaults="Send to payer ({{email}})"
    />
  ),
  totalCancelValue: (
    <Trans
      i18nKey="subtitle.totalCancelValue"
      defaults="Total cancel value: {{value}}"
    />
  ),
  totalPrice: <Trans i18nKey="subtitle.totalPrice" defaults="Total price" />,
  totalRefundValue: (
    <Trans
      i18nKey="subtitle.totalRefundValue"
      defaults="Total refund value: {{value}}"
    />
  ),
  transactionSummary: (
    <Trans
      i18nKey="subtitle.transactionSummary"
      defaults="Transaction Summary"
    />
  ),
  transferTime: (
    <Trans i18nKey="subtitle.transferTime" defaults="Transfer {{time}}" />
  ),
  travelTime: (
    <Trans i18nKey="subtitle.travelTime" defaults="{{time}} travel time" />
  ),
  unknownCarrier: (
    <Trans i18nKey="subtitle.unknownCarrier" defaults="Unknown carrier" />
  ),
  UPPER_BED: <Trans i18nKey="subtitle.UPPER_BED" defaults="Upper bed" />,
  visibleColumns: (
    <Trans i18nKey="subtitle.visibleColumns" defaults="Visible columns" />
  ),
  WITH_ANIMALS: (
    <Trans i18nKey="subtitle.WITH_ANIMALS" defaults="Pets allowed" />
  ),
  WITHOUT_ANIMALS: (
    <Trans i18nKey="subtitle.WITHOUT_ANIMALS" defaults="No pets" />
  ),
});
