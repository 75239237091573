import { Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { TripLeg } from 'dto/trip';
import { TransportBadge } from 'components/TransportBadge';
import { IconName } from '@fleet/shared/mui/Icon';
import { Tooltip } from '@fleet/shared/mui';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { renderToString } from 'react-dom/server';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: '0.1875rem',
      overflow: 'hidden',
      cursor: 'pointer',
      color: theme.palette.text.primary + '!important',
    },
    amenityWrap: {
      background: theme.palette.background.default,
    },
    whiteBg: {
      background: theme.palette.common.white,
    },
    amenity: {
      padding: '0 3px',
      boxSizing: 'content-box',
    },
  }),
  { name: 'LegInfo' }
);

export const LegInfo: FC<
  TripLeg & { isBgWhite?: boolean; className?: string }
> = (props) => {
  const {
    className,
    isBgWhite,
    ptMode,
    carrier,
    serviceCode,
    lineNumber,
    serviceAttributes,
  } = props;
  const transportationInfo = [
    carrier?.name ??
      carrier?.code ??
      renderToString(<TransSubtitle i18nKey="unknownCarrier" />),
    serviceCode ?? lineNumber,
  ]
    .filter(Boolean)
    .join(' ');

  const classes = useStyles();
  const getAmenityIcon = useCallback((code: string): IconName | '' => {
    switch (code) {
      case 'KOMAEK':
        return '1-class';
      case 'BOKEJ2':
        return '2-class-crossed';
      case 'KOMB':
      case 'KOM5':
      case 'KOMKLS':
        return '2-class';
      case 'KOMLUG':
        return '2-class-calm';
      case 'KOMEDJ':
        return 'pet-crossed';
      case 'KOMCYK':
        return 'bicycle';
      case 'KOMECY':
        return 'bicycle-crossed';
      case 'KOMRES':
      case 'KOMKRO':
        return 'restaurant';
      case 'KOMFRK':
      case 'KOMFRL':
        return 'breakfast-2-class';
      case 'KOMFRR':
      case 'KOMFRU':
        return 'breakfast-1-class';
      case 'KOM37':
      case 'KOMSER':
        return 'coffee-machine';
      case 'KOMKB1':
        return 'cafe-1-class';
      case 'KOMC':
        return 'couchette';
      case 'EVFEUB':
      case 'EVFEUJ':
        return 'eu-law';
      case 'EVFSFS':
        return 'sw-law';
      case 'KOMPLU':
        return 'food-1-class';
      case 'KOMIUK':
      case 'KOMINV':
        return 'wifi';
      case 'KOMBAG':
        return 'luggage';
      case 'BOKOBL':
        return 'reservation-mandatory';
      case 'KOMMAT':
        return 'food';
      case 'KOMMÅL':
        return 'food-plus';
      case 'KOM34':
        return 'bar';
      case 'KOMMOV':
        return 'movingo';
      case 'BOKEJB':
        return 'reservation-crossed';
      case 'KOMEJS':
        return 'coffee-machine-crossed';
      case 'KOMEAC':
        return 'ac-crossed';
      case 'KOMEWI':
        return 'wifi-crossed';
      case 'KOM74':
        return 'nonstop';
      case 'BOKFRI':
        return 'reservation-optional';
      case 'KOMEL':
        return 'power-socket';
      case 'KOMFUN':
      case 'KOMTAF':
        return 'breakfast';
      case 'KOMLYF':
        return 'wheelchair-ramp';
      default:
        return '';
    }
  }, []);
  return (
    <Stack
      direction="row"
      alignItems="stretch"
      className={classNames(classes.root, className)}
      flexWrap="wrap"
    >
      <TransportBadge
        ptMode={ptMode}
        label={transportationInfo}
        rounded={!serviceAttributes.length}
      />
      <Stack
        direction="row"
        alignItems="center"
        className={classNames(classes.amenityWrap, {
          [classes.whiteBg]: isBgWhite,
        })}
      >
        {serviceAttributes.map(({ code, description }) => {
          const iconName = getAmenityIcon(code);
          return (
            iconName && (
              <Tooltip content={description} key={code}>
                <Icon
                  className={classes.amenity}
                  name={iconName}
                  size={['sw-law', 'eu-law'].includes(iconName) ? 24 : 16}
                />
              </Tooltip>
            )
          );
        })}
      </Stack>
    </Stack>
  );
};
