// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const move = (array: Array<any>, from: number, to: number) => {
  const item = array[from];
  const length = array.length;
  const diff = from - to;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, to),
      item,
      ...array.slice(to, from),
      ...array.slice(from + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = to + 1;
    return [
      ...array.slice(0, from),
      ...array.slice(from + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
};
