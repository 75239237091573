import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { FC, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useSelector } from 'store/utils';
import {
  currentBookingSelector,
  selectAdmissionSelection,
  selectRefundOffers,
} from 'features/booking/bookingSelectors';
import {
  PassengerAdmissionsColumns,
  PassengerAdmissionsTable,
} from 'routes/bookingDetails/PassengerAdmissionsTable';
import { getBookingAdmissions } from 'utils/trip';

interface PassengerFulfillmentSelectionProps {
  type: 'refund' | 'cancel';
  passengerId: string;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
    content: {
      padding: '16px 8px',
      background: theme.palette.background.default,
      boxShadow:
        'inset 0 8px 8px -5px rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.1)',
    },
    header: {
      flexDirection: 'row-reverse',
    },
  }),
  { name: 'PassengerSelection' }
);

export const PassengerSelection: FC<PassengerFulfillmentSelectionProps> = ({
  type,
  passengerId,
}) => {
  const refundOffers = useSelector(selectRefundOffers);
  const selectedAdmissions = useSelector(selectAdmissionSelection);
  const booking = useSelector(currentBookingSelector)!;
  const { passengers } = booking;
  const bookingAdmissions = useMemo(
    () => getBookingAdmissions(booking),
    [booking]
  );
  const passengerSelectedAdmissions = useMemo(
    () =>
      bookingAdmissions.filter(({ id }) =>
        selectedAdmissions[passengerId].includes(id)
      ),
    [bookingAdmissions, passengerId, selectedAdmissions]
  );
  const passengerFulfillmentIds = useMemo(
    () =>
      passengerSelectedAdmissions.reduce<Array<string>>(
        (acc, { fulfillments }) => [
          ...acc,
          ...fulfillments.map(({ id }) => id),
        ],
        []
      ),
    [passengerSelectedAdmissions]
  );
  const passengerName = useMemo(() => {
    const passenger = passengers.find(({ id }) => id === passengerId)!;
    return [passenger.firstName.value, passenger.lastName.value].join(' ');
  }, [passengers, passengerId]);
  const classes = useStyles();
  const passengerRefundTotal = useMemo(
    () =>
      refundOffers?.passengerOffers.find(({ fulfillmentId }) =>
        passengerFulfillmentIds.includes(fulfillmentId)
      )?.refundableAmount,
    [refundOffers, passengerFulfillmentIds]
  );

  return (
    <Accordion disableGutters classes={{ root: classes.root }} defaultExpanded>
      <AccordionSummary
        classes={{ root: classes.header }}
        expandIcon={
          <Icon name="chevron-down" size={24} margin color="secondary" />
        }
      >
        <Stack direction="row" alignItems="baseline" spacing={1}>
          <Typography variant="subtitle">{passengerName}</Typography>
          {passengerRefundTotal && (
            <Typography variant="body2" component="span">
              <TransSubtitle
                i18nKey="refundValueHint"
                values={{
                  amount: `${passengerRefundTotal.amount} ${passengerRefundTotal.currency}`,
                }}
              />
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.content }}>
        <PassengerAdmissionsTable
          hiddenColumns={[
            PassengerAdmissionsColumns.selection,
            PassengerAdmissionsColumns.productName,
            PassengerAdmissionsColumns.passengerType,
            PassengerAdmissionsColumns.passengerCards,
            PassengerAdmissionsColumns.flexibility,
            ...(type === 'refund'
              ? []
              : [PassengerAdmissionsColumns.refundableAmount]),
          ]}
          data={passengerSelectedAdmissions}
        />
      </AccordionDetails>
    </Accordion>
  );
};
