import { Stack, Typography } from '@mui/material';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Icon } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { Journey as TripJourney } from 'dto/trip';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  icon: {
    width: '34px',
    flexShrink: 0,
  },
}));

export const JourneysSummaries = ({
  data = [],
}: {
  data?: Array<TripJourney>;
}) => {
  const classes = useStyles();
  const tripData = useMemo(() => {
    if (isEmpty(data)) return;
    const originJourney = data[0];
    const destinationJourney = data[data.length - 1];
    return {
      journeyTimes: [
        originJourney.departureTime,
        destinationJourney.arrivalTime,
      ]
        .map((date) => formatDate(date, currentDateTimeFormat))
        .join(' - '),
      journeyStops: [
        originJourney.originStop.name,
        destinationJourney.destinationStop.name,
      ].join(' - '),
      isResplus: data.some(({ alliances }) => alliances.includes('RESPLUS')),
    };
  }, [data]);

  if (!tripData) return null;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">{tripData.journeyTimes}</Typography>
      <Icon className={classes.icon} name="journey-changeover" sx={{}} />
      <Typography variant="body2" sx={{ fontWeight: 700 }}>
        {tripData.journeyStops}
      </Typography>
      {tripData.isResplus && (
        <Icon name="resplus-horizontal" height={14} width={52} />
      )}
    </Stack>
  );
};
