import { Icon } from '@fleet/shared/mui';
import { Alert, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `thin solid ${theme.palette.warning.main}`,
    color: theme.palette.text.primary,
    padding: '0.25rem 2rem 0.25rem 1rem',
    '& > .MuiAlert-icon': {
      paddingTop: '0.625rem',
    },
  },
}));

interface AlertCardProps {
  title?: ReactNode;
  message: ReactNode;
  className?: string;
}
export const AlertCard: FC<AlertCardProps> = ({
  title,
  message,
  className,
}) => {
  const classes = useStyles();
  return (
    <Alert
      severity="warning"
      iconMapping={{
        warning: <Icon name="alert-circle" size={22} />,
      }}
      className={classNames(classes.root, className)}
    >
      <Stack>
        {title && <Typography variant="subtitle">{title}</Typography>}
        <Typography variant="paragraph">{message}</Typography>
      </Stack>
    </Alert>
  );
};
