import { makeStyles } from '@mui/styles';
import worldImg from 'images/world.png';
import { TripsCarousel } from 'routes/tickets/dashboard/TripsCarousel';
import { RECENT_TRIPS_LS_KEY, useLocalStorage } from 'hooks/useLocalStorage';
import { Stack } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback } from 'react';
import { Loadable, useFormContext } from '@fleet/shared';
import { TripSearchParams } from 'dto/trip';
import { useDispatch, useSelector } from 'store/utils';
import {
  addTripToFavorites,
  getFavoriteTrips,
  RecentTrip,
  removeTripFromFavorites,
} from 'features/user/userActions';
import { selectFavoriteTrips } from 'features/user/userSelector';
import { favoriteTripsLoading } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${worldImg})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center -12px',
    backgroundRepeat: 'no-repeat',
  },
}));

interface DashboardProps {}

export const Dashboard: FC<DashboardProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const favoriteTrips = useSelector(selectFavoriteTrips);
  const loading = useSelector(favoriteTripsLoading);
  const form = useFormContext<TripSearchParams>();
  const { initialValue: lsResentTrips } = useLocalStorage<Array<RecentTrip>>({
    key: RECENT_TRIPS_LS_KEY,
    getDefaultValue: (value) => value || [],
  });
  const applyTripParams = useCallback(
    (trip: RecentTrip) => {
      const { origin, destination } = trip;
      form.change('originStop', { code: origin.code });
      form.change('destinationStop', { code: destination.code });
    },
    [form]
  );

  const handleAddToFavorite = useCallback(
    async (payload: { origin: string; destination: string }) => {
      await dispatch(addTripToFavorites(payload)).unwrap();
      dispatch(getFavoriteTrips());
    },
    [dispatch]
  );

  const handleRemoveFromFavorite = useCallback(
    async (id: string) => {
      await dispatch(removeTripFromFavorites(id)).unwrap();
      dispatch(getFavoriteTrips());
    },
    [dispatch]
  );

  return (
    <Loadable loading={loading}>
      <Stack className={classes.root} spacing={2} pt={3}>
        <TripsCarousel
          title={<TransSubtitle i18nKey="recentTrips" />}
          trips={lsResentTrips}
          onTripClick={applyTripParams}
          addToFavorites={handleAddToFavorite}
        />
        <TripsCarousel
          title={<TransSubtitle i18nKey="favoriteTrips" />}
          trips={favoriteTrips}
          onTripClick={applyTripParams}
          removeFromFavorites={handleRemoveFromFavorite}
        />
      </Stack>
    </Loadable>
  );
};
