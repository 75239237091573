import { createLoadingSelector, selector } from 'store/utils';
import { RootState } from 'store';
import { searchTrips, showTripsResultPage } from 'features/trip/tripActions';
import _keyBy from 'lodash/keyBy';
import { createSelector } from '@reduxjs/toolkit';
import { OfferAccommodationType } from 'dto/trip';
import isEmpty from 'lodash/isEmpty';

export const tripsSelector = selector((state: RootState) => state.trip.list);
export const tripLinksMapSelector = selector((state: RootState) =>
  _keyBy(state.trip.links, 'rel')
);
export const downloadPdfFlagSelector = selector(
  (state: RootState) => state.trip.downloadPdfTickets
);
export const selectTripLoading = createLoadingSelector(
  searchTrips,
  showTripsResultPage
);

export const showingStopsForSelector = selector(
  (state: RootState) => state.trip.showStopsFor
);

export const selectSelectedOffers = selector(
  (state: RootState) => state.trip.selectedOffers
);

export const selectUnconfiguredOffer = createSelector(
  selectSelectedOffers,
  ({ list, selectionMap }) =>
    list.find(
      ({ offerAccommodationType, id }) =>
        offerAccommodationType !== OfferAccommodationType.SEAT &&
        isEmpty(selectionMap[id])
    )
);
